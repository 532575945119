<template>
  <PostbackUrlGenerator :campaign-id="campaignId" />
</template>

<script>

import PostbackUrlGenerator from "@/components/PostbackUrlGenerator.vue";

export default {
  name: 'CampaignPostbackUrlGenerator',
  components: {PostbackUrlGenerator},
  props: {
    campaignId: {
      type: String,
      default: null
    }
  },
}
</script>
