<template>
  <tr>
    <th data-sortable="false" title="web_id">Web ID</th>
    <th data-sortable="false" title="partner">Partner</th>
    <th data-sortable="false" title="campaign_id">Campaign</th>
    <th data-sortable="false" title="blocked_by">Blocked By</th>
    <th data-sortable="false" title="created_at">Created at</th>
    <th data-sortable="false" title="actions">Actions</th>
  </tr>
</template>
<script>
export default {
  name: 'BlockedWebsColumns',
  props: {
    role: {
      type: String,
      default: ''
    },
  }
}
</script>
