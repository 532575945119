<template>
  <tr>
    <th title="ext_id" class="w-5">Web Id</th>
    <th title="username">Username</th>
    <th title="status" class="w-10">Status</th>
    <th class="w-5" data-sortable="false">Action</th>
  </tr>
</template>
<script>
export default {
  name: 'PartnerUsersColumns',
  props: {
    role: {
      type: String,
      default: ''
    }
  }
}
</script>
