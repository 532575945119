<template>
  <div class="card">
    <div class="card-header border-bottom">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Leads chart</h5>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div>
        <div class="status-filters dataTable-search search-block col-lg-6 col-sm-12">
          <VueMultiselect v-model="chosenOptions" :options="statusOptions" :multiple="true" :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true" placeholder="Pick lead status" label="name" track-by="name"
                          :preselect-first="true"
                          @select="forceRerender">
            <template #selection="{ }">
        <span v-for="(option, index) in chosenOptions" :key="index" :style="{ background: option.color }"
              class="multiselect__tag">
                <span>{{ option.name }}</span>
                <i tabindex="1" class="remove-status-icon" @click="removeOption(option)"></i>
              </span>
            </template>
          </VueMultiselect>
        </div>
    </div>
    <div>
      <leads-line-chart
          :key="key"
          id="chart-line3"
          title="Leads"
          :chart="{
          labels: labels,
          datasets: chosenOptions
        }"
      />
    </div>
  </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import LeadsLineChart from "@/components/leads/LeadsLineChart.vue";

export default {
  name: "LeadsChartCard",
  components: {
    LeadsLineChart,
    VueMultiselect,
  },
  props: {
    leadsStats: {
      required: true,
    },
  },
  mounted() {
    this.chosenOptions = this.statusOptions;
  },
  data() {
    return {
      chosenOptions: [],
      key: 0
    }
  },
  watch: {
    leadsStats: {
      handler: "forceRerender",
      deep: true,
    },
  },
  computed: {
    statusOptions() {
      return [
        {name: 'Approved', value: 'Approved', color: '#108f10', data: this.approved},
        {name: 'Rejected', value: 'Rejected', color: '#be3939', data: this.rejected},
        {name: 'Trashed', value: 'Trashed', color: 'grey', data: this.trashed},
        {name: 'Processing', value: 'Processing', color: 'orange', data: this.hold},
        {name: 'Total', value: 'Total', color: '#17c1e8', data: this.total},
      ]
    },
    labels() {
      return this.leadsStats?.map(stat => {
        return stat.date;
      })
    },
    approved() {
      return this.leadsStats?.map(stat => {
        return stat.leads_approved;
      })
    },
    rejected() {
      return this.leadsStats?.map(stat => {
        return stat.leads_rejected;
      })
    },
    hold() {
      return this.leadsStats?.map(stat => {
        return stat.leads_on_hold;
      })
    },
    trashed() {
      return this.leadsStats?.map(stat => {
        return stat.leads_trashed;
      })
    },
    total() {
      return this.leadsStats?.map(stat => {
        return stat.leads_trashed + stat.leads_on_hold + stat.leads_rejected + stat.leads_approved;
      })
    },
  },
  methods: {
    removeOption(option) {
      this.chosenOptions = this.chosenOptions.filter(item => item.name !== option.name);
      this.forceRerender();
    },
    forceRerender() {
      this.chosenOptions = this.statusOptions.filter(item => this.chosenOptions.some(chosen => chosen.name === item.name))
      this.key++;
      // this.updateStatus();
    },
    dates(startDate, num) {
      return Array.from(
          {length: num},
          (_, i) => new Date(startDate.getTime() + (i * 60000 * 60 * 24)).toISOString().slice(0, 10)
      );
    },
  }
};
</script>
<style scoped>
.remove-status-icon::after {
  content: "×";
  color: white;
  font-size: 14px;
}

.remove-status-icon
{
  content: "×";
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
  color: white;
}
</style>

