import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getBlockedWebs(params) {
        const response = await axios.get(
            "/users/-actions/blocked-webs", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },

    async getBlockedWebsIds(params) {
        const response = await axios.get("/blockedWebs/-actions/blockedWebs-ids", {
            headers: authHeader(),
            params: params
        });
        return {data: response.data};
    },

    async addBlockedWeb(blockedWeb) {
        blockedWeb.type = "blockedWebs"

        const response = await axios.post("/users/-actions/block-web", blockedWeb, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async deleteBlockedWeb(blockedWebId) {
        await axios.post(`/users/-actions/unblock-web`, {id: blockedWebId}, {headers: authHeader()});
    },

    async getBlockedWeb(blockedWebId) {
        const response = await axios.get("/blockedWebs/" + blockedWebId + "?include=files,product.files", {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async editBlockedWeb(blockedWeb) {
        blockedWeb.type = "blockedWebs"
        const newJson = dataFormatter.serialize({stuff: blockedWeb})
        const response = await axios.patch("/blockedWebs/" + blockedWeb.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },
}
