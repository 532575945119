<template>
  <div class="py-4">
    <div class="campaign">
      <div class="card">
        <!-- Card header -->
        <div class="card-header border-bottom">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">Best Webs in the last 30 days (by payout)</h5>
            </div>
            <div class="col-auto">
              <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                <!-- The "per page" dropdown will be moved here -->
              </div>
            </div>
          </div>
        </div>

        <!-- Card body -->
        <div class="card-body">

          <!-- Table -->
          <div class="table-responsive">
            <table id="campaign-list" ref="table" class="table table-flush">
              <thead class="thead-light">
              <tr v-if="loadingFinished">
                <th data-sortable="false" title="users.ext_id" class="w-5">Web ID</th>
                <th data-sortable="false" title="total_leads" class="w-5">Leads total</th>
                <th data-sortable="false" title="approved_leads" class="w-5">Approved</th>
                <th data-sortable="false" title="rejected_leads" class="w-5">Rejected</th>
                <th data-sortable="false" title="trashed_leads" class="w-5">Trashed</th>
                <th data-sortable="false" title="on_hold_leads" class="w-5">Processing</th>
                <th data-sortable="false" title="top_offer" class="w-5">Top offer</th>
                <th title="payout" class="w-5">Payout</th>
<!--                <th title="left_to_pay" class="w-5">Left to pay</th>-->
<!--                <th title="requested_to_withdraw" class="w-5">Requested to withdraw</th>-->
<!--                <th title="cpa" class="w-5">CPA</th>-->
<!--                <th title="approve_rate" class="w-5">Approve rate</th>-->
<!--                <th title="delivery_rate" class="w-5">Delivery rate</th>-->
              </tr>
              </thead>
              <tbody class="text-sm">
              </tbody>
            </table>
          </div>
        </div>

<!--        <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">-->
<!--          <div class="ms-3">-->
<!--            <p class="text-sm">-->
<!--              Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }}-->
<!--            </p>-->
<!--          </div>-->
<!--          <BasePagination v-model="pagination.currentPage" class="pagination-success pagination-md me-3"-->
<!--                          :value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"-->
<!--                          @click="getDataFromPage($event)"/>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";

var sortDirection = "-total_payout";
var getCurrentPage = 1;
var filters = {};
const getWebsList = _.debounce(async function (params) {
  await store.dispatch("users/getBestWebs", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "BestWebsList",
  mixins: [eventTable],
  props: {
    updatedFilter: {
      type: Object
    },
    updatedFilters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      filters: {},
      search: '',
      dataWebs: [],
      pagination: {},
      dataTable: null,
      keys: null,
      loadingFinished: false
    }
  },
  computed: {
    websList() {
      return this.$store.getters["users/getBestWebsData"];
    },
    metaPage() {
      return this.$store.getters["users/getUsersMeta"];
    },
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    websList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    updatedFilter: {
      handler: "updateFilters",
      immediate: true,
      deep: true
    },
  },
  async mounted() {
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 5,
        perPageSelect: false
      });

      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      await getWebsList({
        sort: sortDirection,
        query: '',
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });

      // Wait for the table to be initialized before moving the dropdown
      this.dataTable.on('datatable.init', function() {
        // Select the "per page" dropdown. The class or ID might change based on the library's implementation
        var perPageDropdown = document.querySelector(".dataTable-dropdown");

        // Select the new target location for the dropdown
        var newLocation = document.getElementById("newPerPagePosition");

        // Move the dropdown to the new location
        if (perPageDropdown && newLocation) {
          newLocation.appendChild(perPageDropdown);
        }
      });

      // filters['campaigns'] = true;
      this.dataTable.on('datatable.perpage', async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getWebsList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.dataTable.on('datatable.sort', async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getWebsList({
          sort: sortDirection = direction + column,
          query: filters,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    }
  },
  beforeUnmount() {
    sortDirection = "-total_leads";
    getCurrentPage = 1;
    filters = {};
  },
  methods: {
    async refreshList() {
      await getWebsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 5
      });
    },
    async updateFilters() {
      filters = this.updatedFilters

      await this.refreshList();
    },

    async getDataFromPage(page) {
      await getWebsList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataWebs = [];
      if (this.websList.length > 0) {
        this.websList.forEach(web => {
          const partnerName = web.partnerName !== '' ? `<small style="margin-left: 4px;">(${web.partnerName})</small>` : '';
          this.dataWebs.push(
              [
                `<a id="${web.webId}" style="cursor: pointer;" class="campaign-list-web-id">${web.webId}</a>${partnerName}`,
                web.leads.toString(),
                this.navigateToLeads(this.updatedFilters, 'approved', web.webId, web.leadsApproved.toString()),
                this.navigateToLeads(this.updatedFilters, 'rejected', web.webId, web.leadsRejected.toString()),
                this.navigateToLeads(this.updatedFilters, 'trashed', web.webId, web.leadsTrashed.toString()),
                this.navigateToLeads(this.updatedFilters, 'hold', web.webId, web.leadsOnHold.toString()),
                web.topOffer,
                this.redirectToApprovedLeads(web.webId, web.payout),
                // web.paidOut,
                // web.leftToPay,
                // web.requestedToWithdraw,
                // web.cpa,
                // web.approveRate.toString() + "%",
                // web.deliveryRate.toString() + "%",
              ]
          )
        })
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataWebs});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit Web",
          deletePath: "webs/deleteWeb",
          getPath: "webs/getWebs",
          textDelete: "Web deleted successfully!",
          textDefaultData: 'webs'
        });
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }
};
</script>
