<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 faq">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-title card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">PHP Documentation for Lead Submission</h5>
            </div>
          </div>
          <!-- Table -->
          <div class="card-body">
            <h5>Overview</h5>
            <p>This PHP script is designed to send data to an API for adding a new lead. The script receives data from a
              form submitted via the <code>POST</code> method, processes it, and then sends it to a specified API
              endpoint.</p>

            <h5>API URL</h5>
            <div class="code-block">
              <pre>https://api.azurelab.network/api/v2/leads/-actions/add-lead-from-lp</pre>
            </div>

            <h5>PHP Code</h5>
            <div class="code-block">
        <pre>
&lt;?php
if ($_SERVER["REQUEST_METHOD"] == "POST") {
    $phone = $_POST['phone'];
    $name = $_POST['name'];
    $referrer = $_SERVER['HTTP_REFERER'] ?? '';

    $queryString = parse_url($referrer, PHP_URL_QUERY);

    // API endpoint
    $url = 'https://api.azurelab.network/api/v2/leads/-actions/add-lead-from-lp';

    // Prepare the data
    $data = array(
        'api_key' => 'API_KEY_HERE',
        'phone' => $phone,
        'name' => $name,
        'subid' => $_POST['subid'], // Optional parameter
        'sub1' => $_POST['sub1'], // Optional parameter
        'sub2' => $_POST['sub2'], // Optional parameter
        'sub3' => $_POST['sub3'], // Optional parameter
        'sub4' => $_POST['sub4'], // Optional parameter
        'note' => $_POST['note'], // Optional parameter
    );

    $ch = curl_init($url);
    if (null !== $queryString) {
        $ch = curl_init($url . '?' . $queryString);
    }
    curl_setopt($ch, CURLOPT_HTTPHEADER, array('Content-Type: application/json'));
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_POST, true);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));
    $response = curl_exec($ch);
    $httpcode = curl_getinfo($ch, CURLINFO_HTTP_CODE);
    curl_close($ch);
    $response = json_decode($response, true);

    if (200 === $httpcode) {
        echo "Lead added successfully.";
    } else {
        echo "Failed to add lead.";
    }
} else {
    echo "No data received.";
}
?&gt;
        </pre>
            </div>

            <h5>Parameters Explanation</h5>

            <div class="parameter">
              <strong>api_key</strong>: A static API key required by the API to authenticate the request. This is
              hardcoded in the script. You can get it by downloading API files in "Campaigns" tab.
            </div>

            <div class="parameter">
              <strong>phone</strong>: The phone number of the lead. This value is required and is retrieved from the
              form using <code>$_POST['phone']</code>.
            </div>

            <div class="parameter">
              <strong>name</strong>: The first and last name of the lead. This value is optional (could be "null") and
              is retrieved from the form using <code>$_POST['name']</code>.
            </div>

            <div class="parameter">
              <strong>subid</strong>: An optional parameter that can be passed from the form using
              <code>$_POST['subid']</code>. It can be used to track the source or any other additional information.
            </div>

            <div class="parameter">
              <strong>sub 1</strong>: An optional parameter that can be passed from the form using
              <code>$_POST['sub1']</code>. It can be used to track the source or any other additional information. The same goes to sub2, sub3, sub4 and note.
            </div>

            <div class="parameter">
              <strong>referrer</strong>: The URL of the referring page, retrieved using
              <code>$_SERVER['HTTP_REFERER']</code>. The query string from this URL is appended to the API URL if
              present.
            </div>

            <h5 style="margin-top: 30px;">Integrating with Your Landing Page</h5>
            <p>To connect this <code>sendlead.php</code> script to your landing page, follow these steps:</p>

            <h6>1. Download the <code>sendlead.php</code> file</h6>
            <p>Ensure you have the <code>sendlead.php</code> file saved in the root directory of your website or in a
              specific directory where you want to handle form submissions.</p>

            <h6>2. Create a Form on Your Landing Page</h6>
            <p>Add a form to your landing page with the following structure:</p>
            <div class="code-block">
        <pre>
&lt;form action="path/to/sendlead.php" method="post"&gt;
    &lt;label for="name"&gt;Name:&lt;/label&gt;
    &lt;input type="text" id="name" name="name" required&gt;

    &lt;label for="phone"&gt;Phone:&lt;/label&gt;
    &lt;input type="tel" id="phone" name="phone" required&gt;

    &lt;!-- Optional field for subid --&gt;
    &lt;input type="hidden" name="subid" value="123456"&gt;

    &lt;!-- Optional field for sub1 --&gt;
    &lt;input type="hidden" name="sub1" value="abc123"&gt;

    &lt;!-- Analogically sub2, sub3, sub4 --&gt;


    &lt;button type="submit"&gt;Submit&lt;/button&gt;
&lt;/form&gt;
        </pre>
            </div>

            <p>Replace <code>path/to/sendlead.php</code> with the actual path to the <code>sendlead.php</code> file on
              your server. Ensure the form fields <code>api_key</code> and <code>phone</code> are present as they are
              required by the PHP script.</p>

            <h6>3. Handling the Response</h6>
            <p>The PHP script will either display a success message ("Lead added successfully.") or a failure message
              ("Failed to add lead.") based on the API response. You can customize these messages within the script to
              suit your landing page's design and user experience. For example you can redirect user to the thankyou
              page using <code>header('Location: https://domain.com/thankyou.html')</code></p>

            <h6>4. Testing the Integration</h6>
            <p>After setting up the form and script, thoroughly test the integration by submitting the form and checking
              if the lead data is correctly sent to the API and if appropriate feedback is given to the user.</p>

            <h5 style="margin-top: 30px;">Checking leads statuses</h5>
            <h6>API URL</h6>
            <div class="code-block">
              <pre>https://api.azurelab.network/api/v2/leads/-actions/check-lead-statuses</pre>
            </div>

            <h6>Example request</h6>
            <div class="code-block">
              <pre>
curl -X GET "https://api.azurelab.network/api/v2/leads/-actions/check-lead-statuses" \
 -H "Accept: application/json" \
 -H "Content-Type: application/json" \
 -d '{
       "api_key": "you_api_key_here", //OR you can use web_api_key. The difference is described below
       "lead_ids": [3]
     }'
              </pre>
            </div>

            <h6>Example response</h6>
            <div class="code-block">
              <pre>
[
  {
    "lead_id": 833,
    "status": "hold",
    "reason": null
  },
  {
    "lead_id": 255,
    "status": "trashed",
    "reason": "Not answering"
  }
]
              </pre>
            </div>

            <h6>Parameters Explanation</h6>

            <div class="parameter">
              <strong>api_key</strong>: A static API key required by the API to authenticate the request. This is
              hardcoded in the script. You can get it by downloading API files in "Campaigns" tab.
            </div>
            <div class="parameter">
              <strong>web_api_key</strong>: This is your global api_key. You can use it to get leads from all campaigns. You have to ask for it on telegram for now.
            </div>
            <div class="parameter">
              <strong>lead_ids</strong>: An array of lead ids. You get the lead id when you send lead to us. We send it in lead_id: ['message' => 'OK', 'lead_id' => $lead->id]. If you omit this parameter, we will return to you all your leads
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FAQ",
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
  background-color: #f4f4f4;
}

h1, h2, h4 {
  color: #333;
}

.code-block {
  background-color: #272822;
  color: #f8f8f2;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
  margin-bottom: 20px;
  font-family: 'Courier New', Courier, monospace;
}

.code-block pre {
  margin: 0;
}

.parameter {
  background-color: #e7f3fe;
  border-left: 6px solid #2196F3;
  padding: 10px;
  margin-bottom: 10px;
}

.note {
  font-style: italic;
  color: #555;
}
</style>
