<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <!-- Card body -->
    <div class="card-body">
      <div class="row mt-sm-2">
        <UsersAffiliateManagerIDFilter v-if="role === 'admin'" @update:updateFilters="updateFilters"/>
        <UsersPartnerIDFilter v-if="role !== 'partner'" @update:updateFilters="updateFilters"/>
        <WebIDFilter @update:updateFilters="updateFilters"/>
        <UsersAdvertiserIDFilter v-if="role === 'admin' || role === 'affiliate_manager'"
                                 @update:updateFilters="updateFilters"/>
        <UserStatusFilter @update:updateFilters="updateFilters"/>
        <UserRoleFilter v-if="role !== 'partner'" @update:updateFilters="updateFilters"/>
        <UserActivityFilter @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import UserActivityFilter from "@/components/Filters/UserActivityFilter.vue"
import UserStatusFilter from "@/components/Filters/UserStatusFilter.vue"
import UsersPartnerIDFilter from "@/components/Filters/UsersPartnerIDFilter.vue";
import UserRoleFilter from "@/components/Filters/UserRoleFilter.vue";
import UsersAffiliateManagerIDFilter from "@/components/Filters/UsersAffiliateManagerIDFilter.vue";
import WebIDFilter from "@/components/Filters/WebIDFilter.vue";
import UsersAdvertiserIDFilter from "@/components/Filters/UsersAdvertiserIDFilter.vue";

export default {
  name: 'UsersFilters',
  components: {
    UsersAdvertiserIDFilter,
    WebIDFilter,
    UsersAffiliateManagerIDFilter, UserRoleFilter, UsersPartnerIDFilter, UserActivityFilter, UserStatusFilter
  },
  props: {
    updateFilters: {},
    role: {
      type: String,
      default: ''
    }
  }
}
</script>
