const initialState = {
    leadsDate: null,
    geo: null,
    product_name: null,
    offer_type: null,
    withdrawal_status: null,
    offer_privacy_level: null
};

export const filters = {
    namespaced: true,
    state: initialState,
    mutations: {
        saveLeadsDate(state, date) {
            state.leadsDate = date;
        },
        saveGeo(state, geo) {
            state.geo = geo;
        },
        saveOfferType(state, offer_type) {
            state.offer_type = offer_type;
        },
        saveOfferPrivacyLevel(state, offer_privacy_level) {
            state.offer_privacy_level = offer_privacy_level;
        },
        saveProductName(state, product_name) {
            state.product_name = product_name;
        },
        saveWithdrawalStatus(state, withdrawal_status) {
            state.withdrawal_status = withdrawal_status;
        }
    },
    getters: {
        getLeadsDate(state) {
            return state.leadsDate;
        },
        getGeo(state) {
            return state.geo;
        },
        getOfferType(state) {
            return state.offer_type
        },
        getOfferPrivacyLevel(state) {
            return state.offer_privacy_level;
        },
        getProductName(state) {
            return state.product_name;
        },
        getWithdrawalStatus(state) {
            return state.withdrawal_status;
        }
    }
}
