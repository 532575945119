<template>
  <tr>
    <th title="advertiser_name">Advertiser</th>
    <th title="deposit_value">Value</th>
    <th title="deposit_date">Deposit Date</th>
  </tr>
</template>
<script>
export default {
  name: 'DepositsColumns'
}
</script>
