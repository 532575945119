<template>
  <div class="py-4 container-fluid">
    <LeadsFilters :default-filters=defaultFilters :role=userProfile?.roles[0].name :update-filters="updateFilters"/>

    <div class="mt-4 lead">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Leads</h5>
              <div class="d-flex align-items-center">
                <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                  <!-- The "per page" dropdown will be moved here -->
                </div>
                <button class="display-subs btn btn-sm btn-outline-success" @click="showSubs">{{ buttonLabel }}</button>
                <button class="display-subs btn btn-sm btn-outline-success" @click="exportToCsv">Export to CSV</button>

              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="card-body">
            <div class="table-responsive">
              <table id="lead-list" ref="table" class="table table-flush">
                <thead v-if="loadingFinished" class="thead-light">
                <tr>
                  <th data-sortable title="created_at">Created at</th>
                  <th data-sortable="false" title="id">Id</th>
                  <th data-sortable="false" title="external_uuid">Ext. UUID</th>
                  <th v-if="userProfile?.roles[0].name !== 'affiliate'" data-sortable="false" title="web_id">Web ID</th>
                  <th data-sortable="false" title="product_name">Product name</th>
                  <th v-if="userProfile?.roles[0].name === 'admin' || userProfile?.roles[0].name === 'affiliate_manager'"
                      data-sortable="false" title="phone_number">Phone number
                  </th>
                  <th v-if="userProfile?.roles[0].name === 'admin' || userProfile?.roles[0].name === 'affiliate_manager'"
                      data-sortable="false" title="name">Name
                  </th>
                  <th data-sortable="false" title="offer_type">Type</th>
                  <th data-sortable="false" title="geo">Geo</th>
                  <th data-sortable="false" title="status">Status</th>
                  <th data-sortable="false" title="reason">Reason</th>
                  <th data-sortable="false" title="payout">Payout</th>
                  <th class="subs" data-sortable="false" hidden title="sub1">sub1</th>
                  <th class="subs" data-sortable="false" hidden title="sub2">sub2</th>
                  <th class="subs" data-sortable="false" hidden title="sub3">sub3</th>
                  <th class="subs" data-sortable="false" hidden title="sub4">sub4</th>
                </tr>
                </thead>
                <tbody class="text-sm">
                </tbody>
              </table>
            </div>
          </div>

          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">
            <div class="ms-3">
              <p class="text-sm">
                Showing {{ pagination.total ? metaPage?.from : 0 }} {{ metaPage?.to ? 'to ' + metaPage?.to : '' }}
              </p>
            </div>
            <BasePagination v-model="pagination.currentPage" :per-page="pagination.perPage"
                            :total="pagination.total" :value="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            @click="getDataFromPage($event)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";

import BasePagination from "@/components/BasePagination.vue";
import {ref} from "vue";
import LeadsFilters from "@/components/leads/LeadsFilters.vue";
import {getLeadProperties} from "@/components/leads/leadsUtils";
import {mapGetters} from "vuex";
import showSwal from "../../mixins/showSwal";

var sortDirection = "-created_at";
var getCurrentPage = 1;
var filters = {};
const getLeadsList = _.debounce(async function (params) {
  await store.dispatch("leads/getLeads", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "AffiliateManagerLeadsList",
  components: {
    LeadsFilters: LeadsFilters,
    BasePagination
  },
  props: {
    leadStatus: {
      type: String,
      default: ''
    },
    serializedFilters: {
      type: String,
      default: ''
    },
    webId: {
      type: String,
      default: ''
    },
    withdrawalId: {
      type: String,
      default: ''
    },
    campaignId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      searchGeo: '',
      searchProduct: '',
      dataLeads: [],
      pagination: {},
      dataTable: null,
      keys: null,
      createdAt: ref,
      loadingFinished: false,
      defaultFilters: [],
      buttonLabel: 'Show parameters',
      showSubsFlag: false
    }
  },
  mixins: [eventTable],
  computed: {
    leadsList() {
      return this.$store.getters["leads/getLeadsData"];
    },
    metaPage() {
      return this.$store.getters["leads/getLeadsMeta"];
    },

    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      storeFilters: 'leads/getFilters',
      userProfile: 'profile/getUserProfile'
    })
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    leadsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    filters: {
      handler: "reactiveFilters",
      deep: true
    },
    showSubsFlag: {
      handler: "handleSubs",
      deep: true
    }
  },
  created() {
    if ('' !== this.serializedFilters) {
      this.defaultFilters = JSON.parse(this.serializedFilters);
      filters = this.defaultFilters;
    }
  },
  async mounted() {
    await store.dispatch("profile/getProfile");

    if (null !== this.storeFilters) {
      filters = this.storeFilters;
    }
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 50,
        perPageSelect: [5, 10, 15, 20, 25, 50]
      });

      document.querySelector(".dataTable-bottom").remove()
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      if ('' !== this.webId) {
        filters['web_id'] = this.webId
      }

      if ('' !== this.leadStatus) {
        filters['lead_status'] = this.leadStatus
      }

      if ('' !== this.withdrawalId) {
        filters['leads_withdrawal_id'] = this.withdrawalId
      }

      if ('' !== this.campaignId) {
        filters['campaigns_id'] = this.campaignId
      }

      await getLeadsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });

      // Wait for the table to be initialized before moving the dropdown
      // Select the "per page" dropdown. The class or ID might change based on the library's implementation
      var perPageDropdown = document.querySelector(".dataTable-dropdown");

      // Select the new target location for the dropdown
      var newLocation = document.getElementById("newPerPagePosition");

      // Move the dropdown to the new location
      if (perPageDropdown && newLocation) {
        newLocation.appendChild(perPageDropdown);
      }

      this.dataTable.on('datatable.perpage', async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getLeadsList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.dataTable.on('datatable.sort', async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getLeadsList({
          sort: sortDirection = direction + column,
          query: filters,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    }
  },
  beforeUnmount() {
    sortDirection = "-created_at";
    filters = {};
    getCurrentPage = 1;
  },
  methods: {
    showSubs() {
      this.showSubsFlag = !this.showSubsFlag;
    },

    handleSubs() {
      var subs = document.getElementsByClassName("subs");
      var i = 0;
      if (this.showSubsFlag) {
        for (i = 0; i < subs.length; i++) {
          subs[i].hidden = false;
        }
        this.buttonLabel = 'Hide parameters'
      } else {
        for (i = 0; i < subs.length; i++) {
          subs[i].hidden = true;
        }
        this.buttonLabel = 'Show parameters'
      }
    },
    async refreshList() {
      if (this.dataTable === null) {
        return; //I am sleepy. Basically in LeadsDateFilter I am setting up default value and it propagates to this component before this component is ready.
        // I will ignore requests when datatable is not set up.
      }
      await getLeadsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable?.options?.perPage
      });
    },

    async getDataFromPage(page) {
      await getLeadsList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async updateFilters(updatedFilter) {
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }
      await this.refreshList();
    },

    async reactiveFilters() {
      await getLeadsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable.options.perPage
      });
    },

    exportToCsv() {
      // give me a swal where I could choose which columns I want to export
      showSwal.methods.fire({
        title: 'Select columns to export',
        html: `

        <p>Choose what columns you want to export</p>
        <input checked type="checkbox" id="Created At" name="Created At" value="Created At">
        <label for="Created At">Created At</label><br>
        <input checked type="checkbox" id="ID" name="ID" value="ID">
        <label for="ID">ID</label><br>
        <input checked type="checkbox" id="External UUID" name="External UUID" value="External UUID">
        <label for="External UUID">External UUID</label><br>
        <input checked type="checkbox" id="Web ID" name="Web ID" value="Web ID">
        <label for="Web ID">Web ID</label><br>
        <input checked type="checkbox" id="Product Name" name="Product Name" value="Product Name">
        <label for="Product Name">Product Name</label><br>
        <input checked type="checkbox" id="Phone Number" name="Phone Number" value="Phone Number">
        <label for="Phone Number">Phone Number</label><br>
        <input checked type="checkbox" id="Name" name="Name" value="Name">
        <label for="Name">Name</label><br>
        <input checked type="checkbox" id="Offer Type" name="Offer Type" value="Offer Type">
        <label for="Offer Type">Offer Type</label><br>
        <input checked type="checkbox" id="Geo" name="Geo" value="Geo">
        <label for="Geo">Geo</label><br>
        <input checked type="checkbox" id="Status" name="Status" value="Status">
        <label for="Status">Status</label><br>
        <input checked type="checkbox" id="Reason" name="Reason" value="Reason">
        <label for="Reason">Reason</label><br>
        <input checked type="checkbox" id="Payout" name="Payout" value="Payout">
        <label for="Payout">Payout</label><br>
        <input checked type="checkbox" id="Sub1" name="Sub1" value="Sub1">
        <label for="Sub1">Sub1</label><br>
        <input checked type="checkbox" id="Sub2" name="Sub2" value="Sub2">
        <label for="Sub2">Sub2</label><br>
        <input checked type="checkbox" id="Sub3" name="Sub3" value="Sub3">
        <label for="Sub3">Sub3</label><br>
        <input checked type="checkbox" id="Sub4" name="Sub4" value="Sub4">
        <label for="Sub4">Sub4</label><br>
        `,
        preConfirm: () => {
          return [
            {"key": "created_at", "value": document.getElementById("Created At").checked},
            {"key": "id", "value": document.getElementById("ID").checked},
            {"key": "external_uuid", "value": document.getElementById("External UUID").checked},
            {"key": "web_id", "value": document.getElementById("Web ID").checked},
            {"key": "product_name", "value": document.getElementById("Product Name").checked},
            {"key": "obfuscated_phone_number", "value": document.getElementById("Phone Number").checked},
            {"key": "obfuscated_name", "value": document.getElementById("Name").checked},
            {"key": "offer_type", "value": document.getElementById("Offer Type").checked},
            {"key": "geo", "value": document.getElementById("Geo").checked},
            {"key": "status", "value": document.getElementById("Status").checked},
            {"key": "reason", "value": document.getElementById("Reason").checked},
            {"key": "payout", "value": document.getElementById("Payout").checked},
            {"key": "sub1", "value": document.getElementById("Sub1").checked},
            {"key": "sub2", "value": document.getElementById("Sub2").checked},
            {"key": "sub3", "value": document.getElementById("Sub3").checked},
            {"key": "sub4", "value": document.getElementById("Sub4").checked},
          ];
        }
      }).then(async (result) => {
        console.log(result);
        const columns = result.value.filter(column => column.value === true).map(column => column.key);
        await store.dispatch("leads/exportToCsv", {columns: columns, filter: filters});
      });
    },
    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataLeads = [];
      if (this.leadsList.length > 0) {
        this.leadsList.forEach(lead => {
          this.dataLeads.push(
              getLeadProperties(lead, this.userProfile?.roles[0].name)
          )
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataLeads});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit Lead",
          deletePath: "leads/deleteLead",
          getPath: "leads/getLeads",
          textDelete: "Lead deleted successfully!",
          textDefaultData: 'leads'
        });
        this.handleSubs();
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }

};
</script>

