<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        :custom-label="customLabelForOffer"
        :show-labels="false"
        v-model="offer"
        placeholder="Offer"
        :options="offers ?? []"
        :show-no-results="true"
        :track-by="id"
        label="name"
        select-label='+'
        deselect-label="-"
        :searchable="true">
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    VueMultiselect,
  },
  props: {
    offerId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      offer: null,
      selectedOffer: null,
    }
  },
  computed: {
    ...mapGetters({
      offers: 'offers/getOffersForFilter',
    }),
  },
  watch: {
    offer(newValue) {
      this.updateSelectedOffer(newValue);
    },
  },
  mounted() {
    this.$store.dispatch("offers/getOffersForFilter");

    if ('' !== this.offerId) {
      this.selectedOffer = this.offerId;
    }
  },
  methods: {
    customLabelForOffer({id, name, geo, offer_type}) {
      return `${id} ${name} ${geo} ${offer_type}`;
    },
    // Method to update selectedGeo and emit its value to the parent
    updateSelectedOffer(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offer_id'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offer_id', value: value.id})
      }

      this.$emit('update:updateFilters', filter);
    }
  }
}
</script>
