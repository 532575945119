<template>
  <div class="py-4 container-fluid">
    <custom-payouts-filters v-if="profile" :update-filters="updateFilters" />
    <div class="mt-4 customPayout">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Custom Payouts</h5>
              <div class="d-flex align-items-center">
                <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                  <!-- The "per page" dropdown will be moved here -->
                </div>
                <material-button class="float-right btn btm-sm btn-add-on-listing" @click="this.$router.push({ name: 'Set Custom Payout' })">
                  <i class="fas fa-tag me-2"></i>
                  Set Custom Payout
                </material-button>
              </div>

            </div>
          </div>

          <!-- Card body -->
          <div class="card-body">

            <!-- Table -->
            <div class="table-responsive">
              <table id="customPayout-list" ref="table" class="table table-flush">
                <thead class="thead-light">
                <CustomPayoutsColumns  v-if="loadingFinished" />
                </thead>
                <tbody class="text-sm">
                </tbody>
              </table>
            </div>
          </div>

          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">
            <div class="ms-3">
              <p class="text-sm">
                Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }}
              </p>
            </div>
            <BasePagination v-model="pagination.currentPage" class="pagination-success pagination-md me-3"
                            :value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"
                            @click="getDataFromPage($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";

import MaterialButton from "@/components/MaterialButton.vue";
import BasePagination from "@/components/BasePagination.vue";
import CustomPayoutsColumns from "@/components/customPayouts/CustomPayoutsColumns.vue";
import {getCustomPayoutProperties} from "@/components/customPayouts/customPayoutUtils";
import {mapGetters} from "vuex";
import CustomPayoutsFilters from "@/components/customPayouts/CustomPayoutsFilters.vue";


var sortDirection = "-created_at";
var getCurrentPage = 1;
var filters = {};

const getCustomPayoutsList = _.debounce(async function (params) {
  await store.dispatch("offers/getCustomPayouts", {
    ...(params.sort ? { sort: params.sort } : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "CustomPayoutsList",
  components: {
    CustomPayoutsFilters,
    CustomPayoutsColumns,
    MaterialButton,
    BasePagination
  },
  data() {
    return {
      search: '',
      dataCustomPayouts: [],
      pagination: {},
      dataTable: null,
      keys: null,
      Status: '',
      loadingFinished: false
    }
  },
  mixins: [eventTable],
  computed: {
    customPayoutsList() {
      return this.$store.getters["offers/getCustomPayoutsData"];
    },
    metaPage() {
      return this.$store.getters["offers/getCustomPayoutsMeta"];
    },
    ...mapGetters({
      profile: "profile/getUserProfile"
    })
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    customPayoutsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch"
    },
  },
  async mounted() {
    await store.dispatch("profile/getProfile");
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 25,
      });

      document.querySelector(".dataTable-bottom").remove()
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      await getCustomPayoutsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });

      // Wait for the table to be initialized before moving the dropdown
      // Select the "per page" dropdown. The class or ID might change based on the library's implementation
      var perPageDropdown = document.querySelector(".dataTable-dropdown");

      // Select the new target location for the dropdown
      var newLocation = document.getElementById("newPerPagePosition");

      // Move the dropdown to the new location
      if (perPageDropdown && newLocation) {
        newLocation.appendChild(perPageDropdown);
      }

      this.dataTable.on('datatable.perpage', async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getCustomPayoutsList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.dataTable.on('datatable.sort', async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getCustomPayoutsList({
          sort: sortDirection = direction + column,
          query: filters,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    };
  },

  beforeUnmount() {
    sortDirection = "-created_at";
    filters = {};
    getCurrentPage = 1;
  },

  methods: {
    async refreshList() {
      await getCustomPayoutsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 25
      });
    },
    async updateFilters(updatedFilter) {
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }
      console.log(filters);
      await this.refreshList();
    },
    role() {
      return this.$store.getters['profile/getUserProfile']?.roles[0]?.name;
    },
    async getDataFromPage(page) {
      await getCustomPayoutsList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactiveSearch() {
      await getCustomPayoutsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);

      this.pagination = {
        currentPage: this.pagination[this.keys[0]],
        perPage: this.pagination[this.keys[3]],
        total: this.pagination[this.keys[5]]
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataCustomPayouts = [];
      if (this.customPayoutsList.length > 0) {
        this.customPayoutsList.forEach(customPayout => {
          this.dataCustomPayouts.push(
              getCustomPayoutProperties(customPayout)
          )
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({ data: this.dataCustomPayouts });
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit CustomPayout",
          deletePath: "offers/deleteCustomPayout",
          getPath: "offers/getCustomPayouts",
          textDelete: "CustomPayout deleted successfully!",
          textDefaultData: 'customPayouts'
        });
      }
      else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }

};
</script>
