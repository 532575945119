<template>
  <div class="col-12">
    <h5 class="ms-3">{{ title }}</h5>
    <div class="table table-responsive">
      <table class="table mb-0 align-items-center">
        <thead>
        <tr>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
          >
            File
          </th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(member, index) of files"
            :key="index">
          <td>
            <a
                :href="member.full_url"
                v-text="member.file_name"/>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Attachments",
  props: {
    files: {
      type: Array,
    },
    title: {
      type: String,
    }
  }
};
</script>
