<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedRole"
        :options="activityOptions"
        deselect-label="-"
        label="name"
        placeholder="Filter by role"
        select-label='+'
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      selectedRole: null,
      activityOptions: [
        {name: 'admin', value: 'admin'},
        {name: 'Affiliate Manager', value: 'affiliate_manager'},
        {name: 'Partner', value: 'partner'},
        {name: 'Affiliate', value: 'affiliate'},
        {name: 'Advertiser', value: 'advertiser'},
      ]
    };
  },

  watch: {
    selectedRole(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (value === null || '' === value?.value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'role_name'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'role_name', value: value.value})
      }

      this.$emit('update:updateFilters', filter);
    },
  },
};
</script>

<style scoped>
</style>
