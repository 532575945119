/* eslint-disable no-unused-vars */
import campaignsService from '../services/campaigns.service';

const initialState = {campaigns: null, campaign: null, promotedOfferIds: null, domains: null};

export const campaigns = {
    namespaced: true,
    state: initialState,
    actions: {
        async getCampaigns({commit}, options) {
            const campaigns = await campaignsService.getCampaigns(options);
            commit('getCampaignsSuccess', campaigns);
        },

        async myCampaigns({commit}, options) {
            const campaigns = await campaignsService.myCampaigns(options);
            commit('getCampaignsSuccess', campaigns);
        },

        async getDomains({commit}, options) {
            const domains = await campaignsService.getDomains(options);
            commit('getDomainsSuccess', domains);
        },

        async getCampaignsForDashboard({commit}, options) {
            const domains = await campaignsService.getCampaignsForDashboard(options);
            commit('getCampaignsSuccess', domains);
        },

        async getAdvertisersCampaignsForDashboard({commit}, options) {
            const domains = await campaignsService.getAdvertisersCampaignsForDashboard(options);
            commit('getCampaignsSuccess', domains);
        },
        async getCampaignsListAdmin({commit}, options) {
            const campaigns = await campaignsService.getCampaignsAdmin(options);
            commit('getCampaignsSuccess', campaigns);
            commit('getPromotedOfferIdsSuccess', campaigns?.data.map(elem => elem.offerId))
        },

        async addCampaign({commit}, newCampaign) {
            await campaignsService.addCampaign(newCampaign);
        },

        async deleteCampaign({commit}, campaignId) {
            await campaignsService.deleteCampaign(campaignId);
        },

        async getCampaignSimple({commit}, campaignId) {
            const campaign = await campaignsService.getCampaignSimple(campaignId);
            commit('getCampaignSuccess', campaign)
        },

        async getCampaign({commit}, campaignId) {
            const campaign = await campaignsService.getCampaign(campaignId);
            commit('getCampaignSuccess', campaign)
        },

        async editCampaign({commit}, modifiedCampaign) {
            await campaignsService.editCampaign(modifiedCampaign);
        },

        async generateLinks({commit}, modifiedCampaign) {
            await campaignsService.generateLinks(modifiedCampaign);
        },

        async acceptCampaign({commit}, modifiedCampaign) {
            await campaignsService.acceptCampaign(modifiedCampaign);
        },

        async downloadLandingPage({commit}, campaignId) {
            await campaignsService.downloadLandingPage(campaignId);
        }
    },
    mutations: {
        getDomainsSuccess(state, domains) {
            state.domains = domains;
        },
        getCampaignsSuccess(state, campaigns) {
            state.campaigns = campaigns;
        },
        getPromotedOfferIdsSuccess(state, promotedOfferIds) {
            state.promotedOfferIds = promotedOfferIds;
        },
        setCampaignForAcceptance(state, campaign) {
            state.campaign = campaign;
        },
        getCampaignSuccess(state, campaign) {
            console.log('saveCampaign', campaign);
            state.campaign = campaign;
        }
    },
    getters: {
        getDomains(state) {
            return state.domains?.data;
        },
        getCampaignsData(state) {
            return state.campaigns?.data;
        },
        getPromotedOfferIds(state) {
            return state.promotedOfferIds;
        },
        getCampaignsMeta(state) {
            return state.campaigns?.meta;
        },
        getCampaign(state) {
            console.log('getCampaign', state.campaign);
            return state.campaign;
        },
    }
}
