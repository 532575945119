<template>
  <PostbackUrlGenerator :user-id="userId" />
</template>

<script>

import PostbackUrlGenerator from "@/components/PostbackUrlGenerator.vue";

export default {
  name: 'GlobalPostbackUrlGenerator',
  components: {PostbackUrlGenerator},
  props: {
    userId: {
      type: String,
      default: null
    }
  },
}
</script>
