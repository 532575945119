/* eslint-disable no-unused-vars */
import filesService from '../services/files.service';

const initialState = { files: null, file: null, filesIds: null};

export const files = {
    namespaced: true,
    state: initialState,
    actions: {
        async getFiles({ commit }, options) {
            const files = await filesService.getFiles(options);
            commit('getFilesSuccess', files);
        },

        async getFilesIds({ commit }, options) {
            if(null === this.state.files.filesIds) {
                const filesIds = await filesService.getFilesIds(options);
                commit('getFilesIdsSuccess', filesIds);
            }
        },

        async addBannerFiles({ commit }, file) {
            const fileResponse = (await filesService.addBannerFiles(file, this.state.files.file.id)).url;
            commit('addBannerFilesSuccess', fileResponse)
        },

        async addLandingPageFiles({ commit }, file) {
            const fileResponse = (await filesService.addLandingPageFiles(file, this.state.files.file.id)).url;
            commit('addFileSuccess', fileResponse)
        },

        async addFile({ commit }, newFile) {
            const file =await filesService.addFile(newFile);
            commit('addFileSuccess', file)
        },

        async deleteFile({ commit }, fileId) {
            await filesService.deleteFile(fileId);
        },

        async getFile({ commit }, fileId) {
            const file = await filesService.getFile(fileId);
            commit('getFileSuccess', file)
        },

        async editFile({ commit }, modifiedFile) {
            await filesService.editFile(modifiedFile);
        },
    },
    mutations: {
        getFileSuccess(state, file) {
            state.file = file;
        },
        addFileSuccess(state, file) {
            state.file = file
        },
        getFilesSuccess(state, files) {
            state.files = files;
        },
        getFilesIdsSuccess(state, filesIds) {
            state.filesIds = filesIds;
        },
        setFileForPage(state, file) {
            state.file = file;
        }
    },
    getters: {
        getFile(state) {
            return state.file
        },
        getFilesData(state) {
            return state.files?.data;
        },
        getFilesIds(state) {
            return state.filesIds?.data;
        },
        getFilesMeta(state) {
            return state.files?.meta;
        }
    }
}

