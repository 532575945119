<template>
  <div class="py-4 container-fluid">
    <withdrawals-filters v-if="profile" :update-filters="updateFilters"/>
    <div class="mt-4 withdrawal">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Withdrawals</h5>
              <div class="d-flex align-items-center">
                <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                  <!-- The "per page" dropdown will be moved here -->
                </div>
                <material-button v-if="role() === 'affiliate' || role() === 'partner' || role() === 'advertiser'"
                                 class="float-right btn btm-sm btn-add-on-listing"
                                 @click="this.$router.push({ name: 'Add Withdrawal' })">
                  <i class="fas fa-tag me-2"></i>
                  Withdrawal request
                </material-button>
              </div>

            </div>
          </div>

          <!-- Card body -->
          <div class="card-body">

            <!-- Table -->
            <div class="table-responsive">
              <table id="withdrawal-list" ref="table" class="table table-flush">
                <thead class="thead-light">
                <WithdrawalsColumns v-if="loadingFinished"/>
                </thead>
                <tbody class="text-sm">
                </tbody>
              </table>
            </div>
          </div>

          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">
            <div class="ms-3">
              <p class="text-sm">
                Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }}
              </p>
            </div>
            <BasePagination v-model="pagination.currentPage" :per-page="pagination.perPage"
                            :total="pagination.total" :value="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            @click="getDataFromPage($event)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";

import MaterialButton from "@/components/MaterialButton.vue";
import BasePagination from "@/components/BasePagination.vue";
import WithdrawalsColumns from "@/components/withdrawals/WithdrawalsColumns.vue";
import {getWithdrawalProperties} from "@/components/withdrawals/withdrawalsUtils";
import {mapGetters} from "vuex";
import WithdrawalsFilters from "@/components/withdrawals/WithdrawalsFilters.vue";


var sortDirection = "-created_at";
var getCurrentPage = 1;
var filters = {};

const getWithdrawalsList = _.debounce(async function (params) {
  await store.dispatch("withdrawals/getWithdrawals", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "WithdrawalsList",
  components: {
    WithdrawalsFilters,
    WithdrawalsColumns,
    MaterialButton,
    BasePagination
  },
  data() {
    return {
      search: '',
      dataWithdrawals: [],
      pagination: {},
      dataTable: null,
      keys: null,
      Status: '',
      loadingFinished: false
    }
  },
  mixins: [eventTable],
  computed: {
    withdrawalsList() {
      return this.$store.getters["withdrawals/getWithdrawalsData"];
    },
    metaPage() {
      return this.$store.getters["withdrawals/getWithdrawalsMeta"];
    },
    ...mapGetters({
      profile: "profile/getUserProfile"
    })
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    withdrawalsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch"
    },
  },
  async mounted() {
    await store.dispatch("profile/getProfile");
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 25,
      });

      document.querySelector(".dataTable-bottom").remove()
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      await getWithdrawalsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });

      // Wait for the table to be initialized before moving the dropdown
      // Select the "per page" dropdown. The class or ID might change based on the library's implementation
      var perPageDropdown = document.querySelector(".dataTable-dropdown");

      // Select the new target location for the dropdown
      var newLocation = document.getElementById("newPerPagePosition");

      // Move the dropdown to the new location
      if (perPageDropdown && newLocation) {
        newLocation.appendChild(perPageDropdown);
      }

      this.dataTable.on('datatable.perpage', async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getWithdrawalsList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.dataTable.on('datatable.sort', async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getWithdrawalsList({
          sort: sortDirection = direction + column,
          query: filters,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    }

  },

  beforeUnmount() {
    sortDirection = "-created_at";
    filters = {};
    getCurrentPage = 1;
  },

  methods: {
    async refreshList() {
      await getWithdrawalsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 25
      });
    },
    async updateFilters(updatedFilter) {
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }
      console.log(filters);
      await this.refreshList();
    },
    role() {
      return this.$store.getters['profile/getUserProfile']?.roles[0]?.name;
    },
    async getDataFromPage(page) {
      await getWithdrawalsList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactiveSearch() {
      await getWithdrawalsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);

      this.pagination = {
        currentPage: this.pagination[this.keys[0]],
        perPage: this.pagination[this.keys[3]],
        total: this.pagination[this.keys[5]]
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataWithdrawals = [];
      if (this.withdrawalsList.length > 0) {
        this.withdrawalsList.forEach(withdrawal => {
          this.dataWithdrawals.push(
              getWithdrawalProperties(withdrawal, this.role())
          )
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataWithdrawals});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit Withdrawal",
          deletePath: "withdrawals/deleteWithdrawal",
          getPath: "withdrawals/getWithdrawals",
          textDelete: "Withdrawal deleted successfully!",
          textDefaultData: 'withdrawals'
        });
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }

};
</script>
