<template>
  <div v-if="role === 'admin' || role === 'affiliate_manager'" class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="searchPartnerId"
        placeholder="Partner"
        label="name"
        track-by="id"
        :options="userOptions ?? []"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      searchPartnerId: null,
    };
  },

  computed: {
    ...mapGetters({
      userOptions: "users/getPartnersIds",
      userProfile: "profile/getUserProfile",
    }),
    role() {
      return this.userProfile?.roles[0]?.name;
    },
  },
  watch: {
    searchPartnerId: {
      handler: "updateSearchPartnerId"
    },
    userProfile(newVal) {
      if (newVal && (newVal?.roles[0].name === 'admin' || newVal?.roles[0].name === 'affiliate_manager')) {
        this.$store.dispatch("users/getPartnerIds");
      }
    }
  },
  created() {
    this.$store.dispatch("users/getPartnerIds");
  },
  methods: {
    // Method to update searchPartnerId and emit its value to the parent
    updateSearchPartnerId(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'partner_ext_id'})
        store.dispatch("users/getWebsIdsWithNames", {filter: {partner_ext_id: null}});
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'partner_ext_id', value: value.ext_id})
        store.dispatch("users/getWebsIdsWithNames", {filter: {partner_ext_id: value.ext_id}});

      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
