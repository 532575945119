<template>
  <div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import router from "@/router";

export default {
  name: "Home",
  async beforeMount() {
    await store.dispatch("profile/setInitialState");
    await store.dispatch("profile/getProfile").then(() => {
          if (this.userProfile.roles[0].name === 'affiliate') {
            router.push({name: "Web Campaigns Dashboard", params: {webId: this.userProfile.ext_id}});
          } else {
            this.$router.push({name: "Webs Dashboard"});
          }
        }
    );
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      userProfile: 'profile/getUserProfile',
    })
  },
};
</script>
