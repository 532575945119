import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async deleteCustomPayout(id) {
        const response = await axios.post(
            "/offers/-actions/delete-custom-payout", {
                "id": id,
            },
            {
                headers: authHeader(),
            });

        return {data: response.data.data, meta: response.data};
    },
    async getCustomPayouts(params) {
        const response = await axios.get(
            "/offers/-actions/custom-payouts-list", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },
    async getCustomPayout(data) {
        const response = await axios.get(
            "/offers/-actions/custom-payout", {
                headers: authHeader(),
                params: data
            });

        return {data: response.data};
    },
    editCustomPayout(data) {
        return axios.post(
            "/offers/-actions/edit-custom-payout", {data},
            {
                headers: authHeader(),
            });
    },
    async createCustomPayout(data) {
        const response = await axios.post(
            "/offers/-actions/create-custom-payout", {data},
            {
                headers: authHeader(),
            });

        return {data: response.data.data, meta: response.data};
    },
    async shareOffer(data) {
        const response = await axios.post(
            "/offers/-actions/share-offer", {data},
            {
                headers: authHeader(),
            });

        return {data: response.data.data, meta: response.data};
    },

    async getOffers(params) {
        const response = await axios.get(
            "/offers/-actions/offers-list", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },

    async getOffersIds(params) {
        const response = await axios.get("/offers/-actions/offers-ids", {headers: authHeader(), params: params});
        return {data: response.data};
    },

    async addBannerFiles(file, offerId) {
        let user = JSON.parse(localStorage.getItem('user'));

        const postUrl = "/uploads/offer-banner-files/offers/" + offerId + "/banner-files";
        const response = await axios.post(postUrl,
            {attachment: file},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + user,
                }
            }
        );

        return response.data;
    },

    async addLandingPageFiles(file, offerId) {
        let user = JSON.parse(localStorage.getItem('user'));
        const postUrl = "/uploads/landing-page-files/offers/" + offerId + "/landing-page-files";
        const response = await axios.post(postUrl,
            {attachment: file},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + user,
                }
            }
        );

        return response.data;
    },

    async addOffer(offer) {
        offer.type = "offers"
        console.log('offer', offer);
        const newJson = dataFormatter.serialize({stuff: offer, includeNames: ['product']})
        console.log('newJson', newJson);

        const response = await axios.post("/offers", newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async deleteOffer(offerId) {
        const now = new Date();
        const offer = {};
        offer.type = "offers"
        offer.id = offerId;
        offer.deletedAt = now.toISOString();
        const newJson = dataFormatter.serialize({stuff: offer})
        await axios.patch("/offers/" + offerId, newJson, {headers: authHeader()});
    },

    async getOffer(offerId) {
        const response = await axios.get("/offers/" + offerId + "?include=files,product.files", {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async editOffer(offer) {
        offer.type = "offers"
        const newJson = dataFormatter.serialize({stuff: offer})
        const response = await axios.patch("/offers/" + offer.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async generateLinks(offer) {
        offer.type = "offers"
        const newJson = dataFormatter.serialize({stuff: offer})
        const response = await axios.patch("/offers/" + offer.id + "/-actions/generate-links", newJson, {headers: authHeader()});
        return response.data;
    },
}
