<template>
  <div class="container-fluid mt-4">
<!--    <div class="row align-items-center">-->
<!--      <div class="col-lg-4 col-sm-8">-->
<!--        <nav-pill />-->
<!--      </div>-->
<!--    </div>-->
    <div class="row mb-5">

      <div>
        <side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import SideNavItem from "./components/SideNavItem.vue";

export default {
  name: "AffiliateProfile",
  components: {
    SideNavItem,
  },
};
</script>
