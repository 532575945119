<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <!-- Card body -->
    <div class="card-body">
      <div class="row mt-sm-2">
        <OfferFilter @update:updateFilters="updateFilters"/>
        <GeoFilter @update:updateFilters="updateFilters"/>
        <ProductNameFilter @update:updateFilters="updateFilters"/>
        <OfferTypeFilter @update:updateFilters="updateFilters"/>
        <OffersNicheFilter @update:updateFilters="updateFilters"/>
        <OfferPrivacyLevelFilter v-if="role === 'admin' || role === 'affiliate_manager'" @update:updateFilters="updateFilters"/>
        <InHouseOfferFilter v-if="role === 'admin' || role === 'affiliate_manager'" @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import GeoFilter from "@/components/Filters/GeoFilter.vue"
import ProductNameFilter from "@/components/Filters/ProductNameFilter.vue"
import OffersNicheFilter from "@/components/Filters/OffersNicheFilter.vue";
import OfferTypeFilter from "@/components/Filters/OfferTypeFilter.vue";
import OfferPrivacyLevelFilter from "@/components/Filters/OfferPrivacyLevelFilter.vue";
import InHouseOfferFilter from "@/components/Filters/InHouseOfferFilter.vue";
import OfferFilter from "@/components/Filters/OfferFilter.vue";

export default {
  name: 'OffersFilters',
  components: {
    OfferFilter,
    InHouseOfferFilter,
    OfferPrivacyLevelFilter,
    OfferTypeFilter,
    OffersNicheFilter, GeoFilter, ProductNameFilter},
  props: {
    updateFilters: {},
    role: {
      type: String,
      default: ''
    }
  }
}
</script>
