/* eslint-disable no-unused-vars */
import productsService from '../services/products.service';

const initialState = {products: null, product: null, productNames: null, productNamesWithIds: null};

export const products = {
    namespaced: true,
    state: initialState,
    actions: {
        async getProducts({commit}, options) {
            const products = await productsService.getProducts(options);
            commit('getProductsSuccess', products);
        },

        async getProductNames({commit}, options) {
            if (null == this.state.products.productNames) {
                const productNames = await productsService.getProductNames(options);
                commit('getProductNamesSuccess', productNames);
            }
        },

        async getProductNamesWithIds({commit}, options) {
            if (null == this.state.products.productNamesWithIds) {
                const productNamesWithIds = await productsService.getProductNamesWithIds(options);
                commit('getProductNamesWithIdsSuccess', productNamesWithIds);
            }

        },

        async addProduct({commit}, newProduct) {
            await productsService.addProduct(newProduct);
        },

        async deleteProduct({commit}, productId) {
            await productsService.deleteProduct(productId);
        },

        async getProduct({commit}, productId) {
            const product = await productsService.getProduct(productId);
            commit('getProductSuccess', product)
        },

        async editProduct({commit}, modifiedProduct) {
            await productsService.editProduct(modifiedProduct);
        },
    },
    mutations: {
        getProductsSuccess(state, products) {
            state.products = products;
        },
        getProductNamesSuccess(state, productNames) {
            state.productNames = productNames;
        },
        getProductNamesWithIdsSuccess(state, productNamesWithIds) {
            state.productNamesWithIds = productNamesWithIds;
        },
        getProductSuccess(state, product) {
            state.product = product;
        },
    },
    getters: {
        getProductsData(state) {
            return state.products?.data;
        },
        getProductNamesData(state) {
            return state.productNames?.data;
        },
        getProductNamesWithIdsData(state) {
            return state.productNamesWithIds?.data;
        },
        getProductsMeta(state) {
            return state.products?.meta;
        },
        getProduct(state) {
            return state.product;
        }
    }
}
