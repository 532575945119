<template>
  <!--  <div v-if="this.loading">-->
  <!--    <Spinner/>-->
  <!--  </div>-->
  <aside
      id="sidenav-main"
      class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
      :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
  >
<!--    //center element below-->
    <div style="display: flex; justify-content: center; align-items: center;text-align:center;">
      <router-link class="" to="/">
        <!--        <img-->
        <!--          :src="-->
        <!--            sidebarType === 'bg-white' ||-->
        <!--            (sidebarType === 'bg-transparent' && !isDarkMode)-->
        <!--              ? logoDark-->
        <!--              : logo-->
        <!--          "-->
        <!--          class="navbar-brand-img h-100"-->
        <!--          alt="main_logo"-->
        <!--        />-->
        <img
            src="../../assets/img/logos/azurelab_network_logo.svg"
            class="avatar avatar-azurelab"
            alt="logo_xd"
        />
      </router-link>
    </div>

<!--    <div class="sidenav-header pe-10">-->
<!--      <i-->
<!--          id="iconSidenav"-->
<!--          class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"-->
<!--          aria-hidden="true"-->
<!--      ></i>-->
<!--      <router-link class="" to="/">-->
<!--        &lt;!&ndash;        <img&ndash;&gt;-->
<!--        &lt;!&ndash;          :src="&ndash;&gt;-->
<!--        &lt;!&ndash;            sidebarType === 'bg-white' ||&ndash;&gt;-->
<!--        &lt;!&ndash;            (sidebarType === 'bg-transparent' && !isDarkMode)&ndash;&gt;-->
<!--        &lt;!&ndash;              ? logoDark&ndash;&gt;-->
<!--        &lt;!&ndash;              : logo&ndash;&gt;-->
<!--        &lt;!&ndash;          "&ndash;&gt;-->
<!--        &lt;!&ndash;          class="navbar-brand-img h-100"&ndash;&gt;-->
<!--        &lt;!&ndash;          alt="main_logo"&ndash;&gt;-->
<!--        &lt;!&ndash;        />&ndash;&gt;-->
<!--        <span class="text-center font-weight-bold text-white" style="text-align: center;">-->
<!--          <img-->
<!--              src="../../assets/img/logos/azurelab_network_logo.svg"-->
<!--              class="avatar avatar-xxl"-->
<!--              alt="logo_xd"-->
<!--          />-->
<!--        </span>-->

<!--      </router-link>-->
<!--    </div>-->
    <hr class="horizontal light mt-0 mb-2"/>
    <sidenav-list/>
  </aside>


</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
import {mapState} from "vuex";
// import Spinner from "@/components/Spinner.vue";

export default {
  name: "Index",
  components: {
    // Spinner,
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
      loading: true
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
  },
};
</script>
