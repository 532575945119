<template>
  <div class="py-4 container-fluid">
    <div v-if="offer" class="mt-4 row">
      <div class="col-9">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Generate Links</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Offers List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>

              <div class="col-10 mt-5">
                <label class="ms-0"> Choose domain </label>
                <select id="domain-choices-state" class="multisteps-form__select form-control" name="domain-choices-state">
                </select>
              </div>

              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="offer.prelanding_link" label="Prelanding link"
                                  variant="static"
                                  type="text"
                                  name="prelanding_link"></material-input>
                  <validation-error :errors="apiValidationErrors.prelanding_link"></validation-error>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="offer.landing_link" label="Landing link"
                                  variant="static"
                                  type="text"
                                  name="landing_link"></material-input>
                  <validation-error :errors="apiValidationErrors.landing_link"></validation-error>
                </div>

              </div>
              <div class="row mt-5">
<!--                <div class="col-2">-->
<!--                  <material-input id="value" v-model:value="sub1" label="sub1"-->
<!--                                  variant="static"-->
<!--                                  type="text"-->
<!--                                  name="sub1"></material-input>-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <material-input id="value" v-model:value="sub2" label="sub2"-->
<!--                                  variant="static"-->
<!--                                  type="text"-->
<!--                                  name="sub2"></material-input>-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <material-input id="value" v-model:value="sub3" label="sub3"-->
<!--                                  variant="static"-->
<!--                                  type="text"-->
<!--                                  name="sub3"></material-input>-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <material-input id="value" v-model:value="sub4" label="sub4"-->
<!--                                  variant="static"-->
<!--                                  type="text"-->
<!--                                  name="sub4"></material-input>-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <material-input id="value" v-model:value="sub5" label="sub5"-->
<!--                                  variant="static"-->
<!--                                  type="text"-->
<!--                                  name="sub5"></material-input>-->
<!--                </div>-->
                <div class="col-12 text-end">
                  <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Add
                  </material-button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "@/mixins/showSwal";
import {mapGetters} from "vuex";
import Choices from "choices.js";

export default {
  name: 'LinkGenerator',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
  },
  data() {
    return {
      domainChoices: null,
      domainNamePrelandingLink: '',
      domainNameLandingLink: '',
      sub1: '',
      sub2: '',
      sub3: '',
      sub4: '',
      sub5: '',
    }
  },
  props: {
    offerId: {
      type: String,
      default: ''
    },
  },
  watch: {
    sub1: {
      handler: "refreshLinks",
    },
    sub2: {
      handler: "refreshLinks",
    },
    sub3: {
      handler: "refreshLinks",
    },
    sub4: {
      handler: "refreshLinks",
    },
    sub5: {
      handler: "refreshLinks",
    },
  },
  computed: {
    ...mapGetters({
      offers: "offers/getOffersData",
      offer: "offers/getOffer"
    }),
    domainsList() {
      return this.$store.getters['campaigns/getDomains'].map(elem => {return {label: elem, value: elem}});
    },
  },
  mixins: [formMixin],
  async created() {
    await this.$store.dispatch('offers/getOffer', this.offerId);
  },
  async mounted() {
    if('' === this.offerId) {
      this.$router.push({ name: 'Offers List' })
    }

    try {
      //get roles
      await this.$store.dispatch('campaigns/getDomains');

      if (document.getElementById("domain-choices-state")) {
        var element = document.getElementById("domain-choices-state");
        element.addEventListener('change', (event) => {
          this.domainNamePrelandingLink = event.target.value
          this.domainNameLandingLink = event.target.value
          this.refreshLinks()
        })
        this.domainChoices = new Choices(element, {
          searchEnabled: false,
        });

        const choices = this.domainsList.map((elem) => {
          return {value: elem.value, label: elem.label, selected: false}
        })
        choices[0].selected = true;
        //
        console.log('choices', choices);
        await this.domainChoices.setChoices(choices, 'value', 'label');
        // this.domainChoices.setChoices([
        //   {value: 'One', label: 'Label One', disabled: true},
        //   {value: 'Two', label: 'Label Two', selected: true},
        //   {value: 'Three', label: 'Label Three'},
        // ], 'value', 'label', false);
        //
        // if (this.domainsList.length === 1) {
        //   add default value to domainChoices if only one domain is available
          // this.domainChoices.setValues([this.domainsList[0].value])
        // } else {
        //   this.domainChoices.setChoices(this.domainsList)
        //   this.domainChoices.setChoiceByValue(this.domainsList[0].id)
        // }
      }
    } catch (error) {
      showSwal.methods.showSwal({
        type: "error",
        message: "Oops, something went wrong!",
        width: 500
      });
    } finally {
      this.loading = false
    }

    if (this.offer?.landingLink) {
      const url = new URL(this.offer.landingLink);
      const params = url.searchParams;
      this.domainNameLandingLink = url.origin;
      this.domainNamePrelandingLink = url.origin;
      this.sub1 = params.get('sub1') ?? '';
      this.sub2 = params.get('sub2') ?? '';
      this.sub3 = params.get('sub3') ?? '';
      this.sub4 = params.get('sub4') ?? '';
      this.sub5 = params.get('sub5') ?? '';
    }
  },
  methods: {
    refreshLinks() {
      let landingLink = this.domainNameLandingLink + '/';
      let prelandingLink = this.domainNamePrelandingLink + '/';

      if('' !== this.sub1 || '' !== this.sub2 || '' !== this.sub3 || '' !== this.sub4 || '' !== this.sub5) {
        landingLink += '?'
        prelandingLink += '?'
      }

      if('' !== this.sub1) {
        landingLink = this.addSub(landingLink, 'sub1', this.sub1);
        prelandingLink = this.addSub(prelandingLink, 'sub1', this.sub1);
      }

      if('' !== this.sub2) {
        landingLink = this.addSub(landingLink, 'sub2', this.sub2);
        prelandingLink = this.addSub(prelandingLink, 'sub2', this.sub2);
      }

      if('' !== this.sub3) {
        landingLink = this.addSub(landingLink, 'sub3', this.sub3);
        prelandingLink = this.addSub(prelandingLink, 'sub3', this.sub3);
      }

      if('' !== this.sub4) {
        landingLink = this.addSub(landingLink, 'sub4', this.sub4);
        prelandingLink = this.addSub(prelandingLink, 'sub4', this.sub4);
      }

      if('' !== this.sub5) {
        landingLink = this.addSub(landingLink, 'sub5', this.sub5);
        prelandingLink = this.addSub(prelandingLink, 'sub5', this.sub5);
      }

      this.offer.prelandingLink = prelandingLink;
      this.offer.landingLink = landingLink;
    },

    addSub(link, subLabel, subValue) {
      if('?' === link.charAt(link.length - 1)) {
        return link + `${subLabel}=${subValue}`;
      } else {
        return link + `&${subLabel}=${subValue}`;
      }
    },

    async handleAdd() {
      this.resetApiValidation();
      try {
        await this.$store.dispatch("offers/generateLinks", {
          prelanding_link: this.offer.prelanding_link,
          landing_link: this.offer.landing_link,
          id: this.offer.id
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Offer added successfully!",
          width: 500
        });
        this.$router.push({name: "Offers List"})
      } catch (error) {
        console.log("error",error);
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, something went wrong!",
          width: 500
        });

      }
    }
  }
}
</script>
