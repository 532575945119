<template>
  <tr class="dz-clickable">
    <th data-sortable="false" class="w-5" title="id">Id</th>
    <th data-sortable="false" class="w-10">Image</th>
    <th title="name" data-sortable="false" >Name</th>
    <th v-if="role === 'admin' || role ==='affiliate_manager'" title="product_id" data-sortable="false" >Product ID</th>
    <th title="approve_rate" data-sortable="false" >AR</th>
    <th title="offer_type" data-sortable="false">Type</th>
    <th title="metric_type" data-sortable="false">Metric type</th>
    <th title="geo">Geo</th>
    <th v-if="role !== 'affiliate'" title="promoters">Promoters</th>
    <th title="niche" data-sortable="false">Niche</th>
    <th title="price" >Price</th>
    <th title="payout" >Payout</th>
    <th v-if="role === 'admin' || role ==='affiliate_manager'" title="links" class="w-5">Links</th>
    <th data-sortable="false">Action</th>
  </tr>
</template>
<script>
export default {
  name: 'OffersColumns',
  props: {
    role: {
      type: String,
      default: ''
    },
  }
}
</script>
