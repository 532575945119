<template>
  <div class="py-4">
    <div class="campaign">
      <div class="card">
        <!-- Card header -->
        <div class="card-header border-bottom">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">Top offers</h5>
            </div>
            <div class="col-auto">
              <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                <!-- The "per page" dropdown will be moved here -->
              </div>
            </div>
          </div>
        </div>

        <!-- Card body -->
        <div class="card-body">

          <!-- Table -->
          <div class="table-responsive">
            <table id="campaign-list" ref="table" class="table table-flush">
              <thead class="thead-light">
              <tr v-if="loadingFinished">
                <th class="w-5" data-sortable="false" title="image"></th>
                <th class="w-5" data-sortable="false" title="name">Name</th>
                <th class="w-5" data-sortable="false" title="geo">Geo</th>
                <th class="w-5" data-sortable="false" title="niche">Niche</th>
                <th class="w-5" data-sortable="false" title="ar">AR</th>
                <th class="w-5" data-sortable="false" title="payout">Payout</th>
              </tr>
              </thead>
              <tbody class="text-sm">
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";

var sortDirection = "-total_approved";
var getCurrentPage = 1;
var filters = {};
const getOffersList = _.debounce(async function (params) {
  await store.dispatch("offers/getTopOffers", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "TopOffersList",
  mixins: [eventTable],
  props: {
    updatedFilters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      filters: {},
      search: '',
      dataWebs: [],
      pagination: {},
      dataTable: null,
      keys: null,
      loadingFinished: false
    }
  },
  computed: {
    offersList() {
      return this.$store.getters["offers/getTopOffers"];
    },
    metaPage() {
      return this.$store.getters["users/getUsersMeta"];
    },
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    offersList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
  },
  async mounted() {
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 5,
        perPageSelect: false
      });

      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      await getOffersList({
        sort: sortDirection,
        query: '',
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });
    }
  },
  beforeUnmount() {
    sortDirection = "-total_approved";
    getCurrentPage = 1;
    filters = {};
  },
  methods: {
    async refreshList() {
      await getOffersList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 25
      });
    },

    async getDataFromPage(page) {
      await getOffersList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataOffers = [];
      if (this.offersList.length > 0) {
        this.offersList.forEach(offer => {
          this.dataOffers.push(
              [
                (null !== offer.image_url ? `<img src="${offer.image_url}" class="avatar-offer-xxl">` : ''),
                (offer.in_house ? `<span><span style="display: block;">${offer.name}</span><span
                style="font-size: 10px; background: rgba(143, 230, 143, 0.5); padding: 1px 5px; border-radius: 4px; color: black; display: inline-block;">In house</span></span>`
                    : offer.name),
                `<div ><span class="fi fi-${offer.geo.toLowerCase()} fis flag-span" title="${offer.geo}"></span> ${offer.geo}</div>`,
                offer.niche,
                offer.approve_rate.toString() + "%",
                offer.payout,
              ])
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataOffers});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit Offer",
          deletePath: "offers/deleteOffer",
          getPath: "offers/getOffers",
          textDelete: "Offer deleted successfully!",
          textDefaultData: 'offers'
        });
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }
};
</script>
