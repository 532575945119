<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <!-- Card body -->
    <div class="card-body">
      <div class="row mt-sm-2">
        <UsersPartnerIDFilter v-if="role !== 'affiliate'" @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import UsersPartnerIDFilter from "@/components/Filters/UsersPartnerIDFilter.vue";

export default {
  name: 'BlockedWebsFilters',
  components: {
    UsersPartnerIDFilter,
  },
  props: {
    updateFilters: {},
    role: {
      type: String,
      default: ''
    }
  }
}
</script>
