<template>
  <div v-if="user" class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Add Deposit</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Deposits List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <Form :validation-schema="schema" class="text-start mt-3 align-items-end" role="form">
              <div class="col">

                <div class="mt-3 mb-5 col-6">
                  <label for="deposit_type" style="margin-left: 0;">Type</label>
                  <UsersAdvertiserIDFilter @update:update-filters="updateAdvertiser"/>
                </div>

                <div class="mt-5 mb-5 col-2">
                  <material-input-field id="deposit_value" v-model:value="deposit.deposit_value"
                                        label="How much? (in $)"
                                        name="deposit_value" type="number" variant="static"/>
                </div>
              </div>
            </Form>

            <div class="add-request-btn col-2">
              <material-button class="btn btm-sm" @click.prevent="handleAdd">Add Deposit
              </material-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../mixins/showSwal";
import {mapGetters} from "vuex";
import MaterialInputField from "@/components/MaterialInputField.vue";
import UsersAdvertiserIDFilter from "@/components/Filters/UsersAdvertiserIDFilter.vue";

export default {
  name: 'AddDeposit',
  components: {
    UsersAdvertiserIDFilter,
    MaterialInputField,
    MaterialButton,
  },
  data() {
    return {
      deposit: {},
      advertiser: null,
      deposit_types: ['USDT - (TRC - 20)', 'USDT - (ERC-20)', 'WIRE - (invoice)']
    }
  },
  mixins: [formMixin],
  computed: {
    ...mapGetters({
      user: "profile/getUserProfile"
    }),
  },
  watch: {
    user() {
      this.deposit.usdt_trc20_wallet = this.user.usdt_trc20_wallet;
      this.deposit.usdt_erc20_wallet = this.user.usdt_erc20_wallet;
    }
  },
  async mounted() {
    await this.$store.dispatch("profile/getProfile", true);
  },
  methods: {
    updateAdvertiser(advertiser) {
      this.advertiser = advertiser;
    },
    async handleAdd() {
      console.log(this.advertiser.filters[0].value);
      this.resetApiValidation();
      try {
        await this.$store.dispatch("deposits/addDeposit", {
          deposit_value: parseInt(this.deposit.deposit_value),
          advertiser_id: this.advertiser.filters[0].value,
        });

        showSwal.methods.showSwal({
          type: "success",
          message: "Deposit added successfully!",
          width: 500
        });
        this.$router.push({name: "Deposits List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.errors[0].detail,
          width: 500
        });
      }
    }
  }
}
</script>
<style scoped>
.add-request-btn {
  margin-top: 20px;
}
</style>
