<template>
  <div v-if="me && me?.roles[0].name === 'affiliate'" class="manager-wrapper">
    <div class="manager-info">
      <div class="manager-about">
        <p class="manager-title">{{ managerTitle }} </p>
        <p class="manager-name">{{ me?.affiliate_manager?.first_name }} {{ me?.affiliate_manager?.last_name }}</p>
        <a class="telegram" :href="telegramLink"><i class="fab fa-telegram fa-x"></i> {{me?.affiliate_manager?.telegram_account_name}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AffiliateManagerInfo",
  computed: {
    ...mapGetters({
      me: 'profile/getUserProfile',
    }),
    telegramLink() {
      return `https://t.me/${this.me?.affiliate_manager?.telegram_account_name}`;
    }
  },
  data() {
    return {
      managerTitle: 'Your Manager',
    };
  },
};
</script>

<style scoped>

a:hover {
  color: white;
  text-decoration: none;
}
a {
  color: white;
  text-decoration: none;
}

p {
  color: white;
}
.manager-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;  /* Adjusts the flex direction */
  align-items: center;     /* Centers the content horizontally */
  justify-content: center; /* Centers the content vertically */
  text-align: center;      /* Optional: centers text within the wrapper */
}

.manager-info {
  display: flex;
  flex-direction: column;
}

.manager-about {
  margin-bottom: 8px;
}

.telegram {
  font-weight: bold;
  margin-bottom: 2px;
}

.manager-name {
  font-weight: bold;
  margin-bottom: 2px;
}

.manager-title {
  font-size: 0.875rem;
  margin-bottom: 2px;
}

.manager-social {
  display: flex;
  gap: 8px;
}

.manager-social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.manager-feedback-link {
  display: flex;
  align-items: center;
}

.feedback-text {
  margin-left: 4px;
}
</style>
