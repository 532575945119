<template>
  <div v-if="isLoggedIn && balance" class="balance" :class="{ 'green': balance.amount > 0}">
    <a style="cursor: pointer;" @click="$router.push({ name: 'Add Withdrawal' })">
      <i class="fa fa-wallet" aria-hidden="true" style="
    margin-right: 3px;
"></i>{{ balance.label }}
    </a>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'BalanceComponent',
  computed: {
    ...mapGetters({
      "balance": 'withdrawals/getAvailableToWithdraw',
      "userProfile": 'profile/getUserProfile',
    }),
    role() {
      return this.userProfile?.roles[0].name;
    }
  },
  data() {
    return {
      isLoggedIn: false
    }
  },
  watch: {
    userProfile(newVal) {
      if (newVal && newVal?.roles[0].name === 'affiliate') {
        this.$store.dispatch("withdrawals/getAvailableToWithdraw");
        this.isLoggedIn = true;
      }
    }
  }
};
</script>

<style scoped>
.balance {
  text-align: center;
  color: black;
  font-weight: 700;
  text-decoration: none;
  margin-right: 3px;
}

.green {
  color: #4da24d;
}
</style>
