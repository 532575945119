<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Edit Campaign</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Campaigns List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <Form>
              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.leads_daily" label="Leads daily"
                                  variant="static"
                                  type="number"
                                  aria-valuemax="100"
                                  name="leads_daily"></material-input>
                  <validation-error :errors="apiValidationErrors.leads_daily"></validation-error>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.traffic_source" label="Traffic source"
                                  variant="static"
                                  aria-valuemax="100"
                                  name="traffic_source"></material-input>
                  <validation-error :errors="apiValidationErrors.traffic_source"></validation-error>
                </div>
              </div>

              <div class="button-row d-flex mt-4">
                <material-button
                    type="button" variant="gradient" class="ms-auto mb-0 js-btn-next"
                    @click="handleEdit">Save
                </material-button>
              </div>
<!--              <div class="row mt-4 mb-5 d-flex align-items-end">-->
<!--                <div class="col-2 text-end">-->
<!--                  <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Edit-->
<!--                    Campaign-->
<!--                  </material-button>-->
<!--                </div>-->
<!--              </div>-->
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../mixins/showSwal";
import _ from "lodash"

export default {
  name: 'EditCampaign',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      campaign: {}
    }
  },
  props: {
    campaignId: {
      type: String,
      default: ''
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("campaigns/getCampaign", this.campaignId)
      this.campaign = _.omit(this.$store.getters['campaigns/getCampaign'], 'links')
      console.log(this.campaign);
    } catch (error) {
      showSwal.methods.showSwal({
        type: "error",
        message: `Oops! Something went wrong!`,
        width: 500
      });
    }
  },
  methods: {
    async handleEdit() {
      this.resetApiValidation();
      try {
        await this.$store.dispatch("campaigns/editCampaign", {
          id: this.campaign.id,
          leads_daily: parseInt(this.campaign.leads_daily),
          traffic_source: this.campaign.traffic_source,
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Campaign modified successfully!",
          width: 500
        });
        this.$router.push({name: "Campaigns List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, something went wrong!",
          width: 500
        });

      }
    }
  }
}
</script>
