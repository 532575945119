<template>
  <div class="col-12">
    <h5 class="ms-3">{{ title }}</h5>
    <div class="table table-responsive">
      <table class="table mb-0 align-items-center">
        <thead>
        <tr>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
          >
            File
          </th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(member, index) of files"
            :key="index">
          <td>

            <a :id="member.id" class="cursor-pointer me-3" data-bs-delay="0" @click.prevent="handleDelete(member.id)" title="${text}">
              <i class="fas fa-solid fa-trash-alt"> </i>
            </a>
            <a
                :href="member.full_url"
                v-text="member.file_name"/>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import showSwal from "@/mixins/showSwal";

export default {
  name: "EditAttachments",
  props: {
    files: {
      type: Array,
    },
    title: {
      type: String,
    },
    offerId: {
      type: String,
      default: ''
    }
  },
  methods: {
    async handleDelete(id) {
      try {
        await this.$store.dispatch("files/deleteFile", id)
        showSwal.methods.showSwal({
          type: "success",
          message: "Attachment deleted!",
          width: 500
        });
        await this.$store.dispatch("offers/getOffer", this.offerId)
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, something went wrong!",
          width: 500
        });
      }
    }
  }
};
</script>
