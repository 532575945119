<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <material-input v-model:value="searchNiche" placeholder="Niche..."
                    class="mb-1 dataTable-input search-input-table"
                    id="niche"
                    variant="static"/>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  components: {MaterialInput},
  data() {
    return {
      searchNiche: '', // Data model for the niche search input
    };
  },
  watch: {
    // Watcher to react to changes in the searchNiche input
    searchNiche(value) {
      this.searchNiche = value;
      var filter = {
        decision: String,
        filters: []
      }
      if ('' === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offers_niche'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offers_niche', value: value})
      }

      // Emit an event or perform a search operation as the input changes
      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
