<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedStatus"
        placeholder="Campaign Status"
        :options="statusOptions"
        label="name"
        track-by="value"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      selectedStatus: null, // Initially selected geographic option
      statusOptions: [
        {name: 'Accepted', value: 'accepted'},
        {name: 'Not accepted', value: 'not_accepted'},
          ],
    };
  },

  watch: {
    selectedStatus: {
      handler: "updateSelectedStatus",
      deep: true
    },
  },

  methods: {
    // Method to update selectedStatus and emit its value to the parent
    updateSelectedStatus(value) {
      this.selectedStatus = value;
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'campaigns_status'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'campaigns_status', value: value.value})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
