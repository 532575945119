<template>
  <div v-if="campaign" class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Accept Campaign</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Campaigns List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>

              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.guaranteed_ar" label="Guaranteed AR (in %)"
                                  variant="static"
                                  type="number"
                                  aria-valuemax="100"
                                  name="guaranteed_ar"></material-input>
                  <validation-error :errors="apiValidationErrors.guaranteed_ar"></validation-error>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-2 text-end">
                  <material-button class="float-right btn btm-sm" @click.prevent="handleAccept"> Accept Campaign
                  </material-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../mixins/showSwal";
import {mapGetters} from "vuex";

export default {
  name: 'AcceptCampaign',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      campaign: 'campaigns/getCampaign',
      profile: 'profile/getUserProfile',
    })
  },
  mixins: [formMixin],
  mounted() {
    if (null === this.campaign) {
      this.$router.push({name: "Campaigns List"})
    }
  },
  methods: {
    async handleAccept() {
      this.resetApiValidation();
      try {
        this.campaign.accepted = true
        await this.$store.dispatch("campaigns/acceptCampaign", {
          id: this.campaign.id,
          guaranteed_ar: parseInt(this.campaign.guaranteed_ar),
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Campaign added successfully!",
          width: 500
        });
        this.$router.push({name: "Campaigns List"})
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.errors[0].detail,
          width: 500
        });

      }
    }
  }
}
</script>
