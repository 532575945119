<template>
  <div class="py-4 container-fluid">
    <AdvertisersDashboardFilters :update-filters="updateFilters"/>

    <div class="row">
      <advertisers-campaigns-list :updated-filters="updatedFilters" :web-id="webId"/>
    </div>
    <div
        v-if="dashboardStats">
      <div class="row">
        <!-- Adjusted Mini Statistics Cards for better responsiveness -->
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :title="{ text: 'Leads total', value: dashboardStats?.totalLeads }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :leads-status="'approved'"
              :title="{ text: 'Leads approved', value: dashboardStats?.approvedLeads }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :leads-status="'rejected'"
              :title="{ text: 'Leads Rejected/Trashed', value: dashboardStats?.rejectedLeads + '/' + dashboardStats?.trashedLeads }"
              detail=""
          />
        </div>

        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :leads-status="'hold'"
              :title="{ text: 'Leads on hold', value: dashboardStats?.onHoldLeads }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
              :is-clickable="false"
              :title="{ text: 'Profit', value: `${dashboardStats?.totalAzurelabCommission}` }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
              :is-clickable="false"
              :title="{ text: 'Advertiser deposits', value: `${dashboardStats?.totalAdvertiserPayout}` }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
              :is-clickable="false"
              :title="{ text: 'Earnings', value: dashboardStats?.totalPayout }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'percent',
                color: 'text-white',
                background: 'primary',
              }"
              :is-clickable="false"
              :title="{ text: 'AVG AR w/ trash', value: `${dashboardStats?.approvedRate} %` }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'percent',
                color: 'text-white',
                background: 'primary',
              }"
              :is-clickable="false"
              :title="{ text: 'AVG AR w/o trash', value: `${dashboardStats?.approvedRateWithoutTrash} %` }"
              detail=""
          />
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/views/template/dashboards/components/MiniStatisticsCard.vue";
import dinero from "dinero.js";
import store from "@/store";
import {mapGetters} from "vuex";
import AdvertisersDashboardFilters from "@/views/advertisers/AdvertisersDashboardFilters.vue";
import AdvertisersCampaignsList from "@/views/advertisers/AdvertisersCampaignsList.vue";

var filters = {}
export default {
  name: "AdvertisersDashboard",
  components: {
    AdvertisersCampaignsList,
    AdvertisersDashboardFilters,
    MiniStatisticsCard
  },
  props: {
    webId: {
      type: String
    },
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      dashboardStats: 'leads/getDashboardStats',
      productOptions: 'products/getProductNamesData',
      userOptions: 'users/getUsersIds'
    })
  },
  created() {
    store.dispatch("products/getProductNames");
    store.dispatch("users/getUsersIds");
    this.updateFilters({decision: 'add', filters: [{name: "ext_id", value: this.webId}]});
  },
  methods: {
    async updateFilters(updatedFilter) {
      if (undefined == updatedFilter) {
        return;
      }
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }

      this.updatedFilters = {...filters};
    },
  },
  data() {
    return {
      updatedFilters: {},
      paidOutValue: dinero({amount: 0, currency: 'USD'}),
      searchGeo: '',
      searchUser: '',
      searchProduct: '',
      searchOfferType: '',
      offerTypeOptions: [
        {name: 'Full price', value: 'full_price'},
        {name: 'Sample', value: 'sample'},
      ],
      geoOptions: [
        'PL', 'RO', 'CZ', 'BG', 'DE'
      ],
      campaignCreatedAt: {},
      leadsCreatedAt: {},
    };
  },
};
</script>
