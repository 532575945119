import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getLeads(params) {
        const response = await axios.get(
            "/leads/-actions/leads-list", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },

    //Dates with leads on dashboard
    async getDashboardStats(params, abortController) {
        try {
            const response = await axios.get("/leads/-actions/dashboard-stats", {
                headers: authHeader(),
                params: params,
                signal: abortController.signal
            });

            return {data: response.data};
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Request was aborted');
            } else {
                console.error('An error occurred', e);
            }
        }
    },

    async getDashboardStatsForAdvertisers(params, abortController) {
        try {
            const response = await axios.get("/leads/-actions/dashboard-stats-for-advertisers", {
                headers: authHeader(),
                params: params,
                signal: abortController.signal
            });

            return {data: response.data};
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Request was aborted');
            } else {
                console.error('An error occurred', e);
            }
        }
    },

    async addLead(lead) {
        lead.type = "leads"
        const newJson = dataFormatter.serialize({stuff: lead})
        const response = await axios.post("/leads", newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async exportToCsv(params) {
        axios.get("/leads/-actions/export-to-csv", {responseType: 'blob', headers: authHeader(), params: params})
            .then(response => {
                const blob = new Blob([response.data], {type: response.data.type})
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = name
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
    },

    async deleteLead(leadId) {
        await axios.delete("/leads/" + leadId, {headers: authHeader()});
    },

    async getLead(leadId) {
        const response = await axios.get("/leads/" + leadId, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async editLead(lead) {
        lead.type = "leads"
        const newJson = dataFormatter.serialize({stuff: lead})
        const response = await axios.patch("/leads/" + lead.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },
}




