import eventTable from "@/mixins/eventTable";

const {actionDeleteButton, changeCustomPayout} = eventTable.methods;

export function getCustomPayoutProperties(customPayout) {
    return [
        customPayout.offer_id + ' ' + customPayout.product_name+ ' ' + customPayout.offer_type + ' ' + customPayout.offer_geo,
        customPayout.partner,
        customPayout.web_id + ' ' + customPayout.web_name,
        changeCustomPayout(customPayout.id, customPayout.payout.value),
        actionDeleteButton(customPayout.id, 'Delete custom payout')
    ]
}
