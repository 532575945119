/* eslint-disable no-unused-vars */
import leadsService from '../services/leads.service';

const initialState = {leads: null, lead: null, dashboardStats: null, filters: null, abortController: null};

export const leads = {
    namespaced: true,
    state: initialState,
    actions: {
        async getLeads({commit}, options) {
            const leads = await leadsService.getLeads(options);
            commit('getLeadsSuccess', leads);
        },

        async getDashboardStats({commit}, options) {
            if (this.abortController) {
                this.abortController.abort()
            }

            // Create new abort controller
            this.abortController = new AbortController()

            const dashboardStats = await leadsService.getDashboardStats(options, this.abortController);
            commit('getDashboardStatsSuccess', dashboardStats);
        },

        async getDashboardStatsForAdvertisers({commit}, options) {
            if (this.abortController) {
                this.abortController.abort()
            }

            // Create new abort controller
            this.abortController = new AbortController()

            const dashboardStats = await leadsService.getDashboardStatsForAdvertisers(options, this.abortController);
            commit('getDashboardStatsSuccess', dashboardStats);
        },


        async addLead({commit}, newLead) {
            await leadsService.addLead(newLead);
        },

        async deleteLead({commit}, leadId) {
            await leadsService.deleteLead(leadId);
        },

        async getLead({commit}, leadId) {
            const lead = await leadsService.getLead(leadId);
            commit('getLeadSuccess', lead)
        },

        async editLead({commit}, modifiedLead) {
            await leadsService.editLead(modifiedLead);
        },

        async exportToCsv({commit}, options) {
            await leadsService.exportToCsv(options);
        },
        cancelPendingRequests() {
            if (this.abortController) {
                this.abortController.abort()
                this.abortController = null
            }
        }
    },
    mutations: {
        setFilters(state, filters) {
            state.filters = filters;
        },
        getLeadsSuccess(state, leads) {
            state.leads = leads;
        },
        getDashboardStatsSuccess(state, dashboardStats) {
            state.dashboardStats = dashboardStats;
        },
        getLeadSuccess(state, lead) {
            state.lead = lead;
        },
    },
    getters: {
        getFilters(state) {
            return state.filters;
        },
        getDashboardStats(state) {
            return state.dashboardStats?.data;
        },
        getLeadsData(state) {
            return state.leads?.data;
        },
        getLeadsMeta(state) {
            return state.leads?.meta;
        },
        getLead(state) {
            return state.lead;
        }
    }
}

