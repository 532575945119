import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getDeposits(params) {
        const response = await axios.get("/deposits/-actions/list-deposits", {headers: authHeader(), params: params});
        return {data: response.data.data, meta: response.data};
    },

    async addDeposit(deposit) {
        deposit.type = "deposits"
        const newJson = dataFormatter.serialize({stuff: deposit})
        const response = await axios.post("/deposits/-actions/create-deposit", newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async deleteDeposit(depositId) {
        await axios.delete("/deposits/" + depositId, {headers: authHeader()});
    },

    async getDeposit(depositId) {
        const response = await axios.get("/deposits/" + depositId, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async editDeposit(deposit) {
        deposit.type = "deposits"
        const newJson = dataFormatter.serialize({stuff: deposit})
        const response = await axios.patch("/deposits/" + deposit.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },
}




