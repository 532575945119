import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getCategories(params) {
        const response = await axios.get("/categories", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addCategory(category) {
        category.type = "categories"
        const newJson = dataFormatter.serialize({ stuff: category })
        const response = await axios.post("/categories", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteCategory(categoryId) {
        await axios.delete("/categories/" + categoryId, { headers: authHeader() });
    },

    async getCategory(categoryId) {
        const response = await axios.get("/categories/" + categoryId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editCategory(category) {
        category.type = "categories"
        const newJson = dataFormatter.serialize({ stuff: category })
        const response = await axios.patch("/categories/" + category.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




