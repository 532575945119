<template>
  <div class="py-4 container-fluid">
    <WebCampaignsDashboardFilters :update-filters="updateFilters"/>

    <div class="row">
      <webs-campaigns-list :updated-filters="updatedFilters" :web-id="webId"/>
    </div>
    <div
        v-if="dashboardStats">
      <div class="row">
        <!-- Adjusted Mini Statistics Cards for better responsiveness -->
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :title="{ text: 'Leads total', value: dashboardStats?.totalLeads }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :leads-status="'approved'"
              :title="{ text: 'Leads approved', value: dashboardStats?.approvedLeads }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :leads-status="'rejected'"
              :title="{ text: 'Leads Rejected/Trashed', value: dashboardStats?.rejectedLeads + '/' + dashboardStats?.trashedLeads }"
              detail=""
          />
        </div>
        <!--        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">-->
        <!--          <mini-statistics-card-->
        <!--              :title="{ text: 'Leads trashed', value: dashboardStats?.trashedLeads }"-->
        <!--              detail=""-->
        <!--              :filters="updatedFilters"-->
        <!--              :leads-status="'trashed'"-->
        <!--              :icon="{-->
        <!--                name: 'leaderboard',-->
        <!--                color: 'text-white',-->
        <!--                background: 'success',-->
        <!--              }"-->
        <!--          />-->
        <!--        </div>-->
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
              :leads-status="'hold'"
              :title="{ text: 'Leads on hold', value: dashboardStats?.onHoldLeads }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
              :is-clickable="false"
              :title="{ text: 'Earnings', value: dashboardStats?.totalPayout }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'percent',
                color: 'text-white',
                background: 'primary',
              }"
              :is-clickable="false"
              :title="{ text: 'AVG AR w/ trash', value: `${dashboardStats?.approvedRate} %` }"
              detail=""
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :icon="{
                name: 'percent',
                color: 'text-white',
                background: 'primary',
              }"
              :is-clickable="false"
              :title="{ text: 'AVG AR w/o trash', value: `${dashboardStats?.approvedRateWithoutTrash} %` }"
              detail=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <top-offers-list/>
        </div>
        <div class="col-6">
          <new-offers-list/>
        </div>
      </div>
      <div class="mt-sm-4">
        <leads-chart-card
            v-if="dashboardStats"
            v-model:dates-range="campaignCreatedAt"
            v-model:leads-stats="dashboardStats.datesWithLeads"
        />
      </div>
    </div>
  </div>
</template>
<script>
import US from "../../../assets/img/icons/flags/US.png";
import DE from "../../../assets/img/icons/flags/DE.png";
import GB from "../../../assets/img/icons/flags/GB.png";
import BR from "../../../assets/img/icons/flags/BR.png";
import booking1 from "../../../assets/img/products/product-1-min.jpg";
import booking2 from "../../../assets/img/products/product-2-min.jpg";
import booking3 from "../../../assets/img/products/product-3-min.jpg";
import MiniStatisticsCard from "@/views/template/dashboards/components/MiniStatisticsCard.vue";
import LeadsChartCard from "@/views/dashboards/components/LeadsChartCard.vue";
import dinero from "dinero.js";
import store from "@/store";
import {mapGetters} from "vuex";
import WebCampaignsDashboardFilters from "@/views/dashboards/CampaignsDashboard/WebCampaignsDashboardFilters.vue";
import WebsCampaignsList from "@/views/dashboards/CampaignsDashboard/WebsCampaignsList.vue";
import TopOffersList from "@/views/dashboards/TopOffers.vue";
import NewOffersList from "@/views/dashboards/NewOffers.vue";

var filters = {}
export default {
  name: "WebCampaignsDashboard",
  components: {
    NewOffersList, TopOffersList,
    WebsCampaignsList,
    WebCampaignsDashboardFilters,
    LeadsChartCard,
    MiniStatisticsCard
  },
  props: {
    webId: {
      type: String
    },
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      dashboardStats: 'leads/getDashboardStats',
      productOptions: 'products/getProductNamesData',
      userOptions: 'users/getUsersIds'
    })
  },
  created() {
    store.dispatch("products/getProductNames");
    store.dispatch("users/getUsersIds");
    this.updateFilters({decision: 'add', filters: [{name: "ext_id", value: this.webId}]});
  },
  methods: {
    async updateFilters(updatedFilter) {
      if (undefined == updatedFilter) {
        return;
      }
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }

      this.updatedFilters = {...filters};
    },
  },
  data() {
    return {
      updatedFilters: {},
      paidOutValue: dinero({amount: 0, currency: 'USD'}),
      booking1,
      booking2,
      booking3,
      US,
      DE,
      GB,
      BR,
      searchGeo: '',
      searchUser: '',
      searchProduct: '',
      searchOfferType: '',
      offerTypeOptions: [
        {name: 'Full price', value: 'full_price'},
        {name: 'Sample', value: 'sample'},
      ],
      geoOptions: [
        'PL', 'RO', 'CZ', 'BG', 'DE'
      ],
      campaignCreatedAt: {},
      leadsCreatedAt: {},
    };
  },
};
</script>
