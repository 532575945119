import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getProducts(params) {
        const response = await axios.get(
            "/products" +
            "?include=promoter,leads,offer&", {
                headers: authHeader(),
                params: params
            });
        return {data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page};
    },

    async getProductNames() {
        const response = await axios.get("/products/-actions/product-names", {headers: authHeader()});
        return {data: response.data};
    },

    async getProductNamesWithIds() {
        const response = await axios.get("/products/-actions/product-names-with-ids", {headers: authHeader()});
        return {data: response.data};
    },

    async addProduct(product) {
        product.type = "products"
        const newJson = dataFormatter.serialize({stuff: product, includeNames: ['offer']})
        const response = await axios.post("/products", newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async deleteProduct(productId) {
        await axios.delete("/products/" + productId, {headers: authHeader()});
    },

    async getProduct(productId) {
        const response = await axios.get("/products/" + productId + "?include=promoter,leads,offer,offer.product,offer.product.files&", {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async editProduct(product) {
        product.type = "products"
        const newJson = dataFormatter.serialize({stuff: product})
        const response = await axios.patch("/products/" + product.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },
}




