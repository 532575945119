<template>
  <tr>
    <th title="ext_id">Web</th>
    <th title="partner">Partner</th>
    <th title="created_at">Created at</th>
    <th title="withdrawal_type" >Type</th>
    <th title="withdrawal_status">Status</th>
    <th title="withdrawal_value">Value</th>
    <th title="bank_info">Bank info</th>
    <th title="company_info">Company info</th>
    <th title="usdt wallet">USDT wallet</th>
    <th title="download_invoice" class="w-15">Invoice</th>
  </tr>
</template>
<script>
export default {
  name: 'WithdrawalsColumns'
}
</script>
