<template>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form mb-9">
            <div class="row">
              <div class="col-12 col-lg-8 m-auto">
                <div class="card">
                  <div
                    class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                  >
                    <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                    >
                      <div class="multisteps-form__progress">
                        <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="User Info"
                          :class="activeClass"
                        >
                          <span>Edit User</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <Info :user-id="userId" :class="activeClass" @update:handleSave="handleSave"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Info from "@/views/users/Info.vue";
  import showSwal from "@/mixins/showSwal";
  import formMixin from "@/mixins/formMixin";
  import _ from "lodash";
  export default {
    name: "EditUser",
    components: {
      Info,
    },
    props: {
      userId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        activeClass: "js-active position-relative",
        user: null,
        apiValidationErrors: {}
      };
    },
    mixins: [formMixin],
    methods: {
      async handleSave(value) {
        this.resetApiValidation();
        try {
          //update user with pic
          await this.$store.dispatch('users/editUser', _.omit(value, 'latestToken'))
          showSwal.methods.showSwal({
            type: "success",
            message: "Success!",
            width: 500
          });
          this.$router.push({name: 'Users List'})
        } catch (error) {
          this.setApiValidation(error.response.data.errors);
          error.response.data.errors.forEach((error) => {
            showSwal.methods.showSwal({
              type: "error",
              message: error.detail,
              width: 500
            });
          });
        }
        // this.$router.push({name: 'users'});
      }
    }
  };
  </script>
