export default {
    en: {
        message: {
            sample: 'Sample',
            full_price: 'Full price'
        }
    },
    ja: {
        message: {
            hello: 'こんにちは、世界'
        }
    }
}
