<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedUserStatus"
        placeholder="Filter by status"
        label="name"
        :options="statusOptions"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      selectedUserStatus: null,
      statusOptions: [
        {name: 'Waiting for verification', value: 'waiting_for_verification'},
        {name: 'Active', value: 'active'},
        {name: 'Banned', value: 'banned'},
      ],
    };
  },

  watch: {
    selectedUserStatus(newValue) {
      this.updateSelectedUserStatus(newValue);
    },
  },

  methods: {
    // Method to update selectedGeo and emit its value to the parent
    updateSelectedUserStatus(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'user_status'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'user_status', value: value.value})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
