<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <material-input v-model:value="selectedIdentifier" placeholder="Lead sub4"
                    class="mb-1 dataTable-input search-input-table"
                    id="niche"
                    variant="static"/>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  components: {
    MaterialInput,
  },
  data() {
    return {
      selectedIdentifier: null,
    }
  },
  watch: {
    selectedIdentifier(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if ('' === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'leads_sub4'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'leads_sub4', value: value})
      }

      this.$emit('update:updateFilters', filter);
    },
  },
};
</script>
