<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedPrivacyLevel"
        placeholder="Offer privacy level"
        label="name"
        track-by="value"
        select-label='+'
        deselect-label="-"
        :options="privacyLevelOptions"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    VueMultiselect,
  },

  watch: {
    selectedPrivacyLevel: {
      handler: "updateSelectedPrivacyLevel",
      deep: true
    },
  },

  computed: {
    ...mapGetters({
      savedOfferPrivacyLevel: 'filters/getOfferPrivacyLevel',
    }),
  },

  async mounted() {
    const savedFilter = await this.savedOfferPrivacyLevel;
    if (null !== savedFilter) {
      this.selectedPrivacyLevel = savedFilter;
    }
  },

  data() {
    return {
      selectedPrivacyLevel: null, // Initially selected offer type
      privacyLevelOptions: [
        {name: 'Public', value: 'public'},
        {name: 'Private', value: 'private'},
        {name: 'Semiprivate', value: 'semiprivate'},
      ],
    };
  },

  methods: {
    // Method to update selectedPrivacyLevel and emit its value to the parent
    updateSelectedPrivacyLevel(value) {
      this.selectedPrivacyLevel = value;
      this.$store.commit('filters/saveOfferPrivacyLevel', value);
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offer_privacy_level'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offer_privacy_level', value: value.value})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
