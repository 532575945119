export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    let impersonating = JSON.parse(localStorage.getItem('impersonating'));
    if (user) {
        if (impersonating) {
            return {
                Authorization: 'Bearer ' + impersonating,
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json'
            };
        }
        return {
            Authorization: 'Bearer ' + user,
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
        };
    } else {
        //redirect to login page
        // window.location.href = '/login';
    }
}
