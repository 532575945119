<template>
  <div></div>
  <!-- Navbar -->
<!--  <nav-->
<!--    class="top-0 navbar navbar-expand-lg position-absolute z-index-3"-->
<!--    :class="isBlur ? isBlur : 'shadow-none my-3 navbar-transparent w-100 mt-4'"-->
<!--  >-->
<!--    <div class="container" :class="isBlur && 'px-1'">-->
<!--&lt;!&ndash;      <router-link&ndash;&gt;-->
<!--&lt;!&ndash;        class="navbar-brand font-weight-bolder ms-lg-0 ms-3"&ndash;&gt;-->
<!--&lt;!&ndash;        :class="darkMode ? 'text-black' : 'text-white'"&ndash;&gt;-->
<!--&lt;!&ndash;        to="/"&ndash;&gt;-->
<!--&lt;!&ndash;        >Azurelab Network</router-link&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->
<!--      <button-->
<!--        class="shadow-none navbar-toggler ms-2"-->
<!--        type="button"-->
<!--        data-bs-toggle="collapse"-->
<!--        data-bs-target="#navigation"-->
<!--        aria-controls="navigation"-->
<!--        aria-expanded="false"-->
<!--        aria-label="Toggle navigation"-->
<!--      >-->
<!--        <span class="mt-2 navbar-toggler-icon">-->
<!--          <span class="navbar-toggler-bar bar1"></span>-->
<!--          <span class="navbar-toggler-bar bar2"></span>-->
<!--          <span class="navbar-toggler-bar bar3"></span>-->
<!--        </span>-->
<!--      </button>-->
<!--      <div-->
<!--        id="navigation"-->
<!--        class="pt-3 pb-2 collapse navbar-collapse w-100 py-lg-0"-->
<!--      >-->
<!--&lt;!&ndash;        <ul class="mx-auto navbar-nav navbar-nav-hover">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <li class="mx-2 nav-item dropdown dropdown-hover">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <a&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              id="dropdownMenuPages"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              role="button"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              :class="[darkModes]"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              data-bs-toggle="dropdown"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              aria-expanded="false"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              Pages&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              <img&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                :src="downArrWhite"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                alt="down-arrow"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                class="arrow ms-1"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              <img&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                :src="downArrBlack"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                alt="down-arrow"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                class="arrow ms-1 d-block"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            </a>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-xl border-radius-xl mt-lg-3"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              aria-labelledby="dropdownMenuPages"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              <div class="row d-none d-lg-block">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div class="px-4 py-2 col-12">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <div class="row">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <div class="col-4 position-relative">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          >dashboard</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        Dashboards&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Default' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Analytics</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Sales' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Sales</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          >people</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        Users&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'New User' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">New User</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <hr class="vertical dark" />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <div class="col-4 position-relative">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          >badge</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        Profile&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'All Projects' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Projects</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          >precision_manufacturing</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        Projects&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Timeline' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Timeline</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <hr class="vertical dark" />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <div class="col-4">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          >account_balance</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        Account&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Settings' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Settings</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Billing' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Billing</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Invoice' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Invoice</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          >queue_play_next</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        Extra&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Pricing Page' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Pricing Page</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'RTL' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">RTL Page</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        :to="{ name: 'Widgets' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <span class="ps-3">Widgets</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      </router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              &lt;!&ndash; responsive &ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              <div class="d-lg-none">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <i class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    >dashboard</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Dashboards&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Default' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Default</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Sales' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Sales</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <i class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    >people</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Users&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link to="#" class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >New user</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <i class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    >badge</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Profile&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link to="#" class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Projects</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <i class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    >precision_manufacturing</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Projects&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Timeline' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Timeline</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <i class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    >account_balance</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Account&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Settings' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Settings</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Billing' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Billing</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Invoice' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Invoice</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <i class="material-icons text-dark opacity-6 me-2 text-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    >queue_play_next</i&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Extra&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Pricing Page' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Pricing Page</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'RTL' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >RTL Page</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  :to="{ name: 'Widgets' }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  >Widgets</router-link&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          </li>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          <li class="mx-2 nav-item dropdown dropdown-hover">&ndash;&gt;-->
<!--&lt;!&ndash;            <a&ndash;&gt;-->
<!--&lt;!&ndash;              id="dropdownMenuAccount"&ndash;&gt;-->
<!--&lt;!&ndash;              role="button"&ndash;&gt;-->
<!--&lt;!&ndash;              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;              :class="[darkModes]"&ndash;&gt;-->
<!--&lt;!&ndash;              data-bs-toggle="dropdown"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-expanded="false"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              Authentication&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrWhite"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrBlack"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1 d-block"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="dropdown-menu dropdown-menu-animation dropdown-md border-radius-xl p-3 mt-0 mt-lg-3"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-labelledby="dropdownMenuAccount"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="d-none d-lg-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                <div&ndash;&gt;-->
<!--&lt;!&ndash;                  class="col-12 ps-0 d-flex justify-content-center flex-column"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <ul class="list-group">&ndash;&gt;-->
<!--&lt;!&ndash;                    <li&ndash;&gt;-->
<!--&lt;!&ndash;                      class="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <a&ndash;&gt;-->
<!--&lt;!&ndash;                        id="dropdownSignUp"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="mb-1 dropdown-item border-radius-md ps-3 d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >login</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->

<!--&lt;!&ndash;                        <span>Sign In</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <img&ndash;&gt;-->
<!--&lt;!&ndash;                          src="@/assets/img/down-arrow.svg"&ndash;&gt;-->
<!--&lt;!&ndash;                          alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="arrow ms-auto"&ndash;&gt;-->
<!--&lt;!&ndash;                        />&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div&ndash;&gt;-->
<!--&lt;!&ndash;                        class="px-2 py-3 mt-0 dropdown-menu"&ndash;&gt;-->
<!--&lt;!&ndash;                        aria-labelledby="dropdownSignIn"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                          class="mb-1 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          :to="{ name: 'Signin Basic' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          <span>Basic</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                        <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                          class="mb-1 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          :to="{ name: 'Signin Cover' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          <span>Cover</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                        <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                          class="mb-1 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          :to="{ name: 'Signin Illustration' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          <span>Illustration</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </li>&ndash;&gt;-->
<!--&lt;!&ndash;                    <li&ndash;&gt;-->
<!--&lt;!&ndash;                      class="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <a&ndash;&gt;-->
<!--&lt;!&ndash;                        id="dropdownSignUp"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="mb-1 dropdown-item border-radius-md ps-3 d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >assignment</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->

<!--&lt;!&ndash;                        <span>Sign Up</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <img&ndash;&gt;-->
<!--&lt;!&ndash;                          src="@/assets/img/down-arrow.svg"&ndash;&gt;-->
<!--&lt;!&ndash;                          alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="arrow ms-auto"&ndash;&gt;-->
<!--&lt;!&ndash;                        />&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div&ndash;&gt;-->
<!--&lt;!&ndash;                        class="px-2 py-3 mt-0 dropdown-menu"&ndash;&gt;-->
<!--&lt;!&ndash;                        aria-labelledby="dropdownSignUp"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                          class="mb-1 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          :to="{ name: 'Signup Cover' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          <span>Cover</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </li>&ndash;&gt;-->
<!--&lt;!&ndash;                    <li&ndash;&gt;-->
<!--&lt;!&ndash;                      class="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <a&ndash;&gt;-->
<!--&lt;!&ndash;                        id="dropdownSignUp"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="mb-1 dropdown-item border-radius-md ps-3 d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >restart_alt</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->

<!--&lt;!&ndash;                        <span>Reset Password</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <img&ndash;&gt;-->
<!--&lt;!&ndash;                          src="@/assets/img/down-arrow.svg"&ndash;&gt;-->
<!--&lt;!&ndash;                          alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="arrow ms-auto"&ndash;&gt;-->
<!--&lt;!&ndash;                        />&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div&ndash;&gt;-->
<!--&lt;!&ndash;                        class="px-2 py-3 mt-0 dropdown-menu"&ndash;&gt;-->
<!--&lt;!&ndash;                        aria-labelledby="dropdownPasswordReset"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                          class="mb-1 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          :to="{ name: 'Reset Cover' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          <span>Cover</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  </ul>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="row d-lg-none">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="col-12 d-flex justify-content-center flex-column">&ndash;&gt;-->
<!--&lt;!&ndash;                  <h6&ndash;&gt;-->
<!--&lt;!&ndash;                    class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="material-icons opacity-6 me-2 text-md">login</i>&ndash;&gt;-->
<!--&lt;!&ndash;                    Sign In&ndash;&gt;-->
<!--&lt;!&ndash;                  </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Signin Basic' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    >Basic</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Signin Cover' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    >Cover</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Signin Illustration' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    >Illustration</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                  <h6&ndash;&gt;-->
<!--&lt;!&ndash;                    class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      >assignment</i&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                    Sign Up&ndash;&gt;-->
<!--&lt;!&ndash;                  </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Signup Cover' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    >Cover</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                  <h6&ndash;&gt;-->
<!--&lt;!&ndash;                    class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      >restart_alt</i&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                    Reset Password&ndash;&gt;-->
<!--&lt;!&ndash;                  </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Reset Cover' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    >Cover</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;          <li class="mx-2 nav-item dropdown dropdown-hover">&ndash;&gt;-->
<!--&lt;!&ndash;            <a&ndash;&gt;-->
<!--&lt;!&ndash;              id="dropdownMenuBlocks"&ndash;&gt;-->
<!--&lt;!&ndash;              role="button"&ndash;&gt;-->
<!--&lt;!&ndash;              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;              :class="[darkModes]"&ndash;&gt;-->
<!--&lt;!&ndash;              data-bs-toggle="dropdown"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-expanded="false"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              Applications&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrWhite"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrBlack"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1 d-block"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive border-radius-lg mt-lg-3"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-labelledby="dropdownMenuBlocks"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="d-none d-lg-block">&ndash;&gt;-->
<!--&lt;!&ndash;                <ul class="list-group">&ndash;&gt;-->
<!--&lt;!&ndash;                  <li&ndash;&gt;-->
<!--&lt;!&ndash;                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      :to="{ name: 'Kanban' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >widgets</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        Kanban&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  <li&ndash;&gt;-->
<!--&lt;!&ndash;                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      :to="{ name: 'Wizard' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >import_contacts</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        Wizard&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  <li&ndash;&gt;-->
<!--&lt;!&ndash;                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      :to="{ name: 'Data Tables' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >backup_table</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        DataTables&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  <li&ndash;&gt;-->
<!--&lt;!&ndash;                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      :to="{ name: 'Calendar' }"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >event</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        Calendar&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                </ul>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash; responsive &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="row d-lg-none">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="col-md-12">&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Kanban' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                        >widgets</i&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                      Kanban&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Wizard' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                        >import_contacts</i&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                      Wizard&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Data Tables' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                        >backup_table</i&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                      DataTables&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    :to="{ name: 'Calendar' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center text-dark">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md">event</i>&ndash;&gt;-->
<!--&lt;!&ndash;                      Calendar&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;          <li class="mx-2 nav-item dropdown dropdown-hover">&ndash;&gt;-->
<!--&lt;!&ndash;            <a&ndash;&gt;-->
<!--&lt;!&ndash;              id="dropdownMenuEcommerce"&ndash;&gt;-->
<!--&lt;!&ndash;              role="button"&ndash;&gt;-->
<!--&lt;!&ndash;              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;              :class="[darkModes]"&ndash;&gt;-->
<!--&lt;!&ndash;              data-bs-toggle="dropdown"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-expanded="false"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              Ecommerce&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrWhite"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrBlack"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1 d-block"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-lg-block' : ' d-lg-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-lg border-radius-xl mt-lg-3"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-labelledby="dropdownMenuEcommerce"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="row d-none d-lg-block">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="px-4 py-2 col-12">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-6 position-relative">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center px-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >shopping_cart</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        Orders&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                        :to="{ name: 'Order List' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="ps-3">Order List</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                        :to="{ name: 'Order Details' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="ps-3">Order Details</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      <hr class="vertical dark" />&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-6 position-relative">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center px-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >memory</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        Products&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                        :to="{ name: 'New Product' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="ps-3">New Product</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                        :to="{ name: 'Edit Product' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="ps-3">Edit Product</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                        :to="{ name: 'Product Page' }"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="ps-3">Product Page</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash; responsive &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="d-lg-none">&ndash;&gt;-->
<!--&lt;!&ndash;                <div&ndash;&gt;-->
<!--&lt;!&ndash;                  class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    >shopping_cart</i&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                  Orders&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  :to="{ name: 'Order List' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                  >Order List</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  :to="{ name: 'Order Details' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                  >Order Details</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                <div&ndash;&gt;-->
<!--&lt;!&ndash;                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="material-icons opacity-6 me-2 text-md">store</i>&ndash;&gt;-->
<!--&lt;!&ndash;                  General&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div&ndash;&gt;-->
<!--&lt;!&ndash;                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="material-icons opacity-6 me-2 text-md">memory</i>&ndash;&gt;-->
<!--&lt;!&ndash;                  Products&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  :to="{ name: 'New Product' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                  >New Product</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  :to="{ name: 'Edit Product' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                  >Edit Product</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                  :to="{ name: 'Product Page' }"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="dropdown-item border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                  >Product Page</router-link&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;          <li class="mx-2 nav-item dropdown dropdown-hover">&ndash;&gt;-->
<!--&lt;!&ndash;            <a&ndash;&gt;-->
<!--&lt;!&ndash;              id="dropdownMenuDocs"&ndash;&gt;-->
<!--&lt;!&ndash;              role="button"&ndash;&gt;-->
<!--&lt;!&ndash;              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"&ndash;&gt;-->
<!--&lt;!&ndash;              :class="[darkModes]"&ndash;&gt;-->
<!--&lt;!&ndash;              data-bs-toggle="dropdown"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-expanded="false"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              Docs&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrWhite"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;              <img&ndash;&gt;-->
<!--&lt;!&ndash;                :src="downArrBlack"&ndash;&gt;-->
<!--&lt;!&ndash;                alt="down-arrow"&ndash;&gt;-->
<!--&lt;!&ndash;                class="arrow ms-1 d-block"&ndash;&gt;-->
<!--&lt;!&ndash;                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-lg mt-lg-3 border-radius-lg"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-labelledby="dropdownMenuDocs"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="d-none d-lg-block">&ndash;&gt;-->
<!--&lt;!&ndash;                <ul class="list-group">&ndash;&gt;-->
<!--&lt;!&ndash;                  <li class="p-0 border-0 nav-item list-group-item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      href="https://www.creative-tim.com/learning-lab/vue/overview/material-dashboard/"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >article</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        <h6&ndash;&gt;-->
<!--&lt;!&ndash;                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          Getting Started&ndash;&gt;-->
<!--&lt;!&ndash;                        </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="text-sm opacity-8 ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                        >All about overview, quick start, license and&ndash;&gt;-->
<!--&lt;!&ndash;                        contents</span&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  <li class="p-0 border-0 nav-item list-group-item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      href="https://www.creative-tim.com/learning-lab/vue/colors/material-dashboard/"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >grading</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        <h6&ndash;&gt;-->
<!--&lt;!&ndash;                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          Foundation&ndash;&gt;-->
<!--&lt;!&ndash;                        </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="text-sm opacity-8 ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                        >See our colors, icons and typography</span&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  <li class="p-0 border-0 nav-item list-group-item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      href="https://www.creative-tim.com/learning-lab/vue/alerts/material-dashboard/"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >apps</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        <h6&ndash;&gt;-->
<!--&lt;!&ndash;                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          Components&ndash;&gt;-->
<!--&lt;!&ndash;                        </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="text-sm opacity-8 ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                        >Explore our collection of fully designed&ndash;&gt;-->
<!--&lt;!&ndash;                        components</span&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  <li class="p-0 border-0 nav-item list-group-item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      href="https://www.creative-tim.com/learning-lab/vue/datepicker/material-dashboard/"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >extension</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        <h6&ndash;&gt;-->
<!--&lt;!&ndash;                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          Plugins&ndash;&gt;-->
<!--&lt;!&ndash;                        </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="text-sm opacity-8 ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                        >Check how you can integrate our plugins</span&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  <li class="p-0 border-0 nav-item list-group-item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                      class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                      href="https://www.creative-tim.com/learning-lab/vue/utilities/material-dashboard/"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                          >swipe</i&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        <h6&ndash;&gt;-->
<!--&lt;!&ndash;                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          Utility Classes&ndash;&gt;-->
<!--&lt;!&ndash;                        </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="text-sm opacity-8 ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                        >For those who want flexibility, use our utility&ndash;&gt;-->
<!--&lt;!&ndash;                        classes</span&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--&lt;!&ndash;                </ul>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="row d-lg-none">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="col-md-12 g-0">&ndash;&gt;-->
<!--&lt;!&ndash;                  <a&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    href="../../pages/about-us.html"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                        >article</i&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                      <h6&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        Getting Started&ndash;&gt;-->
<!--&lt;!&ndash;                      </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="text-sm ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                      >All about overview, quick start, license and&ndash;&gt;-->
<!--&lt;!&ndash;                      contents</span&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                  </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    href="../../pages/about-us.html"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                        >grading</i&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                      <h6&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        Foundation&ndash;&gt;-->
<!--&lt;!&ndash;                      </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="text-sm ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                      >See our colors, icons and typography</span&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                  </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    href="../../pages/about-us.html"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md">apps</i>&ndash;&gt;-->
<!--&lt;!&ndash;                      <h6&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        Components&ndash;&gt;-->
<!--&lt;!&ndash;                      </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="text-sm ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                      >Explore our collection of fully designed components</span&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                  </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    href="../../pages/about-us.html"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md"&ndash;&gt;-->
<!--&lt;!&ndash;                        >extension</i&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                      <h6&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        Plugins&ndash;&gt;-->
<!--&lt;!&ndash;                      </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="text-sm ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                        >Check how you can integrate our plugins</span&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a&ndash;&gt;-->
<!--&lt;!&ndash;                    class="py-2 dropdown-item ps-3 border-radius-md"&ndash;&gt;-->
<!--&lt;!&ndash;                    href="../../pages/about-us.html"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="d-flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-icons opacity-6 me-2 text-md">swipe</i>&ndash;&gt;-->
<!--&lt;!&ndash;                      <h6&ndash;&gt;-->
<!--&lt;!&ndash;                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        Utility Classes&ndash;&gt;-->
<!--&lt;!&ndash;                      </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="text-sm ps-4"&ndash;&gt;-->
<!--&lt;!&ndash;                        >All about overview, quick start, license and&ndash;&gt;-->
<!--&lt;!&ndash;                        contents</span&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </a>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;        </ul>&ndash;&gt;-->
<!--&lt;!&ndash;        <ul class="navbar-nav d-lg-block d-none">&ndash;&gt;-->
<!--&lt;!&ndash;          <li class="nav-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <a&ndash;&gt;-->
<!--&lt;!&ndash;              href="https://www.creative-tim.com/product/vue-material-dashboard-laravel-pro?_ga=2.100464214.933780196.1684307546-944306388.1662358829"&ndash;&gt;-->
<!--&lt;!&ndash;              class="mb-0 btn btn-sm me-1"&ndash;&gt;-->
<!--&lt;!&ndash;              :class="btnBackground"&ndash;&gt;-->
<!--&lt;!&ndash;              target="_blank"&ndash;&gt;-->
<!--&lt;!&ndash;              onclick="smoothToPricing('pricing-soft-ui')"&ndash;&gt;-->
<!--&lt;!&ndash;              >Buy Now</a&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;        </ul>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </nav>-->
  <!-- End Navbar -->
</template>

<script>
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";

export default {
  name: "Navbar",
  props: {
    btnBackground: {
      type: String,
      default: "bg-white"
    },
    isBlur: {
      type: String,
      default: ""
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downArrWhite,
      downArrBlack
    };
  },
  computed: {
    darkModes() {
      return {
        "text-dark": this.darkMode
      };
    }
  }
};
</script>
