<template>
  <nav id="navbarBlur" class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" v-bind="$attrs"
       data-scroll="true" :class="isAbsolute ? 'mt-4' : 'mt-4'">
    <div class="px-4 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :color="color"/>
<!--      <div v-if="isLoggedIn" id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0" :class="isRTL ? 'px-0' : 'me-sm-4'">-->
<!--        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">-->
<!--          <div class="sidenav-toggler-inner">-->
<!--            <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>-->
<!--            <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>-->
<!--            <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
           :class="isRTL ? 'px-0' : 'me-sm-4'">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <material-input id="search" label="Search" :color="color ? 'light' : 'dark'"/>
        </div>
        <BalanceComponent v-if="isLoggedIn"/>


        <ul class="navbar-nav d-flex justify-content-end">

          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle d-flex align-items-center py-0" href="#" role="button"-->
          <!--              data-bs-toggle="dropdown" aria-expanded="false" :class="color">-->
          <!--              <i class="material-icons" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'">account_circle</i>-->
          <!--            </a>-->

          <!--            <ul class="dropdown-menu"  style="transform: translateX(-6px) !important;">-->

          <!--              &lt;!&ndash;Profile&ndash;&gt;-->
          <!--              <li class="nav-item d-flex align-items-center">-->
          <!--                <router-link :to="{ name: 'User Profile' }"-->
          <!--                  class="px-0 nav-link font-weight-bold lh-1 d-flex align-items-center">-->
          <!--                  <i class="material-icons ms-2" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'">-->
          <!--                    account_circle-->
          <!--                  </i>-->
          <!--                  My Profile-->
          <!--                </router-link>-->
          <!--              </li>-->

          <!--              <hr class="m-0">-->

          <!--              &lt;!&ndash;Logout&ndash;&gt;-->
          <!--              <li class="nav-item d-flex align-items-center">-->
          <!--                <router-link :to="{ name: 'Login' }" @click="logout"-->
          <!--                  class="px-0 nav-link font-weight-bold lh-1 d-flex align-items-center">-->
          <!--                  <i class="material-icons ms-2" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'">-->
          <!--                    logout-->
          <!--                  </i>-->
          <!--                  Logout-->
          <!--                </router-link>-->
          <!--              </li>-->
          <!--            </ul>-->

          <!--          </li>-->

          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-body lh-1" @click="toggleSidebar">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <!--          <li class="px-3 nav-item d-flex align-items-center">-->
          <!--            <a class="p-0 nav-link lh-1" :class="color" @click="toggleConfigurator">-->
          <!--              <i class="material-icons fixed-plugin-button-nav cursor-pointer">-->
          <!--                settings-->
          <!--              </i>-->
          <!--            </a>-->
          <!--          </li>-->

        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import BalanceComponent from "@/components/BalanceComponent.vue";

export default {
  name: "Navbar",
  components: {BalanceComponent},
  props: {
    minNav: {
      type: Function,
      default: () => {
      },
    },
    color: {
      type: String,
      default: "text-body",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),
    ...mapGetters({
        'isLoggedIn': 'auth/isLoggedIn',
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.minNav;
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },

    logout() {
      this.$store.dispatch("auth/logout");
    }
  },
};
</script>
