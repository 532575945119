<template><!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
<!--      <div class="row">-->
<!--        <div class="mx-auto mb-4 text-center col-lg-8">-->
<!--          <a href="https://www.creative-tim.com" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">-->
<!--            Azurelab Network-->
<!--          </a>-->
<!--          -->
<!--        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">-->
<!--          <a href="https://www.facebook.com/CreativeTim" target="_blank" class="text-secondary me-xl-4 me-4">-->
<!--            <span class="text-lg fab fa-facebook"></span>-->
<!--          </a>-->
<!--          <a href="https://twitter.com/creativetim" target="_blank" class="text-secondary me-xl-4 me-4">-->
<!--            <span class="text-lg fab fa-twitter"></span>-->
<!--          </a>-->
<!--          <a href="https://www.instagram.com/creativetimofficial" target="_blank" class="text-secondary me-xl-4 me-4">-->
<!--            <span class="text-lg fab fa-instagram"></span>-->
<!--          </a>-->
<!--          <a href="https://github.com/creativetimofficial" target="_blank" class="text-secondary">-->
<!--            <span class="text-lg fab fa-github"></span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
            Azurelab Network {{ new Date().getFullYear() }}. © All rights reserved
        </div>
      </div>
    </div>
</footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
