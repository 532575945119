<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="searchProductName"
        placeholder="Filter by product"
        :options="productOptions ?? []"
        :show-no-results="true"
        :searchable="true"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {VueMultiselect},
  data() {
    return {
      searchProductName: '', // Data model for the niche search input
    };
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      productOptions: 'products/getProductNamesData',
      savedProductName: 'filters/getProductName'
    })
  },

  async created() {
    const savedFilter = await this.savedProductName;
    if (null !== savedFilter) {
      this.searchProductName = savedFilter;
    }
    await store.dispatch("products/getProductNames");
  },
  watch: {
    searchProductName: {
      handler: "updateSearchProductName",
      deep: true
    },
  },
  methods: {
    // Watcher to react to changes in the searchNiche input
    updateSearchProductName(newVal) {
      this.searchProductName = newVal;
      this.$store.commit('filters/saveProductName', newVal);
      var filter = {
        decision: String,
        filters: []
      }
      if (null === newVal) {
        filter.decision = 'delete';
        filter.filters.push({name: 'product_name'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'product_name', value: newVal})
      }

      // Emit an event or perform a search operation as the input changes
      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
