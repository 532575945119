<template>
  <div>
    <!-- Button to trigger modal -->
<!--    <button @click="openModal" class="btn btn-primary">Open Modal</button>-->

    <!-- Modal -->
    <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :class="{ show: showModal }"
        v-if="showModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal Title</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            This is the content of the modal. You can place any content here.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcceptCampaignModal",
  data() {
    return {
      showModal: false, // State to control modal visibility
    };
  },
  methods: {
    openModal() {
      this.showModal = true; // Set showModal to true to display the modal
    },
    closeModal() {
      this.showModal = false; // Set showModal to false to hide the modal
    },
  },
};
</script>

<style scoped>
/* Add any additional CSS for styling the modal */
.modal.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5); /* Dim the background when modal is open */
}
</style>
