<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedGeo"
        placeholder="Geo"
        :options="geoOptions"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    VueMultiselect,
  },

  async mounted() {
    const savedFilter = await this.savedGeo;
    if (null !== savedFilter) {
      this.selectedGeo = savedFilter;
    }
  },
  data() {
    return {
      selectedGeo: null, // Initially selected geographic option
      geoOptions: ['PL', 'RO', 'CZ', 'BG', 'DE', 'EG'], // Hardcoded geographic options
    };
  },

  watch: {
    selectedGeo: {
      handler: "updateSelectedGeo",
      deep: true
    },
  },

  computed: {
    ...mapGetters({
      savedGeo: 'filters/getGeo'
    }),
  },

  methods: {
    // Method to update selectedGeo and emit its value to the parent
    updateSelectedGeo(value) {
      this.selectedGeo = value;
      this.$store.commit('filters/saveGeo', value);
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offers_geo'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offers_geo', value: value})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
