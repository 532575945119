<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedUserActivity"
        placeholder="Filter by activity"
        :options="activityOptions"
        label="name"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      selectedUserActivity: null,
      activityOptions: [
        {name: 'Active', value: 'active'},
        {name: 'Inactive', value: 'inactive'},
        {name: 'Active long time ago', value: 'active_long_time_ago'},
      ]
    };
  },

  watch: {
    selectedUserActivity(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if ('' === value?.value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'last_activity_start_date'})
        filter.filters.push({name: 'last_activity_end_date'})
      } else {
        filter.decision = 'add'
      }

      var now = new Date();
      var yearInMs = 31556952000;
      var monthInMs = 2629746000;

      if ('active' === value?.value) {
        filter.filters.push({name: 'last_activity_start_date', value: (new Date(now.getTime() - monthInMs))})
        filter.filters.push({name: 'last_activity_end_date', value: (new Date(now.getTime()))})
      }
      if ('inactive' === value?.value) {
        filter.filters.push({name: 'last_activity_end_date', value: (new Date(now.getTime() - yearInMs))})
      }

      if ('activeLongTimeAgo' === value?.value) {
        filter.filters.push({name: 'last_activity_start_date', value: (new Date(now.getTime() - yearInMs))})
        filter.filters.push({name: 'last_activity_end_date', value: (new Date(now.getTime() - monthInMs))})
      }

      this.$emit('update:updateFilters', filter);
    },
  },
};
</script>

<style scoped>
</style>
