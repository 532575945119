<template>
  <div v-if="profile">

    <div id="profile" class="card card-body mt-4">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-auto col-4">
          <material-avatar
              :img="profile?.profile_image"
              alt="bruce"
              size="xl"
              shadow="sm"
              circular
          />
        </div>
        <div class="col-sm-auto col-8 my-auto">
          <div class="h-100">
            <h5 class="mb-1 font-weight-bolder">{{ profile.first_name }} {{ profile.last_name }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div id="basic-info" class="card mt-4">
      <div class="card-header">
        <h5>Basic Info</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row mt-4">
          <div class="col-6">
            <material-input
                id="email"
                v-model:value="profile.email"
                type="email"
                variant="static"
                label="Email"
                placeholder="example@email.com"
            />
            <validation-error :errors="apiValidationErrors.email"></validation-error>
          </div>
          <div class="col-6">
            <material-input
                id="confirmEmail"
                type="email"
                variant="static"
                label="Confirm Email"
                placeholder="example@email.com"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <material-input
                id="phoneNumber"
                v-model:value="profile.phone_number"
                type="phone"
                variant="static"
                label="Phone Number"
                placeholder="+40 745 765 439"
            />
            <validation-error :errors="apiValidationErrors.phone_number"></validation-error>
          </div>
          <div class="col-6">
            <material-input
                id="telegram"
                v-model:value="profile.telegram_account_name"
                variant="static"
                label="Telegram"
            />
            <validation-error :errors="apiValidationErrors.telegram_account_name"></validation-error>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <material-input
                id="usdt_trc20_wallet"
                v-model:value="profile.usdt_trc20_wallet"
                variant="static"
                label="USDT trc20 wallet"
            />
            <validation-error :errors="apiValidationErrors.usdt_trc20_wallet"></validation-error>
          </div>
          <div class="col-6">
            <material-input
                id="usdt_erc20_wallet"
                v-model:value="profile.usdt_erc20_wallet"
                variant="static"
                label="USDT erc20 wallet"
            />
            <validation-error :errors="apiValidationErrors.usdt_erc20_wallet"></validation-error>
          </div>
        </div>
        <material-button
            class="float-end mt-6 mb-0"
            color="dark"
            variant="gradient"
            size="sm"
            @click="handleUpdate"
        >Update info
        </material-button>
      </div>

    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <material-input id="newPassword" v-model:value="password.password" type="password" label="New Password"/>
      </div>
      <div class="mb-3">
        <material-input
            id="confirmPassword"
            type="password"
            label="Confirm password"
            v-model:value="password.password_confirmation"
        />
      </div>
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <material-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          @click="handlePasswordReset"
      >Update password
      </material-button
      >
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import img from "../../../assets/img/bruce-mars.jpg";
import img1 from "../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../assets/img/small-logos/logo-asana.svg";
import {mapGetters} from "vuex";
import showSwal from "@/mixins/showSwal";
import formMixin from "@/mixins/formMixin";
import {useRoute} from "vue-router";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "SideNavItem",
  components: {
    ValidationError,
    MaterialButton,
    MaterialInput,
    MaterialAvatar,
  },
  mixins: [formMixin],
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
      password: {}
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getUserProfile"
    })
  },
  setup() {
    const route = useRoute();
    const query = route.query
    return {query}
  },
  methods: {
    async handleUpdate() {
      this.resetApiValidation();
      try {
        //add user
        await this.$store.dispatch("users/editUser", {
          id: this.profile.id,
          email: this.profile.email,
          phone_number: this.profile.phone_number,
          telegram_account_name: this.profile.telegram_account_name,
          usdt_trc20_wallet: this.profile.usdt_trc20_wallet,
          usdt_erc20_wallet: this.profile.usdt_erc20_wallet
        });

        showSwal.methods.showSwal({
          type: "success",
          message: "Success!",
          width: 500
        });
      } catch (error) {
        this.setApiValidation(error.response.data.errors);
        showSwal.methods.showSwal({
          type: "error",
          message: 'Oops, something went wrong!',
          width: 500
        });
      }
    },
    async handlePasswordReset() {
      this.resetApiValidation();
      try {
        try {
          await this.$store.dispatch("auth/passwordUpdate", {
            newPassword: this.password.password,
            confirmPassword: this.password.password_confirmation
          });
          showSwal.methods.showSwal({
            type: "success",
            message: `Password Changed!`,
            width: 500
          });
        } catch (error) {
          showSwal.methods.showSwal({
            type: "error",
            message: "Oops, something went wrong!",
            width: 500
          });
        }
      } catch (error) {
        this.setApiValidation(error.response.data.errors);
        showSwal.methods.showSwal({
          type: "error",
          message: 'Oops, something went wrong!',
          width: 500
        });
      }
    }
  },
  mounted() {
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById(
          "choices-gender");
      new Choices(gender);
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month);
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day);
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year);
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
};
</script>
