<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedLeadStatus"
        placeholder="Lead Status"
        label="name"
        track-by="value"
        :options="leadStatusOptions"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {
    VueMultiselect,
  },
  props: {
    defaultLeadStatus: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      selectedLeadStatus: null, // Initially selected offer type
      leadStatusOptions: [
        {name: 'Approved', value: 'approved'},
        {name: 'Rejected', value: 'rejected'},
        {name: 'Processing', value: 'hold'},
        {name: 'Trashed', value: 'trashed'},
      ], // Hardcoded offer type options
    };
  },
  created() {
    if (this.defaultLeadStatus) {
      const leadStatus = this.leadStatusOptions.find(option => option.value === this.defaultLeadStatus);
      this.updateSelectedLeadStatus(leadStatus)
    }
  },
  watch: {
    selectedLeadStatus: {
      handler: "updateSelectedLeadStatus"
    }
  },
  methods: {
    // Watcher for selectedOfferType
    updateSelectedLeadStatus(value) {
      this.selectedLeadStatus = value;
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'lead_status'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'lead_status', value: value.value})
      }

      this.$emit('update:updateFilters', filter);
    }
  }
};
</script>

<style scoped>
</style>
