<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedStatus"
        placeholder="Status"
        :options="statusOptions"
        select-label='+'
        deselect-label="-"
        label="name"
        track-by="value"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {mapGetters} from "vuex";

export default {
  name: 'WithdrawalStatusFilter',
  components: {
    VueMultiselect,
  },

  async mounted() {
    const savedFilter = await this.savedStatus;
    if (null !== savedFilter) {
      this.selectedStatus = savedFilter;
    }
  },
  data() {
    return {
      selectedStatus: null, // Initially selected geographic option
      statusOptions: [
        {name: 'Paid', value: 'paid'},
        {name: 'Rejected', value: 'rejected'},
        {name: 'Waiting', value: 'waiting'},
      ],
    };
  },

  watch: {
    selectedStatus: {
      handler: "updateSelectedStatus",
      deep: true
    },
  },

  computed: {
    ...mapGetters({
      savedStatus: 'filters/getWithdrawalStatus'
    }),
  },

  methods: {
    // Method to update selectedStatus and emit its value to the parent
    updateSelectedStatus(value) {
      this.selectedStatus = value;
      this.$store.commit('filters/saveWithdrawalStatus', value);
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'withdrawal_status'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'withdrawal_status', value: value.value})
      }
      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
