import AuthService from '../services/auth.service';
import authService from '../services/auth.service';
import store from "@/store/index";

const user = JSON.parse(localStorage.getItem('user'));
let initialState = user ? {loggedIn: true} : {loggedIn: false};
initialState.impersonating = false;

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({commit}, user) {
            try {
                await AuthService.login(user);
                commit('isLoggedIn', true);
            } catch (error) {
                commit('isLoggedIn', false);
                throw (error)
            }
        },
        async logout({commit}) {
            try {
                await AuthService.logout();
                commit('isLoggedIn', false);
            } catch (error) {
                commit('isLoggedIn', true);
            }
        },
        async register({commit}, user) {
            try {
                await AuthService.register(user);
                commit('isLoggedIn', true);
            } catch (error) {
                commit('isLoggedIn', false);
                throw (error)
            }
        },

        // eslint-disable-next-line no-unused-vars
        async impersonate({commit}, userId) {
            const res = await authService.impersonateUser(userId);
            console.log(res.data);
            store.impersonating = res.data;

            location.reload()
        },

        // eslint-disable-next-line no-unused-vars
        async passwordForgot({commit}, userEmail) {
            await AuthService.passwordForgot(userEmail);
        },
        // eslint-disable-next-line no-unused-vars
        async passwordReset({commit}, passwordDTO) {
            await AuthService.passwordReset(passwordDTO);
        },
// eslint-disable-next-line no-unused-vars
        async passwordUpdate({commit}, passwordDTO) {
            await AuthService.passwordUpdate(passwordDTO);
        },
    },
    mutations: {
        isLoggedIn(state, loggedIn) {
            state.loggedIn = loggedIn
        }
    },
    getters: {
        isLoggedIn(state) {
            return state.loggedIn;
        }
    }
};
