/* eslint-disable no-unused-vars */
import blockedWebsService from '../services/blockedWebs.service';

const initialState = {
    blockedWebs: null,
    file: null,
    blockedWebsIds: null,
    promotedBlockedWebIds: null,
    blockedWeb: null,
    customPayouts: null,
    blockedWebsForFilters: null
};

export const blockedWebs = {
    namespaced: true,
    state: initialState,
    actions: {
        async getBlockedWebs({commit}, options) {
            const blockedWebs = await blockedWebsService.getBlockedWebs(options);
            commit('getBlockedWebsSuccess', blockedWebs);
        },
        async getBlockedWebsForFilter({commit}, options) {
            const blockedWebs = await blockedWebsService.getBlockedWebs(options);
            commit('getBlockedWebsForFilterSuccess', blockedWebs);
        },

        async getBlockedWebsIds({commit}, options) {
            if (null === this.state.blockedWebs.blockedWebsIds) {
                const blockedWebsIds = await blockedWebsService.getBlockedWebsIds(options);
                commit('getBlockedWebsIdsSuccess', blockedWebsIds);
            }
        },

        async addBlockedWeb({commit}, newBlockedWeb) {
            const blockedWeb = await blockedWebsService.addBlockedWeb(newBlockedWeb);
            commit('addBlockedWebSuccess', blockedWeb)
        },

        async deleteBlockedWeb({commit}, blockedWebId) {
            await blockedWebsService.deleteBlockedWeb(blockedWebId);
        },

        async getBlockedWeb({commit}, blockedWebId) {
            const blockedWeb = await blockedWebsService.getBlockedWeb(blockedWebId);
            commit('getBlockedWebSuccess', blockedWeb)
        },

        async editBlockedWeb({commit}, modifiedBlockedWeb) {
            await blockedWebsService.editBlockedWeb(modifiedBlockedWeb);
        },
    },
    mutations: {
        addBlockedWebSuccess(state, blockedWeb) {
            state.blockedWeb = blockedWeb
        },
        getBlockedWebsSuccess(state, blockedWebs) {
            state.blockedWebs = blockedWebs;
        },
        getBlockedWebsForFilterSuccess(state, blockedWebs) {
            state.blockedWebsForFilters = blockedWebs;
        },
        getBlockedWebsIdsSuccess(state, blockedWebsIds) {
            state.blockedWebsIds = blockedWebsIds;
        },
        getBlockedWebSuccess(state, blockedWeb) {
            state.blockedWeb = blockedWeb;
        },
        setBlockedWebForPage(state, blockedWeb) {
            state.blockedWeb = blockedWeb;
        }
    },
    getters: {
        getBlockedWebsData(state) {
            return state.blockedWebs?.data;
        },

        getBlockedWebsForFilter(state) {
            return state.blockedWebsForFilters?.data;
        },
        getBlockedWebsIds(state) {
            return state.blockedWebsIds?.data;
        },
        getBlockedWebsMeta(state) {
            return state.blockedWebs?.meta;
        },
        getBlockedWeb(state) {
            console.log("getBlockedWeb", state.blockedWeb)
            return state.blockedWeb;
        }
    }
}

