<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Share offer</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Offers List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div v-if="offers" class="card-body">
            <div class="row">
              <div class="col-3">
                <VueMultiselect
                    :custom-label="customLabelForOffer"
                    :show-labels="false"
                    v-model="offer"
                    placeholder="Offer"
                    :options="offers ?? []"
                    :show-no-results="true"
                    :track-by="id"
                    label="name"
                    select-label='+'
                    deselect-label="-"
                    :searchable="true">
                </VueMultiselect>
              </div>
              <div class="col-2">
                <VueMultiselect
                    v-model="selectedPartner"
                    placeholder="Partner"
                    label="name"
                    track-by="id"
                    :options="partners ?? []"
                    select-label='+'
                    deselect-label="-"
                >
                </VueMultiselect>
              </div>
              <div class="col-4">
                <VueMultiselect v-model="selectedWebs" :custom-label="customLabelForWeb"
                                :max-height="300"
                                :options="webs ?? []"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true" placeholder="Webs" label="name" track-by="name"
                                select-label='+'
                                deselect-label="-"
                >
                </VueMultiselect>
                <template>
                      <span v-for="(option, index) in selectedWebs" :key="index" :style="{ background: option.color }"
                            class="multiselect__tag">
                              <span>{{ option.ext_id }} {{ option.name }}</span>
                              <i tabindex="1" class="remove-status-icon" @click="removeOption(option)"></i>
                      </span>
                </template>
              </div>
            </div>

            <div class="card-header row d-flex align-items-center">
              <div class="col-12 text-end">
                <material-button class="float-right btn btm-sm" @click.prevent="handleSave">Save
                  changes
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin.js";
import store from "@/store";
import {mapGetters} from "vuex";
import MaterialButton from "@/components/MaterialButton.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import showSwal from "@/mixins/showSwal";

export default {
  name: 'ShareOffer',
  components: {
    VueMultiselect,
    MaterialButton,
  },
  watch: {
    offer: {
      handler: "updateListOfWebs",
      deep: true
    },
    websWithAccess: {
      handler: "websWithAccessChanged",
      deep: true
    },
    selectedPartner: {
      handler: "updateWebsListByPartner"
    }
  },
  methods: {
    removeOption(item) {
      this.selectedWebs = this.selectedWebs.filter(option => option !== item);
    },
    websWithAccessChanged() {
      //get elements that exist both in this.websWithAccess and this.webs
      this.selectedWebs = this.websWithAccess.filter(web => this.webs.some(selectedWeb => selectedWeb.id === web.id));
      console.log("this.selectedWebs", this.selectedWebs)
    },
    async updateListOfWebs(newValue) {
      if (newValue) {
        await store.dispatch("users/getWebsWithAccess", newValue.id);
      }
    },
    customLabelForOffer({id, name, geo, offer_type}) {
      return `${id} ${name} ${geo} ${offer_type}`;
    },
    customLabelForWeb({name, ext_id}) {
      return `${ext_id} ${name} `;
    },
    async updateWebsListByPartner() {
      if (this.selectedPartner) {
        await store.dispatch("users/getWebsIdsWithNames", {filter: {partner_ext_id: this.selectedPartner.ext_id}});
      }
      this.selectedWebs = this.websWithAccess.filter(web => this.webs.some(selectedWeb => selectedWeb.id === web.id));
    },
    handleSave() {
      this.apiValidationErrors = {};
      const data = {
        offer_id: this.offer.id,
        selected_webs: this.selectedWebs,
      };
      store.dispatch("offers/shareOffer", data)
          .then(() => {
            showSwal.methods.showSwal({
              type: "success",
              message: "Saved!",
              width: 500,
            });
            // this.$router.push({name: 'Offers List'});
          })
          .catch((error) => {
            this.apiValidationErrors = error.response.data.errors;
          });
    },

  },
  data() {
    return {
      sampleBannerFiles: null,
      fullPriceBannerFiles: null,
      sampleLandingPageFiles: null,
      fullPriceLandingPageFiles: null,
      offer: null,
      web: null,
      apiValidationErrors: {},
      selectedWebs: [],
      selectedPartner: null
    }
  },
  computed: {
    ...mapGetters({
      webs: 'users/getWebsIdsWithNames',
      offers: 'offers/getOffersData',
      websWithAccess: 'users/getWebsWithAccess',
      partners: "users/getPartnersIds",
    })
  },
  async created() {
    await store.dispatch("users/getWebsIdsWithNames");
    await store.dispatch("offers/getOffers");
    await store.dispatch("users/getPartnerIds");
  },

  mixins: [formMixin],
}
</script>
