<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="inHouseOffer"
        placeholder="In house"
        label="name"
        track-by="value"
        select-label="+"
        deselect-label="-"
        :options="inHouseOfferOptions ?? []"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      inHouseOffer: null,
      inHouseOfferOptions: [
        {name: 'In house', value: 1},
        {name: 'External', value: 0},
      ],
    };
  },

  watch: {
    inHouseOffer: {
      handler: "inHouseOfferHandler"
    },
  },
  methods: {
    // Method to update inHouseOffer and emit its value to the parent
    inHouseOfferHandler(value) {
      // console.log("inHouseOffer", this.inHouseOffer);
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offer_in_house'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offer_in_house', value: value.value})

      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
