import eventTable from "@/mixins/eventTable";
const { actionChangeWithdrawalStatus, downloadInvoiceAttachedToWithdrawal } = eventTable.methods;

export function getWithdrawalProperties(withdrawal, role) {
    const admin = role === 'admin';
    let webName = withdrawal.ext_id;
    if (withdrawal.web_name) {
        webName += ' ' + withdrawal.web_name;
    }
    let wallet = withdrawal.withdrawal_type === 'USDT - (ERC-20)' ? withdrawal.usdt_erc20_wallet : withdrawal.usdt_trc20_wallet;
    return [
        webName,
        withdrawal.partner_name,
        withdrawal.created_at,
        withdrawal.withdrawal_type,
        admin ? actionChangeWithdrawalStatus(withdrawal.id, withdrawal.withdrawal_status, withdrawal.withdrawal_status) : withdrawal.withdrawal_status,
        withdrawal.withdrawal_value, //to co na liście musi być stringiem
        withdrawal.bank_info,
        withdrawal.company_info,
        wallet,
        null !== withdrawal.invoice_id ? downloadInvoiceAttachedToWithdrawal(withdrawal.id) : '',
    ];
}
