import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getTags(params) {
        const response = await axios.get("/tags", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addTag(tag) {
        tag.type = "tags"
        const newJson = dataFormatter.serialize({ stuff: tag })
        const response = await axios.post("/tags", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteTag(tagId) {
        await axios.delete("/tags/" + tagId, { headers: authHeader() });
    },

    async getTag(tagId) {
        const response = await axios.get("/tags/" + tagId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editTag(tag) {
        tag.type = "tags"
        const newJson = dataFormatter.serialize({ stuff: tag })
        const response = await axios.patch("/tags/" + tag.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




