<template>
  <div v-if="me" id="profile" class="card card-body mt-4">
    <div class="row">
      <div class="col-sm-auto col-4">
        <material-avatar
          :img="me.profile_image"
          alt="bruce"
          size="xl"
          shadow="sm"
          circular
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder text-start">{{me.first_name }} {{me.last_name}}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ me.roles[0].name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <material-input
            id="firstName"
            variant="static"
            label="First Name"
            placeholder="Alec"
          />
        </div>
        <div class="col-6">
          <material-input
            id="lastName"
            variant="static"
            label="Last Name"
            placeholder="Thompson"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-4 ms-0">I'm</label>
          <select
            id="choices-gender"
            class="form-control"
            name="choices-gender"
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-5 col-5">
              <label class="form-label mt-4 ms-0">Birth Date</label>
              <select
                id="choices-month"
                class="form-control"
                name="choices-month"
              ></select>
            </div>
            <div class="col-sm-4 col-3">
              <label class="form-label mt-4 ms-0">&nbsp;</label>
              <select
                id="choices-day"
                class="form-control"
                name="choices-day"
              ></select>
            </div>
            <div class="col-sm-3 col-4">
              <label class="form-label mt-4 ms-0">&nbsp;</label>
              <select
                id="choices-year"
                class="form-control"
                name="choices-year"
              ></select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="email"
            type="email"
            variant="static"
            label="Email"
            placeholder="example@email.com"
          />
        </div>
        <div class="col-6">
          <material-input
            id="confirmEmail"
            type="email"
            variant="static"
            label="Confirm Email"
            placeholder="example@email.com"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="location"
            type="text"
            variant="static"
            label="Your Location"
            placeholder="Sydney, A"
          />
        </div>
        <div class="col-6">
          <material-input
            id="phoneNumber"
            type="phone"
            variant="static"
            label="Phone Number"
            placeholder="+40 745 765 439"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 align-self-center">
          <label class="form-label mt-4 ms-0">Language</label>
          <select
            id="choices-language"
            class="form-control"
            name="choices-language"
          >
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="Spanish">Spanish</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="form-label mt-4 ms-0">Skills</label>
          <input
            id="choices-skills"
            class="form-control"
            type="text"
            value="vuejs, angular, react"
            placeholder="Enter something"
            onfocus="focused(this)"
            onfocusout="defocused(this)"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <material-input
          id="currentPassword"
          type="password"
          label="Current Password"
        />
      </div>
      <div class="mb-3">
        <material-input id="newPassword" type="password" label="New Password" />
      </div>
      <div class="mb-3">
        <material-input
          id="confirmPassword"
          type="password"
          label="Confirm password"
        />
      </div>
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        >Update password</material-button
      >
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import img from "../../../../assets/img/bruce-mars.jpg";
import img1 from "../../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../../assets/img/small-logos/logo-asana.svg";

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialAvatar,
    MaterialInput,
  },
  data() {
    return {
      user: null,
      img,
      img1,
      img2,
      img3,
      img4,
    };
  },
  computed: {
    me() {
      return this.$store.getters['profile/getUserProfile']
    },
  },
  mounted() {
    this.user = this.me;
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender);
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month);
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day);
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year);
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
};
</script>
