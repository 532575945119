import eventTable from "@/mixins/eventTable";

const {removeBlockedWeb} = eventTable.methods;

export function getBlockedWebProperties(blockedWeb) {
    return [
        blockedWeb.web_ext_id?.toString(),
        blockedWeb.partner_name,
        blockedWeb.campaign_id ? blockedWeb.campaign_id?.toString() : 'Web is banned for all campaigns',
        blockedWeb.blocked_by_name,
        blockedWeb.created_at,
        removeBlockedWeb(blockedWeb.id)
    ]
}
