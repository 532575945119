<template>
  <div class="py-4 container-fluid">
    <div v-if="campaign" class="mt-4 row">
      <div class="col-9">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Generate Links</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Campaigns List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.prelanding_link" label="Prelanding link"
                                  variant="static"
                                  type="text"
                                  name="prelanding_link"></material-input>
                  <validation-error :errors="apiValidationErrors.prelanding_link"></validation-error>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.landing_link" label="Landing link"
                                  variant="static"
                                  type="text"
                                  name="landing_link"></material-input>
                  <validation-error :errors="apiValidationErrors.landing_link"></validation-error>
                </div>

              </div>
              <div class="row mt-5">
                <div class="col-2">
                  <material-input id="value" v-model:value="sub1" label="sub1"
                                  variant="static"
                                  type="text"
                                  disabled
                                  name="sub1"></material-input>
                </div>
                <div class="col-2">
                  <material-input id="value" v-model:value="sub2" label="sub2"
                                  variant="static"
                                  type="text"
                                  name="sub2"></material-input>
                </div>
                <div class="col-2">
                  <material-input id="value" v-model:value="sub3" label="sub3"
                                  variant="static"
                                  type="text"
                                  name="sub3"></material-input>
                </div>
                <div class="col-2">
                  <material-input id="value" v-model:value="sub4" label="sub4"
                                  variant="static"
                                  type="text"
                                  name="sub4"></material-input>
                </div>
                <div class="col-2">
                  <material-input id="value" v-model:value="sub5" label="sub5"
                                  variant="static"
                                  type="text"
                                  name="sub5"></material-input>
                </div>
                <div class="col-2 text-end">
                  <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Add
                  </material-button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "@/mixins/showSwal";
import {mapGetters} from "vuex";

export default {
  name: 'CampaignLinkGenerator',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
  },
  data() {
    return {
      campaign: null,
      domainChoices: null,
      domainNameprelanding_link: '',
      domainNamelanding_link: '',
      sub1: '',
      sub2: '',
      sub3: '',
      sub4: '',
      sub5: '',
    }
  },
  props: {
    campaignId: {
      type: String,
      default: ''
    },
  },
  watch: {
    sub1: {
      handler: "refreshLinks",
    },
    sub2: {
      handler: "refreshLinks",
    },
    sub3: {
      handler: "refreshLinks",
    },
    sub4: {
      handler: "refreshLinks",
    },
    sub5: {
      handler: "refreshLinks",
    },
  },
  computed: {
    ...mapGetters({
      campaigns: "campaigns/getCampaignsData",
    }),
  },
  mixins: [formMixin],
  async created() {
    await this.$store.dispatch('campaigns/getCampaignSimple', this.campaignId);
  },
  async mounted() {
    if('' === this.campaignId) {
      this.$router.push({ name: 'Campaigns List' })
    }

    try {
      //get roles
      await this.$store.dispatch('campaigns/getCampaignSimple', this.campaignId);
      await this.$store.dispatch('campaigns/getDomains');
      this.campaign = await this.$store.getters['campaigns/getCampaign'];
      // console.log('this.campaign', this.campaign.offer_prelanding_link, this.campaign.offer_landing_link);
      this.campaign.prelanding_link = this.campaign?.offer_prelanding_link;
      this.campaign.landing_link = this.campaign?.offer_landing_link;
    } catch (error) {
      console.log('error', error);
      showSwal.methods.showSwal({
        type: "error",
        message: "Oops, something went wrong!",
        width: 500
      });
    } finally {
      this.loading = false
    }

    if (this.campaign?.landing_link) {
      const url = new URL(this.campaign.landing_link);
      const params = url.searchParams;
      this.domainNamelanding_link = url.origin;
      this.domainNameprelanding_link = url.origin;
      this.sub1 = this.campaign.id;
      this.sub2 = params.get('sub2') ?? '';
      this.sub3 = params.get('sub3') ?? '';
      this.sub4 = params.get('sub4') ?? '';
      this.sub5 = params.get('sub5') ?? '';
    }
  },
  methods: {
    refreshLinks() {
      let landing_link = this.domainNamelanding_link + '/';
      let prelanding_link = this.domainNameprelanding_link + '/';

      if('' !== this.sub1 || '' !== this.sub2 || '' !== this.sub3 || '' !== this.sub4 || '' !== this.sub5) {
        landing_link += '?'
        prelanding_link += '?'
      }

      if('' !== this.sub1) {
        landing_link = this.addSub(landing_link, 'sub1', this.sub1);
        prelanding_link = this.addSub(prelanding_link, 'sub1', this.sub1);
      }

      if('' !== this.sub2) {
        landing_link = this.addSub(landing_link, 'sub2', this.sub2);
        prelanding_link = this.addSub(prelanding_link, 'sub2', this.sub2);
      }

      if('' !== this.sub3) {
        landing_link = this.addSub(landing_link, 'sub3', this.sub3);
        prelanding_link = this.addSub(prelanding_link, 'sub3', this.sub3);
      }

      if('' !== this.sub4) {
        landing_link = this.addSub(landing_link, 'sub4', this.sub4);
        prelanding_link = this.addSub(prelanding_link, 'sub4', this.sub4);
      }

      if('' !== this.sub5) {
        landing_link = this.addSub(landing_link, 'sub5', this.sub5);
        prelanding_link = this.addSub(prelanding_link, 'sub5', this.sub5);
      }

      this.campaign.prelanding_link = prelanding_link;
      this.campaign.landing_link = landing_link;
    },

    addSub(link, subLabel, subValue) {
      if('?' === link.charAt(link.length - 1)) {
        return link + `${subLabel}=${subValue}`;
      } else {
        return link + `&${subLabel}=${subValue}`;
      }
    },

    async handleAdd() {
      this.resetApiValidation();
      try {
        await this.$store.dispatch("campaigns/generateLinks", {
          prelanding_link: this.campaign.prelanding_link,
          landing_link: this.campaign.landing_link,
          id: this.campaign.id
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Campaign added successfully!",
          width: 500
        });
        this.$router.push({name: "Campaigns List"})
      } catch (error) {
        console.log("error",error);
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, something went wrong!",
          width: 500
        });

      }
    }
  }
}
</script>
