<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Block web</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Blocked Webs List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body" style="min-height: 250px">
            <div class="row">
              <div class="col-4">
                <VueMultiselect
                    v-model="web" :clear-on-select="false"
                    :custom-label="customLabelForWeb"
                    :max-height="300"
                    :options="webs ?? []"
                    :preserve-search="true" deselect-label="-" label="name" placeholder="Web"
                    select-label='+'
                    track-by="name"
                >
                </VueMultiselect>
              </div>
              <div class="col-4">
                <VueMultiselect
                    v-model="campaign" :clear-on-select="false"
                    :custom-label="customLabelForCampaign"
                    :max-height="300"
                    :options="campaigns ?? []"
                    :preserve-search="true" deselect-label="-" label="name" placeholder="Campaign"
                    select-label='+'
                    track-by="name"
                >
                </VueMultiselect>
              </div>
            </div>

            <div class="card-header row d-flex align-items-center">
              <div class="col-12 text-end">
                <material-button class="float-right btn btm-sm" @click.prevent="handleSave">Save
                  changes
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin.js";
import store from "@/store";
import {mapGetters} from "vuex";
import MaterialButton from "@/components/MaterialButton.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import showSwal from "@/mixins/showSwal";

export default {
  name: 'ShareOffer',
  components: {
    VueMultiselect,
    MaterialButton,
  },
  data() {
    return {
      web: null,
      campaign: null
    }
  },
  watch: {
    web: {
      handler: async function (value) {
        console.log('test');
        await store.dispatch("campaigns/getCampaignsListAdmin", {filter: {web_id: value.ext_id}});
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      webs: 'users/getWebsIdsWithNames',
      campaigns: 'campaigns/getCampaignsData',
    })
  },
  methods: {
    customLabelForWeb({name, ext_id}) {
      return `${ext_id} ${name ?? ''} `;
    },
    customLabelForCampaign({name, productName, offerType, offerGeo}) {
      return `${name ?? productName + ' ' + offerType + ' ' + offerGeo} `;
    },
    handleSave() {
      this.apiValidationErrors = {};
      const data = {
        web_id: this.web.id,
        campaign_id: this.campaign?.id,
      };
      store.dispatch("blockedWebs/addBlockedWeb", data)
          .then(() => {
            showSwal.methods.showSwal({
              type: "success",
              message: "Saved!",
              width: 500,
            });
            this.$router.push({name: 'Blocked Webs List'});
          })
          .catch((error) => {
            this.apiValidationErrors = error.response.data.errors;
            showSwal.methods.showSwal({
              type: "error",
              message: error.response.data.errors[0].detail
            });
          });
    },
  },

  async created() {
    await store.dispatch("users/getWebsIdsWithNames");
    await store.dispatch("campaigns/getCampaignsListAdmin");
  },

  mixins: [formMixin],
}
</script>
