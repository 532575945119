import eventTable from "@/mixins/eventTable.js"

const {
    actionDeleteButton,
    actionChangePartner,
    actionChangeUserStatus,
    actionChangeAffiliateManager,
    actionBanUserButton,
    actionEditButton,
    actionImpersonateUser
} = eventTable.methods;

function getImage(user) {
    if (!user.profileImage) return require("@/assets/img/placeholder.jpg")
    else {
        return user.profileImage
    }
}

export function getUserProperties(user, role, affManagers, partners) {
    const admin = role === 'admin';
    const partner = role === 'partner';
    const affiliateManager = role === 'affiliate_manager';

    if (admin) {
        return [
            `<img src="${getImage(user)}" class="avatar rounded-circle">`,
            user.extId,
            (user.firstName ?? '') + " " + (user.lastName ?? ''),
            user.roleName,
            user.telegramAccountName,
            actionChangeAffiliateManager(user.id, affManagers, user.affiliateManagerName), //todo
            actionChangePartner(user.id, partners, user.partnerName), //todo
            actionChangeUserStatus(user.id, user.status),
            user.email,
            user.latestActivity,
            actionImpersonateUser(user.id, "Impersonate user") + actionEditButton(user.id, "Edit User") + actionDeleteButton(user.id, "Delete user")
        ];
    }

    if (partner) {
        return [
            user.extId,
            user.firstName + " " + user.lastName,
            user.status,
            actionBanUserButton(user.id, "Ban user")
        ];
    }

    if (affiliateManager) {
        return [
            `<img src="${getImage(user)}" class="avatar rounded-circle">`,
            user.extId,
            user.firstName + " " + user.lastName,
            user.roleName,
            user.telegramAccountName,
            user.latestActivity,
            user.status,
            user.email,
            actionEditButton(user.id, "Edit User")
        ];
    }
    return [
        `<img src="${getImage(user)}" class="avatar rounded-circle">`,
        user.extId,
        user.firstName + " " + user.lastName,
        user.roleName,
        user.telegramAccountName,
        user.latestActivity,
        user.status,
        user.email,
    ];
}
