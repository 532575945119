import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getFiles(params) {
        const response = await axios.get(
            "/files/-actions/files-list", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },

    async getFilesIds(params) {
        const response = await axios.get("/files/-actions/files-ids", { headers: authHeader(), params: params });
        return { data: response.data };
    },

    async addBannerFiles(file, fileId) {
        let user = JSON.parse(localStorage.getItem('user'));

        const postUrl = "/uploads/file-banner-files/files/" + fileId + "/banner-files";
        const response = await axios.post(postUrl,
            { attachment: file },
            { headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + user,
                } }
        );

        return response.data;
    },

    async addLandingPageFiles(file, fileId) {
        let user = JSON.parse(localStorage.getItem('user'));
        const postUrl = "/uploads/landing-page-files/files/" + fileId + "/landing-page-files";
        const response = await axios.post(postUrl,
            { attachment: file },
            { headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + user,
                } }
        );

        return response.data;
    },

    async addFile(file) {
        file.type = "files"
        const newJson = dataFormatter.serialize({ stuff: file, includeNames: ['product'] })
        const response = await axios.post("/files", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteFile(fileId) {
        await axios.delete("/files/" + fileId, { headers: authHeader() });
    },

    async getFile(fileId) {
        const response = await axios.get("/files/" + fileId + "?include=files,product.files", { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editFile(file) {
        file.type = "files"
        const newJson = dataFormatter.serialize({ stuff: file })
        const response = await axios.patch("/files/" + file.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}
