import axios from 'axios';
import authHeader from './auth-header';
import Jsona from 'jsona';

const dataFormatter = new Jsona();

export default {
    async getProfile() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            return null;
        }
        var response = await axios.get("/me?include=roles,affiliate_manager", {headers: authHeader()})
        //const response = await axios.get("/me", { headers: authHeader() })
        return dataFormatter.deserialize(response.data);
    },

    async editProfile(profile) {
        profile.type = 'profile'
        const newJson = dataFormatter.serialize({stuff: profile})
        const response = await axios.patch("/user", newJson, {headers: authHeader()})
        return dataFormatter.deserialize(response.data);
    },

    async uploadPic(pic, userId) {
        const postUrl = "/uploads/users/" + userId + "/profile-image";
        const response = await axios.post(postUrl,
            {attachment: pic},
            {headers: {'Content-Type': 'multipart/form-data'}}
        );
        return response.data;
    }

}
