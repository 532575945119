/* eslint-disable no-unused-vars */
import depositsService from '../services/deposits.service';

const initialState = {deposits: null, deposit: null, addedDeposit: null, invoice: null, availableToWithdraw: null};

export const deposits = {
    namespaced: true,
    state: initialState,
    actions: {
        async getDeposits({commit}, options) {
            const deposits = await depositsService.getDeposits(options);
            commit('getDepositsSuccess', deposits);
        },
        async getAvailableToWithdraw({commit}, options) {
            const availableToWithdraw = await depositsService.getAvailableToWithdraw(options);
            commit('getAvailableToWithdrawSuccess', availableToWithdraw);
        },

        async getInvoice({commit}, depositId) {
            const invoice = await depositsService.downloadInvoice(depositId);
            commit('getInvoiceSuccess', invoice);
        },

        async addDeposit({commit}, newDeposit) {
            const deposit = await depositsService.addDeposit(newDeposit);
            commit('getDepositSuccess', deposit)
        },

        async addInvoice({commit}, invoice) {
            const invoiceResponse = (await depositsService.addInvoice(invoice, this.state.deposits.deposit.id)).url;
            commit('addInvoiceSuccess', invoiceResponse)
        },

        async deleteDeposit({commit}, depositId) {
            await depositsService.deleteDeposit(depositId);
        },

        async getDeposit({commit}, depositId) {
            const deposit = await depositsService.getDeposit(depositId);
            commit('getDepositSuccess', deposit)
        },

        async editDeposit({commit}, modifiedDeposit) {
            await depositsService.editDeposit(modifiedDeposit);
        },
    },
    mutations: {
        getAvailableToWithdrawSuccess(state, availableToWithdraw) {
            state.availableToWithdraw = availableToWithdraw;
        },
        addInvoiceSuccess(state, invoice) {
            state.invoice = invoice;
        },
        getInvoiceSuccess(state, invoice) {
            state.invoice = invoice;
        },
        getDepositsSuccess(state, deposits) {
            state.deposits = deposits;
        },
        getDepositSuccess(state, deposit) {
            state.deposit = deposit;
        },
        addDepositSuccess(state, deposit) {
            state.addedDeposit = deposit
        }
    },
    getters: {
        getAvailableToWithdraw(state) {
            return state.availableToWithdraw;
        },
        getDepositsData(state) {
            return state.deposits?.data;
        },
        getDepositsMeta(state) {
            return state.deposits?.meta;
        },
        getDeposit(state) {
            return state.deposit;
        },
        getAddedDeposit(state) {
            return state.addedDeposit;
        },
        getInvoice(state) {
            return state.invoice
        }
    }
}

