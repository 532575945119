<template>
  <tr>
    <th data-sortable="false" title="name">Offer</th>
    <th data-sortable="false" title="product">Partner</th>
    <th title="type" data-sortable="false">Web</th>
    <th title="geo" data-sortable="false">Payout (in $)</th>
  </tr>
</template>
<script>
export default {
  name: 'CustomPayoutsColumns',
  props: {
    role: {
      type: String,
      default: ''
    },
  }
}
</script>
