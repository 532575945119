<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedOfferType"
        placeholder="Offer Type"
        label="name"
        track-by="value"
        select-label='+'
        deselect-label="-"
        :options="offerTypeOptions"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    VueMultiselect,
  },

  watch: {
    selectedOfferType: {
      handler: "updateSelectedOfferType",
      deep: true
    },
  },

  computed: {
    ...mapGetters({
      savedOfferType: 'filters/getOfferType'
    }),
  },

  async mounted() {
    const savedFilter = await this.savedOfferType;
    if (null !== savedFilter) {
      this.selectedOfferType = savedFilter;
    }
  },

  data() {
    return {
      selectedOfferType: null, // Initially selected offer type
      offerTypeOptions: [
        {name: 'Full price', value: 'FP'},
        {name: 'Sample', value: 'Free'},
        {name: 'Hybrid', value: 'Hybrid'},
      ], // Hardcoded offer type options
    };
  },

  methods: {
    // Method to update selectedOfferType and emit its value to the parent
    updateSelectedOfferType(value) {
      this.selectedOfferType = value;
      this.$store.commit('filters/saveOfferType', value);
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offers_type'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offers_type', value: value.value})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
