import eventTable from "@/mixins/eventTable";

const {apiKeyForCampaign, actionEditCampaignButton, actionDeleteButton, generateCampaignPostbackUrl, actionAcceptCampaign, landingPageLinks, actionRedirectToCampaignLinkGenerate, redirectToLeadsBasedOnCampaign, downloadLP, changeCampaignName} = eventTable.methods;

export function getCampaignProperties(campaign, role) {
    if (role === 'affiliate_manager' || role === 'admin') {
        return [
            changeCampaignName(campaign.id, campaign.name ?? ''),
            campaign.productName,
            campaign.offerType,
            `<div ><span class="fi fi-${campaign.offerGeo.toLowerCase()} fis flag-span" title="${campaign.offerGeo.toUpperCase()}"></span> ${campaign.offerGeo.toUpperCase()} </div>`,
            campaign.partnerName,
            campaign.userExtId,
            null != campaign.approveRate ? campaign.approveRate.toString() + "%" : '',
            campaign.payout,
            campaign.totalPayout,
            apiKeyForCampaign(campaign.apiKey),
            landingPageLinks(campaign.prelandingLink ?? '', campaign.landingLink ?? ''),
            (false === campaign.accepted ? actionAcceptCampaign(campaign, "Accept campaign") : '') + redirectToLeadsBasedOnCampaign(campaign.id, "Leads")  + actionRedirectToCampaignLinkGenerate(campaign.id, "Generate Link") + downloadLP(campaign.id, "Download LP")  + generateCampaignPostbackUrl(campaign.id, "Generate Postback URL") + actionEditCampaignButton(campaign.id, "Edit campaign") + actionDeleteButton(campaign.id, "Delete campaign")
        ]
    } else if (role === 'affiliate') {
        // apply soon
        // actionRedirectToLinkGenerate(campaign.id, "Generate links")  +
        return [
            changeCampaignName(campaign.id, campaign.name),
            campaign.productName,
            campaign.offerType,
            `<div ><span class="fi fi-${campaign.offerGeo.toLowerCase()} fis flag-span" title="${campaign.offerGeo.toUpperCase()}"></span> ${campaign.offerGeo.toUpperCase()} </div>`,
            campaign.payout,
            apiKeyForCampaign(campaign.apiKey),
            landingPageLinks(campaign.prelandingLink ?? '', campaign.landingLink ?? ''),
            redirectToLeadsBasedOnCampaign(campaign.id, "Leads")  + actionRedirectToCampaignLinkGenerate(campaign.id, "Generate Link") + downloadLP(campaign.id, "Download LP") + generateCampaignPostbackUrl(campaign.id, "Generate Postback URL")
        ]
    } else { //partner
        return [
            changeCampaignName(campaign.id, campaign.name),
            campaign.productName,
            campaign.offerType,
            `<div ><span class="fi fi-${campaign.offerGeo.toLowerCase()} fis flag-span" title="${campaign.offerGeo.toUpperCase()}"></span> ${campaign.offerGeo.toUpperCase()} </div>`,
            campaign.userExtId,
            null != campaign.approveRate ? campaign.approveRate.toString() + "%" : '',
            campaign.payout,
            campaign.totalPayout,
            apiKeyForCampaign(campaign.apiKey),
            landingPageLinks(campaign.prelandingLink ?? '', campaign.landingLink ?? ''),
            redirectToLeadsBasedOnCampaign(campaign.id, "Leads")  + actionRedirectToCampaignLinkGenerate(campaign.id, "Generate Link")+ downloadLP(campaign.id, "Download LP") + generateCampaignPostbackUrl(campaign.id, "Generate Postback URL")
        ]
    }
}
