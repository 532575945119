<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                    class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="User Info"
                          :class="activeClass"
                      >
                        <span>Add User</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form>
                    <info :class="activeClass" @update:handleSave="handleSave"/>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "@/views/users/Info.vue";
import showSwal from "@/mixins/showSwal";
import formMixin from "@/mixins/formMixin";

export default {
  name: "AddUser",
  components: {
    Info,
  },
  data() {
    return {
      activeClass: "js-active position-relative",
    };
  },
  mixins: [formMixin],
  methods: {
    async handleSave(value) {
      this.user = value;
      this.resetApiValidation();

      try {
        //add user
        await this.$store.dispatch("users/addUser", this.user);

        //upload the pic
        // if (this.file !== null) {
        //   await this.$store.dispatch("users/uploadPic", this.file);
        //   this.file = null
        // }

        // this.user = _.omit(this.$store.getters['users/getUser'], 'links');

        //update user with pic
        // await this.$store.dispatch('users/editUser', {
        //   "id": this.user.id,
        //   "profile_image": this.user.profile_image
        // })
        showSwal.methods.showSwal({
          type: "success",
          message: "Success!",
          width: 500
        });
        this.$router.push({name: 'Users List'})
      } catch (error) {
        this.setApiValidation(error.response.data.errors);
        error.response.data.errors.forEach((error) => {
          showSwal.methods.showSwal({
            type: "error",
            message: error.detail,
            width: 500
          });
        });
      }
    },
  },
};
</script>

