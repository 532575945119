import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getWithdrawals(params) {
        const response = await axios.get("/withdrawals/-actions/list-withdrawals", { headers: authHeader(), params: params });
        return {data: response.data.data, meta: response.data};
    },

    async getAvailableToWithdraw() {
        const response = await axios.get("/withdrawals/-actions/available-to-withdraw", { headers: authHeader()});
        return response.data;
    },

    async addWithdrawal(withdrawal) {
        withdrawal.type = "withdrawals"
        const newJson = dataFormatter.serialize({ stuff: withdrawal })
        const response = await axios.post("/withdrawals", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async downloadInvoice(withdrawalId) {
        const getUrl = "/withdrawals/-actions/download-invoice/" + withdrawalId;
        axios.get(getUrl, { responseType: 'blob', headers: authHeader() })
            .then(response => {
                const blob = new Blob([response.data], { type: response.data.type })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = name
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
    },

    async addInvoice(pic, withdrawalId) {
        let user = JSON.parse(localStorage.getItem('user'));
        const postUrl = "/uploads/invoices/withdrawals/" + withdrawalId + "/invoice";
        const response = await axios.post(postUrl,
            { attachment: pic },
            { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + user,
            } }
        );

        return response.data;
    },

    async deleteWithdrawal(withdrawalId) {
        await axios.delete("/withdrawals/" + withdrawalId, { headers: authHeader() });
    },

    async getWithdrawal(withdrawalId) {
        const response = await axios.get("/withdrawals/" + withdrawalId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editWithdrawal(withdrawal) {
        withdrawal.type = "withdrawals"
        const newJson = dataFormatter.serialize({ stuff: withdrawal })
        const response = await axios.patch("/withdrawals/" + withdrawal.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




