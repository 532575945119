<template>
  <tr>
    <th data-sortable="false">Avatar</th>
    <th title="id">ID</th>
    <th title="username">Username</th>
    <th title="roles.name">Role</th>
    <th title="telegram_account_name">Contact</th>
    <th v-if="role==='admin'" title="affiliate_manager">Affiliate manager</th>
    <th v-if="role==='admin'" title="partner">Partner</th>
    <th title="status">Status</th>
    <th title="email">Email</th>
    <th data-sortable="false" title="last_active_at">Last active at</th>
    <th v-if="role==='admin' || role === 'partner' || role ==='affiliate_manager'" data-sortable="false">Action</th>
  </tr>
</template>
<script>
export default {
  name: 'UsersColumns',
  props: {
    role: {
      type: String,
      default: ''
    }
  }
}
</script>
