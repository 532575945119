<template>
  <tr>
    <th data-sortable="false" title="name">Name</th>
    <th data-sortable="false" title="product">Product</th>
    <th title="type" data-sortable="false">Type</th>
    <th title="geo" data-sortable="false">Geo</th>
    <th v-if="role === 'affiliate_manager' || role === 'admin'" title="web_id" data-sortable="false">Partner</th>
    <th v-if="role !== 'affiliate'" title="web_id" data-sortable="false">Web ID</th>
    <th v-if="role !== 'affiliate'" title="approve_rate">Avg approve rate</th>
    <th title="payout">Payout</th>
    <th v-if="role !== 'affiliate'" title="total_payout">Total payout</th>
    <th title="api_key" data-sortable="false">Api key</th>
    <th title="links" data-sortable="false">Links</th>
    <th data-sortable="false" title="action">Action</th>
  </tr>
</template>
<script>
export default {
  name: 'CampaignsColumns',
  props: {
    role: {
      type: String,
      default: ''
    },
  }
}
</script>
