import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getUsers(params) {
        const response = await axios.get("/users/-actions/users", {headers: authHeader(), params: params});
        return {data: response.data.data, meta: response.data};
    },

    async getAffiliateManagers() {
        try {
            const response = await axios.get("/users/-actions/affiliate-managers-telegram-accounts", {headers: authHeader()});
            return {data: response.data};
        } catch (error) {
            console.log(error)
            if (403 === error.response.status) {
                return {data: []};
            }
        }
    },

    async getPartners() {
        try {
            const response = await axios.get("/users/-actions/partners-telegram-accounts", {headers: authHeader()});
            return {data: response.data};
        } catch (error) {
            console.log(error)
            if (403 === error.response.status) {
                return {data: []};
            }
        }
    },

    async getWebsWithAccess(offerId) {
        const response = await axios.get("/offers/" + offerId + "/-actions/webs-with-access", {headers: authHeader()});
        return {data: response.data};
    },
    async getUserIds(params) {
        const response = await axios.get("/users/-actions/web-ids", {headers: authHeader(), params: params});
        return {data: response.data};
    },

    async getPartnerIds(params) {
        const response = await axios.get("/users/-actions/partner-ids", {headers: authHeader(), params: params});
        return {data: response.data};
    },
    async getAdvertiserIds(params) {
        const response = await axios.get("/users/-actions/advertiser-ids", {headers: authHeader(), params: params});
        return {data: response.data};
    },
    async getAFfiliateManagersIds(params) {
        const response = await axios.get("/users/-actions/affiliate-managers-ids", {
            headers: authHeader(),
            params: params
        });
        return {data: response.data};

    },

    async getWebsIdsWithNames(params) {
        const response = await axios.get("/users/-actions/webs-ids-with-names", {
            headers: authHeader(),
            params: params
        });
        return {data: response.data};

    },

    async getUsersForDashboard(params) {
        const response = await axios.get("/users/-actions/webs-for-dashboard", {
            headers: authHeader(),
            params: params
        });
        return {data: response.data.data, meta: response.data};
    }
    ,

    async getBestWebs(params) {
        const response = await axios.get("/users/-actions/best-webs", {headers: authHeader(), params: params});
        return {data: response.data.data, meta: response.data};
    }
    ,

    async addUser(user) {
        user.type = "users"
        user.relationshipNames = ['roles']

        const newJson = dataFormatter.serialize({stuff: user})
        const response = await axios.post("/users", newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    }
    ,

    async deleteUser(userId) {
        await axios.delete("/users/" + userId, {headers: authHeader()});
    }
    ,

    async getUser(userId) {
        const response = await axios.get("/users/" + userId + "?include=roles,latestToken", {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    }
    ,

    async editUser(user) {
        user.type = "users"
        const newJson = dataFormatter.serialize({stuff: user, includeNames: ['affiliate_manager']})
        const response = await axios.patch("/users/" + user.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async uploadPic(pic, userId) {
        const postUrl = "/uploads/users/" + userId + "/profile-image";
        const response = await axios.post(postUrl,
            {attachment: pic},
            {headers: {'Content-Type': 'multipart/form-data'}}
        );
        return response.data;
    }
}
