<template>
  <div class="py-4 container-fluid">
    <div class="campaign">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Campaigns</h5>
              <div class="d-flex align-items-center">
                <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                  <!-- The "per page" dropdown will be moved here -->
                </div>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="card-body">

            <!-- Table -->
            <div class="table-responsive">
              <table id="campaign-list" ref="table" class="table table-flush">
                <thead class="thead-light">
                <tr v-if="loadingFinished">
                  <th class="w-5" title="web">Web</th>
                  <th class="w-5" title="name">Product</th>
                  <th class="w-5" title="geo">Geo</th>
                  <th class="w-5" title="type">Offer type</th>
                  <th class="w-5" title="approve_rate">AR w/ trash</th>
                  <th class="w-5" title="approve_rate">AR w/o trash</th>
                  <th class="w-5" title="total_leads">Leads total</th>
                  <th class="w-5" title="approved_leads">Approved</th>
                  <th class="w-5" title="rejected_leads">Rejected</th>
                  <th class="w-5" title="trashed_leads">Trashed</th>
                  <th class="w-5" title="on_hold_leads">Processing</th>
                  <th class="w-5" title="valid_leads">Valid</th>
                  <th class="w-5" title="total_payout">Payout</th>
                  <th class="w-5" title="azurelab_commission_sum">Profit</th>
                </tr>
                </thead>
                <tbody class="text-sm">
                </tbody>
              </table>
            </div>
          </div>

          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">
            <div class="ms-3">
              <p class="text-sm">
                Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }}
              </p>
            </div>
            <BasePagination v-model="pagination.currentPage" :per-page="pagination.perPage"
                            :total="pagination.total" :value="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            @click="getDataFromPage($event)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";
import BasePagination from "@/components/BasePagination.vue";
import {mapGetters} from "vuex";

var sortDirection = "-total_payout";
var getCurrentPage = 1;
var filters = {};
const getCampaignsList = _.debounce(async function (params) {
  await store.dispatch("campaigns/getAdvertisersCampaignsForDashboard", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
    with_commission: true
  });
}, 300);

const updateDashboardStats = _.debounce(async function (params) {
  await store.dispatch("leads/getDashboardStatsForAdvertisers", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
    with_commission: true
  });
}, 300);

export default {
  name: "AdvertisersCampaignsList",
  components: {
    BasePagination
  },
  props: {
    webId: {
      type: String
    },
    updatedFilters: {
      type: Object
    },
  },
  data() {
    return {
      filters: {},
      search: '',
      dataCampaigns: [],
      pagination: {},
      dataTable: null,
      keys: null,
      localLeadsStatsForChart: {},
      loadingFinished: false
    }
  },
  mixins: [eventTable],
  computed: {
    campaignsList() {
      return this.$store.getters["campaigns/getCampaignsData"];
    },
    metaPage() {
      return this.$store.getters["campaigns/getCampaignsMeta"];
    },
    ...mapGetters({
      profile: 'profile/getUserProfile'
    })
  },
  watch: {
    updatedFilters: {
      handler: "updateFilters",
      immediate: true,
      deep: true
    },
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    campaignsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    }
  },
  async mounted() {
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 10
      });

      document.querySelector(".dataTable-bottom").remove()
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      await getCampaignsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable.options.perPage
      });

      // Wait for the table to be initialized before moving the dropdown
      this.dataTable.on('datatable.init', function () {
        // Select the "per page" dropdown. The class or ID might change based on the library's implementation
        var perPageDropdown = document.querySelector(".dataTable-dropdown");

        // Select the new target location for the dropdown
        var newLocation = document.getElementById("newPerPagePosition");

        // Move the dropdown to the new location
        if (perPageDropdown && newLocation) {
          newLocation.appendChild(perPageDropdown);
        }
      });

      this.dataTable.on('datatable.perpage', async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getCampaignsList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.dataTable.on('datatable.sort', async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getCampaignsList({
          sort: sortDirection = direction + column,
          query: filters,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    }
  },
  beforeUnmount() {
    sortDirection = "-total_payout";
    getCurrentPage = 1;
    filters = {};
  },
  methods: {
    async refreshList() {
      await getCampaignsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 5
      });

      await updateDashboardStats({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage,
        perpage: this.dataTable ? this.dataTable.options.perPage : 5
      });
    },
    async updateFilters() {
      filters = this.updatedFilters

      await this.refreshList();
    },

    async getDataFromPage(page) {
      await getCampaignsList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);

      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      await updateDashboardStats({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });
      this.dataCampaigns = [];
      if (this.campaignsList.length > 0) {
        this.campaignsList.forEach(campaign => {
          const partnerName = campaign.partnerName !== '' ? `<small style="margin-left: 4px;">(${campaign.partnerName})</small>` : '';
          this.dataCampaigns.push(
              [
                `<a>${campaign.webExtId}</a>${partnerName}`,
                campaign.productName,
                `<div"><span class="fi fi-${campaign.offerGeo.toLowerCase()} fis flag-span " title="${campaign.offerGeo}"></span> ${campaign.offerGeo}</div>`,
                campaign.offerType,
                '' !== campaign.approveRateWithTrash ? campaign.approveRateWithTrash.toString() + "%" : '',
                '' !== campaign.approveRateWithoutTrash ? campaign.approveRateWithoutTrash.toString() + "%" : '',
                this.navigateToLeads(this.updatedFilters, '', this.webId, campaign.leads.toString()),
                this.navigateToLeads(this.updatedFilters, 'approved', this.webId, campaign.leadsApproved.toString()),
                this.navigateToLeads(this.updatedFilters, 'rejected', this.webId, campaign.leadsRejected.toString()),
                this.navigateToLeads(this.updatedFilters, 'trashed', this.webId, campaign.leadsTrashed.toString()),
                this.navigateToLeads(this.updatedFilters, 'hold', this.webId, campaign.leadsOnHold.toString()),
                campaign.validLeads.toString(),
                this.campaignPayout(campaign.payout),
                campaign.azurelabCommissionSum
              ]
          )
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataCampaigns});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit Campaign",
          deletePath: "campaigns/deleteCampaign",
          getPath: "campaigns/getCampaigns",
          textDelete: "Campaign deleted successfully!",
          textDefaultData: 'campaigns'
        });
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }

};
</script>
