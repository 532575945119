<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedMetricType"
        placeholder="Metric type"
        label="name"
        track-by="value"
        :options="metricTypeOptions"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      selectedMetricType: null, // Initially selected offer type
      metricTypeOptions: [
        {name: 'CPA', value: 'CPA'},
        {name: 'CPL', value: 'CPL'},
      ], // Hardcoded offer type options
    };
  },

  watch: {
    // Watcher for selectedOfferType
    selectedMetricType(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offers_metric_type'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offers_metric_type', value: value.value})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
