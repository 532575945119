<template>
  <div v-if="ready" class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-9">
        <div class="card">
          <div class="card-header border-bottom d-flex justify-content-between align-items-center">
            <h5 class="mb-0">Global postback</h5>
            <material-button class="btn btm-sm" @click="$router.push({ name: 'Campaigns List' })">
              Back to list
            </material-button>
          </div>
          <div class="card-body">
            <div class="mb-4 col-6">
              <material-input id="url" v-model:value="url" label="URL" variant="static" type="text"></material-input>
            </div>
            <div class="mb-4 row">
              <div class="form-check col-auto">
                <input class="form-check-input" v-model="newConversion" type="checkbox" id="new-conversion">
                <label class="form-check-label" for="new-conversion">New conversion</label>
              </div>
              <div class="form-check col-auto">
                <input class="form-check-input" type="checkbox" v-model="conversionConfirmation" id="conversion-confirmation">
                <label class="form-check-label" for="conversion-confirmation">Conversion confirmation</label>
              </div>
              <div class="form-check col-auto">
                <input class="form-check-input" type="checkbox" v-model="conversionRejection" id="conversion-rejection">
                <label class="form-check-label" for="conversion-rejection">Conversion rejection</label>
              </div>
              <div class="form-check col-auto">
                <input class="form-check-input" type="checkbox" v-model="trashConversion" id="trash-conversion">
                <label class="form-check-label" for="trash-conversion">Trash conversion</label>
              </div>
            </div>
            <h6>How it works</h6>
            <ul class="list-unstyled">
              <li><strong>{uuid}</strong>: Conversions identification. Unique value</li>
              <li><strong>{status}</strong>: Conversion status: hold, rejected, approved, trashed</li>
              <li><strong>{payout}</strong>: Payout</li>
              <li><strong>{sub1}</strong>: An optional parameter that can be passed via API when sending a lead</li>
              <li><strong>{sub2}</strong>: An optional parameter that can be passed via API when sending a lead</li>
              <li><strong>{sub3}</strong>: An optional parameter that can be passed via API when sending a lead</li>
              <li><strong>{sub4}</strong>: An optional parameter that can be passed via API when sending a lead</li>
            </ul>
            <div class="text-end">
              <material-button class="btn btn-primary" @click="saveChanges">Save changes</material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import campaignsService from "@/services/campaigns.service";
import showSwal from "@/mixins/showSwal";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  name: 'PostbackUrlGenerator',
  components: {
    MaterialInput,
    MaterialButton,
  },
  props: {
    campaignId: {
      type: String,
      default: null
    },
    userId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      url: '',
      newConversion: false,
      conversionConfirmation: false,
      conversionRejection: false,
      trashConversion: false,
      ready: false
    }
  },
  created() {
    const payload = {};
    if (this.campaignId) {
      payload.campaignId = this.campaignId;
    }
    if (this.userId){
      payload.userId = this.userId;
    }

    //check if payload has any properties
    if (Object.keys(payload).length !== 0) {
      this.getPostback(payload);
    } else {
      this.ready = true;
    }
  },
  methods: {
    getPostback(payload) {
      campaignsService.getPostback(payload).then((response) => {
        if (!response.data) {
          return;
        }

        this.url = response.data.url;
        this.newConversion = response.data.newConversion;
        this.conversionConfirmation = response.data.conversionConfirmation;
        this.conversionRejection = response.data.conversionRejection;
        this.trashConversion = response.data.trashConversion;
        this.ready = true;
      }).catch(() => {
        showSwal.methods.showSwal({
          type: "error",
          message: "Error occurred!",
          width: 500
        });
        this.ready = true;
      })
    },
    saveChanges() {
      campaignsService.createPostback({
        url: this.url,
        newConversion: this.newConversion,
        conversionConfirmation: this.conversionConfirmation,
        conversionRejection: this.conversionRejection,
        trashConversion: this.trashConversion,
        campaignId: this.campaignId, //one of those (campaignId and userId) will be empty
        userId: this.userId
      }).then(() => {
        showSwal.methods.showSwal({
          type: "success",
          message: "Postback saved!",
          width: 500
        });
      }).catch(() => {
        showSwal.methods.showSwal({
          type: "error",
          message: "Error occurred!",
          width: 500
        });
      })
    }
  }
}
</script>
