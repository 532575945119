<template>
  <div v-if="user" class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Withdrawal request</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Withdrawals List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <Form role="form" class="text-start mt-3 align-items-end" :validation-schema="schema" @submit="handleLogin"
                  @invalid-submit="badSubmit">
              <div class="col">

                <div class="mt-3 mb-5 col-2">
                  <label for="withdrawal_type" style="margin-left: 0;">Type</label>
                  <VueMultiselect v-model="withdrawal.withdrawal_type"
                                  placeholder=""
                                  :options="withdrawal_types"
                                  select-label='+'
                                  deselect-label="-"
                                  aria-required="true"
                  >
                  </VueMultiselect>
                </div>

                <div class="mt-5 mb-5 col-2">
                  <material-input-field id="withdrawal_value" v-model:value="withdrawal.withdrawal_value" type="number"
                                        label="How much? (in $)"
                                        name="withdrawal_value" variant="static" :max="availableToWithdrawIn$"/>
                </div>

                <div class="mt-5 mb-5 col-2" v-if="withdrawal.withdrawal_type === 'USDT - (ERC-20)'">
                  <div class="row">
                    <material-input-field id="wallet" :disabled="true"
                                          v-model:value="withdrawal.usdt_erc20_wallet" type="string"
                                          label="ERC-20 Wallet"
                                          name="wallet" variant="static"/>
                    <router-link v-if="!hasERC20Wallet" to="/user-profile" class="btn btn-sm">
                      Click here to define it in your profile
                    </router-link>
                  </div>
                </div>
                <div class="mt-5 mb-5 col-2" v-if="withdrawal.withdrawal_type === 'USDT - (TRC - 20)'">
                  <div class="row">
                    <material-input-field id="wallet" :disabled="true"
                                          v-model:value="withdrawal.usdt_trc20_wallet" type="string"
                                          label="TRC-20 Wallet"
                                          name="wallet" variant="static"/>
                    <router-link v-if="!hasTRC20Wallet" to="/user-profile" class="btn btn-sm">
                      Click here to define it in your profile
                    </router-link>
                  </div>
                </div>

                <div class="mt-5 mb-5 col-2" v-if="withdrawal.withdrawal_type === 'WIRE - (invoice)'">
                  <material-input-field id="bank_info" v-model:value="withdrawal.bank_info" type="string"
                                        label="Bank information"
                                        name="bank_info" variant="static"/>
                </div>
                <div class="mt-5 mb-5 col-2" v-if="withdrawal.withdrawal_type === 'WIRE - (invoice)'">
                  <material-input-field id="company_info" v-model:value="withdrawal.company_info" type="string"
                                        label="Company information"
                                        name="company_info" variant="static"/>
                </div>

                <div class="mt-5 mb-5 col-2">
                  <FileUploadComponent v-if="withdrawal.withdrawal_type === 'WIRE - (invoice)'" :id="'invoice'"
                                       :text="'Select Invoice'" @update:updateFile="updateFile"/>

                </div>
              </div>

            </Form>

            <div class="add-request-btn col-2">
              <material-button class="btn btm-sm" @click.prevent="handleAdd">Add Request
              </material-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../mixins/showSwal";
import {mapGetters} from "vuex";
import FileUploadComponent from "@/views/withdrawals/FileUploadComponent.vue";
import MaterialInputField from "@/components/MaterialInputField.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  name: 'AddWithdrawal',
  components: {
    VueMultiselect,
    MaterialInputField,
    FileUploadComponent,
    MaterialButton,
  },
  data() {
    return {
      file: null,
      withdrawal: {},
      withdrawal_types: ['USDT - (TRC - 20)', 'USDT - (ERC-20)', 'WIRE - (invoice)']
    }
  },
  mixins: [formMixin],
  computed: {
    ...mapGetters({
      availableToWithdraw: "withdrawals/getAvailableToWithdraw",
      user: "profile/getUserProfile"
    }),
    hasTRC20Wallet() {
      return this.user.usdt_trc20_wallet !== '' && this.user.usdt_trc20_wallet !== null;
    },
    hasERC20Wallet() {
      return this.user.usdt_erc20_wallet !== '' && this.user.usdt_erc20_wallet !== null;
    },
    availableToWithdrawIn$() {
      return this.availableToWithdraw?.amount;
    },
    availableToWithdrawLabel() {
      return this.availableToWithdraw?.label
    }
  },
  watch: {
    user() {
      this.withdrawal.usdt_trc20_wallet = this.user.usdt_trc20_wallet;
      this.withdrawal.usdt_erc20_wallet = this.user.usdt_erc20_wallet;
    }
  },
  async mounted() {
    await this.$store.dispatch("withdrawals/getAvailableToWithdraw");
    await this.$store.dispatch("profile/getProfile", true);
  },
  methods: {
    updateFile(value) {
      this.file = value;
    },

    async handleAdd() {
      this.resetApiValidation();
      try {
        await this.$store.dispatch("withdrawals/addWithdrawal", {
          withdrawal_type: this.withdrawal.withdrawal_type,
          withdrawal_value: parseInt(this.withdrawal.withdrawal_value),
          usdt_trc20_wallet: this.withdrawal.usdt_trc20_wallet,
          usdt_erc20_wallet: this.withdrawal.usdt_erc20_wallet,
          bank_info: this.withdrawal.bank_info,
          company_info: this.withdrawal.company_info,
        });

        if (this.file !== null) {
          await this.$store.dispatch("withdrawals/addInvoice", this.file);
          this.file = null
        }

        showSwal.methods.showSwal({
          type: "success",
          message: "Withdrawal added successfully!",
          width: 500
        });
        this.$router.push({name: "Withdrawals List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.errors[0].detail,
          width: 500
        });
      }
    }
  }
}
</script>
<style scoped>
.add-request-btn {
  margin-top: 20px;
}
</style>
