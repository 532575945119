<template>
  <div class="dataTable-search search-block col-9 col-md-6 col-lg-4">
    <vue-date-picker v-model="date" placeholder="Created at" :enable-time-picker="false" :exact-match="true" range format="dd-MM-yyyy"/>
  </div>
</template>

<script>
import VueDatePicker from "@/main";
import {mapGetters} from "vuex";

export default {
  components: {VueDatePicker},
  data() {
    return {
      date: '', // Data model for the niche search input
    };
  },

  async mounted() {
    const savedFilter = await this.savedLeadsDate;
    if (null !== savedFilter) {
      console.log('savedFilter', savedFilter);
      // Parse savedFilter dates into Date objects
      const parsedDates = savedFilter.map(dateStr => {
        const [month, day, year] = dateStr.split('/');
        return new Date(year, month - 1, day);
      });
      this.date = parsedDates;
    } else {
      const today = new Date();
      const basicFormat = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
      this.setDateValue([basicFormat, basicFormat]);
    }
  },
  computed: {
    ...mapGetters({
        savedLeadsDate: 'filters/getLeadsDate'
    }),
  },

  watch: {
    date: {
      handler: "updateDate",
      deep: true
    },
  },
  methods: {
    setDateValue(newValue) {
      this.date = newValue;
    },

    formatDate(date) {
      if (typeof date === 'string' || date instanceof String) {
        return date;
      }
      if (!(date instanceof Date) || isNaN(date)) {
        console.error('Invalid date:', date);
        return '';
      }

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },

    updateDate(value) {
      console.log('updateDate', value);

      let startDate = null;
      let endDate = null;

      if (value && Array.isArray(value) && value.length === 2) {
        startDate = this.formatDate(value[0]);
        endDate = this.formatDate(value[1]);
      } else {
        const today = new Date();
        startDate = this.formatDate(today);
        endDate = this.formatDate(today);
      }

      this.$store.commit('filters/saveLeadsDate', [startDate, endDate]);
      console.log('startDate', startDate);
      console.log('endDate', endDate);
      let filter = {};
      if (null === value) {
        filter = {
          decision: 'delete',
          filters: [
            { name: 'leads_start_date'},
            { name: 'leads_end_date'},
          ],
        };
      } else {
        filter = {
          decision: 'add',
          filters: [
            { name: 'leads_start_date', value: startDate },
            { name: 'leads_end_date', value: endDate },
          ],
        };
      }

      this.$emit('update:updateFilters', filter);
    },
  },
};
</script>

<style scoped>
</style>
