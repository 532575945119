<template>
  <div class="py-4 container-fluid">
    <BlockedWebsFilters v-if="profile" :role="profile?.roles[0].name" :update-filters="updateFilters"/>
    <div class="mt-4 blockedWeb">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Blocked Webs</h5>
              <div class="d-flex align-items-center">
                <!-- Ensure the dropdown and button are contained within a flex container for alignment -->
                <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                  <!-- The "per page" dropdown will be moved here -->
                </div>
                <div
                    v-if="profile && (profile.roles[0].name === 'admin' || profile.roles[0].name === 'affiliate_manager')">
                  <button class="btn btn-outline-success btn-sm ms-2 btn-add-on-listing"
                          @click="this.$router.push({ name: 'Add BlockedWeb' })">
                    <i class="fas fa-user-plus me-2"></i>
                    Add Blocked Web
                  </button>

                </div>
                <!-- Button aligned with the dropdown -->
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="card-body">

            <!-- Table -->
            <div class="table-responsive">
              <table id="blockedWeb-list" ref="table" class="table table-flush">
                <thead class="thead-light">
                <BlockedWebsColumns v-if="loadingFinished" :role="profile?.roles[0].name"/>
                </thead>
                <tbody class="text-sm">
                </tbody>
              </table>
            </div>
          </div>

          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">
            <div class="ms-3">
              <p class="text-sm">
                Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }}
              </p>
            </div>
            <BasePagination v-model="pagination.currentPage" :per-page="pagination.perPage"
                            :total="pagination.total" :value="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            @click="getDataFromPage($event)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";

import BasePagination from "@/components/BasePagination.vue";
import BlockedWebsColumns from "@/components/blockedWebs/BlockedWebsColumns.vue";
import BlockedWebsFilters from "@/components/blockedWebs/BlockedWebsFilters.vue";
import {getBlockedWebProperties} from "@/components/blockedWebs/blockedWebsUtils";
import {mapGetters} from "vuex";

var sortDirection = "created_at";
var getCurrentPage = 1;
var filters = {};
const getBlockedWebsList = _.debounce(async function (params) {
  await store.dispatch("blockedWebs/getBlockedWebs", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "BlockedWebsList",
  components: {
    BlockedWebsFilters,
    BlockedWebsColumns,
    BasePagination
  },
  data() {
    return {
      search: '',
      dataBlockedWebs: [],
      pagination: {},
      dataTable: null,
      keys: null,
      searchGeo: '',
      searchBlockedWebType: '',
      searchNiche: '',
      filters: {},
      blockedWebTypeOptions: [
        {name: 'Full price', value: 'full_price'},
        {name: 'Sample', value: 'sample'},
      ],
      geoOptions: [
        'PL', 'RO', 'CZ', 'BG', 'DE'
      ],
      loadingFinished: false
    }
  },
  mixins: [eventTable],
  computed: {
    blockedWebsList() {
      return this.$store.getters["blockedWebs/getBlockedWebsData"];
    },
    ...mapGetters({
      profile: "profile/getUserProfile"
    }),
    metaPage() {
      return this.$store.getters["blockedWebs/getBlockedWebsMeta"];
    },
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    blockedWebsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    searchBlockedWebType: {
      handler: "reactiveSearchBlockedWebType"
    },
    searchGeo: {
      handler: "reactiveSearchGeo"
    },
    searchNiche: {
      handler: "reactiveSearchNiche"
    },
    search: {
      handler: "reactiveSearch"
    },
    filters: {
      handler: "reactiveFilters",
      deep: true
    }
  },
  async mounted() {
    await store.dispatch("profile/getProfile");
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        rowNavigation: true,
        perPage: 25
      });

      document.querySelector(".dataTable-bottom").remove()
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      await getBlockedWebsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });

      // Wait for the table to be initialized before moving the dropdown
      this.dataTable.on('datatable.init', function () {
        // Select the "per page" dropdown. The class or ID might change based on the library's implementation
        var perPageDropdown = document.querySelector(".dataTable-dropdown");

        // Select the new target location for the dropdown
        var newLocation = document.getElementById("newPerPagePosition");

        // Move the dropdown to the new location
        if (perPageDropdown && newLocation) {
          newLocation.appendChild(perPageDropdown);
        }
      });

      this.dataTable.on('datatable.perpage', async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getBlockedWebsList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.dataTable.on('datatable.sort', async function (column, direction) {

        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getBlockedWebsList({
          sort: sortDirection = direction + column,
          query: filters,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    }
  },
  beforeUnmount() {
    sortDirection = "created_at";
    filters = {};
    getCurrentPage = 1;
  },
  methods: {
    async refreshList() {
      await getBlockedWebsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 25
      });
    },
    async updateFilters(updatedFilter) {
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }

      await this.refreshList();
    },

    async getDataFromPage(page) {
      await getBlockedWebsList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataBlockedWebs = [];
      if (this.blockedWebsList.length > 0) {
        this.blockedWebsList.forEach(blockedWeb => {
          this.dataBlockedWebs.push(
              getBlockedWebProperties(blockedWeb, this.profile?.roles[0].name)
          )
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataBlockedWebs});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit BlockedWeb",
          deletePath: "blockedWebs/deleteBlockedWeb",
          getPath: "blockedWebs/getBlockedWebs",
          textDelete: "BlockedWeb deleted successfully!",
          textDefaultData: 'blockedWebs'
        });
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }

};
</script>

<style scoped>
.avatar-xxl {
  width: 80px !important;
  height: 80px !important;
  border-radius: 15px;
  object-fit: contain;
}
</style>
