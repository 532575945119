import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const appInstance = createApp(App);
const API_URL = process.env.VUE_APP_API_BASE_URL
import CountryFlag from 'vue-country-flag-next'

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* i18n */
import { createI18n } from 'vue-i18n'
import messages from './mixins/messages.js'
const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

/* import specific icons */
import {
    faUser,
    faHouse,
    faUserSecret,
    faBriefcase,
    faRoute,
    faDollarSign,
    faBookmark, faLifeRing
} from '@fortawesome/free-solid-svg-icons'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import FlagIcon from 'vue-flag-icon'
import AcceptCampaignModal from "@/components/campaigns/AcceptCampaignModal.vue";
/* add icons to the library */
library.add(
    faUserSecret,
    faTwitter,
    faHouse,
    faUser,
    faBriefcase,
    faRoute,
    faDollarSign,
    faBookmark,
    faLifeRing
)

/* add font awesome icon component */

axios.defaults.baseURL = `${API_URL}/api/v2`;
import * as Sentry from "@sentry/vue";

Sentry.init({
    appInstance,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


appInstance.use(store);
appInstance.use(i18n);
appInstance.use(FlagIcon);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.component('VueDatePicker', VueDatePicker);
appInstance.component('CountryFlag', CountryFlag);
appInstance.component('font-awesome-icon', FontAwesomeIcon)
appInstance.component('AcceptCampaignModal', AcceptCampaignModal);
appInstance.mount("#app");

export default appInstance;
