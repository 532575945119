<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row mt-sm-2">
        <LeadsDateFilter @update:updateFilters="updateFilters"/>

        <UsersAdvertiserIDFilter @update:updateFilters="updateFilters"/>

        <WebIDFilter @update:updateFilters="updateFilters"/>

        <UsersAffiliateManagerIDFilter @update:updateFilters="updateFilters"/>
        
        <GeoFilter @update:updateFilters="updateFilters"/>

        <ProductNameFilter @update:updateFilters="updateFilters"/>

        <OfferTypeFilter @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import OfferTypeFilter from "@/components/Filters/OfferTypeFilter.vue";
import LeadsDateFilter from "@/components/Filters/LeadsDateFilter.vue";
import ProductNameFilter from "@/components/Filters/ProductNameFilter.vue";
import GeoFilter from "@/components/Filters/GeoFilter.vue";
import UsersAdvertiserIDFilter from "@/components/Filters/UsersAdvertiserIDFilter.vue";
import WebIDFilter from "@/components/Filters/WebIDFilter.vue";
import UsersAffiliateManagerIDFilter from "@/components/Filters/UsersAffiliateManagerIDFilter.vue";

export default {
  name: 'AdvertisersDashboardFilters',
  components: {
    UsersAffiliateManagerIDFilter,
    WebIDFilter,
    UsersAdvertiserIDFilter,
    GeoFilter,
    ProductNameFilter,
    LeadsDateFilter,
    OfferTypeFilter,
  },
  props: {
    updateFilters: {}
  }
}
</script>
