<template>
  <div class="multisteps-form__panel border-radius-xl bg-white" data-animation="FadeIn">

    <div class="text-end">
      <material-button class="float-right btn btm-sm" @click.prevent="this.$router.push({ name: 'Users List' })">
        Back to list
      </material-button>
    </div>
    <Form :validation-schema="schema" class="text-start mt-3" role="form">

      <div class="mt-5">
        <label class="ms-0"> Role </label>
        <select id="choices-state" class="multisteps-form__select form-control" name="choices-state">
        </select>
      </div>

      <div class="row mt-5">
        <material-input id="ext_id" v-model:value="user.ext_id" label="Web Id (generated automatically if empty)"
                        name="ext_id"
                        variant="static"/>
        <validation-error :errors="apiValidationErrors.ext_id"></validation-error>
      </div>

      <div class="row mt-5">
        <material-input id="first_name" v-model:value="user.first_name" label="Username" name="first_name"
                        variant="static"/>
        <validation-error :errors="apiValidationErrors.first_name"/>
      </div>

      <div class="row mt-5">
        <material-input v-if="userProfile?.roles[0].name !=='partner'" id="email" v-model:value="user.email"
                        label="Email Address"
                        name="email" type="email"
                        variant="static"/>
        <validation-error :errors="apiValidationErrors.email"/>
      </div>

      <!-- Telegram Account Name -->
      <div class="row mt-5">
        <material-input id="telegram_account_name" v-model:value="user.telegram_account_name" label="Telegram Account Name"
                        name="telegram_account_name" variant="static"/>
        <validation-error :errors="apiValidationErrors.telegram_account_name"/>
      </div>

      <div class="row mt-5">
        <material-input id="password" v-model:value="user.password" label="Password"
                        name="password" type="password" variant="static"/>
      </div>
      <div class="row mt-5">
        <material-input id="confirmPassword" v-model:value="user.password_confirmation"
                        label="Confirm Password" name="confirmPassword" type="password" variant="static"/>
      </div>

      <div class="button-row d-flex mt-4">
        <material-button class="ms-auto mb-0 js-btn-next" color="dark" type="button" variant="gradient"
                         @click="handleSave">Save
        </material-button>
      </div>
    </Form>
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import _ from "lodash";
import showSwal from "@/mixins/showSwal";
import Choices from "choices.js";
import {mapGetters} from "vuex";
import * as Yup from "yup";
import MaterialInput from "@/components/MaterialInput.vue";
import store from "@/store";

export default {
  name: "Info",
  components: {
    MaterialInput,
    MaterialButton,
    ValidationError,
  },
  props: {
    userId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      user: {},
      file: null,
      loading: null,
      roleChoices: null,
      schema: Yup.object().shape({
        email: Yup.string().email("Email has to be a valid email address").required("Email is a required input"),
      }),
    }
  },
  mixins: [formMixin],
  computed: {
    rolesList() {
      if ('admin' !== this.userProfile?.roles[0].name) {
        return this.$store.getters['roles/getRolesData'].filter(elem => elem.name === 'affiliate' || elem.name === 'advertiser'); //admin not allowed
      } else {
        return this.$store.getters['roles/getRolesData'].filter(elem => elem.name !== 'admin'); //admin not allowed
      }
    },
    getImage() {
      if (!this.user.profile_image || this.loading) return require("@/assets/img/placeholder.jpg")
      else {
        return this.user.profile_image
      }
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      userProfile: 'profile/getUserProfile',
    })
  },
  async created() {
    if ('' !== this.userId) {
      await this.$store.dispatch("users/getUser", this.userId);
      this.user = _.omit(this.$store.getters["users/getUser"], 'links');
      if (this.roleChoices) {
        this.roleChoices.setChoiceByValue(this.user.roles[0].id);
      }
    }
  },

  async mounted() {
    await store.dispatch("profile/getProfile")
    this.loading = true

    try {
      //get roles
      await this.$store.dispatch('roles/getRoles');

      if (document.getElementById("choices-state")) {
        var element = document.getElementById("choices-state");
        this.roleChoices = new Choices(element, {
          searchEnabled: false,
        });

        this.roleChoices.setChoices(this.rolesList, 'id', 'name')
        this.roleChoices.setChoiceByValue(this.rolesList[0].id)

      }
    } catch (error) {
      showSwal.methods.showSwal({
        type: "error",
        message: "Oops, something went wrong!",
        width: 500
      });
    } finally {
      this.loading = false
    }
  },
  methods: {
    handleSave() {
      this.user.roles = [{
        id: this.roleChoices.getValue().value,
        type: 'roles'
      }]
      this.$emit('update:handleSave', this.user);
    },
  },
};

</script>
