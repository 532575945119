<template>

  <div class="py-4 container-fluid">
    <UsersFilters :role="userProfile?.roles[0].name" :update-filters="updateFilters"/>

    <div class="mt-4 user">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <!-- Left side content -->
              <h5 class="mb-0">Users</h5>

              <!-- Right side content -->
              <div class="d-flex align-items-center">
                <!-- Add User button -->
                <!-- Entries per page dropdown -->
                <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                  <!-- Dropdown content here -->
                </div>
                <button class="btn btn-outline-success btn-sm me-2 btn-add-on-listing" @click="this.$router.push({ name: 'Add User' })">
                  <i class="fas fa-user-plus me-2"></i>
                  Add User
                </button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div v-if="me" class="card-body">
            <!-- Table -->
            <div class="table-responsive">
              <table id="user-list" ref="table" class="table table-flush">
                <thead  v-if="loadingFinished" class="thead-light">
                <UsersColumns v-if="'partner' !== me?.roles[0].name" :role="me?.roles[0].name"/>
                <PartnerUsersColumns v-if="'partner' === me?.roles[0].name"/>
                </thead>
                <tbody class="text-sm">
                </tbody>
              </table>
            </div>
          </div>

          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">
            <div class="ms-3">
              <p class="text-sm">
                Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }}
              </p>
            </div>
            <BasePagination v-model="pagination.currentPage" class="pagination-success pagination-md me-3"
                            :value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"
                            @click="getDataFromPage($event)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";
import BasePagination from "@/components/BasePagination.vue";
import UsersFilters from "@/views/users/UsersFilters.vue";
import UsersColumns from "@/views/users/UsersColumns.vue";
import {getUserProperties} from "@/views/users/usersUtils";
import PartnerUsersColumns from "@/views/users/PartnerUsersColumns.vue";
import {mapGetters} from "vuex";

var sortDirection = "created_at";
var getCurrentPage = 1;
var filters = {};

const getUsersList = _.debounce(async function (params) {
  await store.dispatch("users/getUsers", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "UsersList",
  components: {
    PartnerUsersColumns,
    UsersColumns,
    UsersFilters,
    BasePagination,
  },
  props: {
    offerId: {
      type: String,
      default: ''
    }
  },
  mixins: [eventTable],
  data() {
    return {
      only_waiting_for_verification: false,
      dataUsers: [],
      pagination: {},
      dataTable: null,
      keys: null,
      selected: null,
      loadingFinished: false
    }
  },
  computed: {
    usersList() {
      return this.$store.getters["users/getUsersData"];
    },

    metaPage() {
      return this.$store.getters["users/getUsersMeta"];
    },

    me() {
      return this.$store.getters['profile/getUserProfile']
    },
    affiliateManagers() {
      return this.$store.getters["users/getAffiliateManagersIds"];
    },
    partners() {
      return this.$store.getters["users/getPartnersIds"];
    },
    ...mapGetters({
      userProfile: 'profile/getUserProfile'
    })
  },
  async created() {
    await store.dispatch("users/getAffiliateManagerIds");
    await store.dispatch("users/getPartnerIds");
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    usersList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    }
  },
  async mounted() {
    if (this.$refs.table) {
      filters = {};
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 25
      });

      document.querySelector(".dataTable-bottom").remove()
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      if('' !== this.offerId ) {
        filters['offer_id'] = this.offerId;
      }

      await getUsersList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage
      });

      // Wait for the table to be initialized before moving the dropdown
      this.dataTable.on('datatable.init', function() {
        // Select the "per page" dropdown. The class or ID might change based on the library's implementation
        var perPageDropdown = document.querySelector(".dataTable-dropdown");

        // Select the new target location for the dropdown
        var newLocation = document.getElementById("newPerPagePosition");

        // Move the dropdown to the new location
        if (perPageDropdown && newLocation) {
          newLocation.appendChild(perPageDropdown);
        }
      });

      this.dataTable.on('datatable.perpage', async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getUsersList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.dataTable.on('datatable.sort', async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getUsersList({
          sort: sortDirection = direction + column,
          query: filters,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    }

    await this.$store.dispatch('profile/getProfile');
  },
  beforeUnmount() {
    sortDirection = "created_at";

    filters = {};
    getCurrentPage = 1;
  },
  methods: {
    async refreshList() {
      await getUsersList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable.options.perPage
      });
    },

    async updateFilters(updatedFilter) {
      if('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }

      await this.refreshList();
    },

    async getDataFromPage(page) {
      await getUsersList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataUsers = [];
      if (this.usersList.length > 0) {
        this.usersList.forEach(user => {
          this.dataUsers.push(
              getUserProperties(user, this.me?.roles[0].name, this.affiliateManagers, this.partners)
          )
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataUsers});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: this.me?.id,
          redirectPath: "Edit User",
          deletePath: "users/deleteUser",
          getPath: "users/getUsers",
          textDelete: "User deleted successfully.",
          textDefaultData: 'users'
        });
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    },
  }

};
</script>

