<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Set custom payout</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Offers List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div v-if="offers" class="card-body">
            <div class="row">
              <div class="col-3">
                <label for="offer" class="form-label text-black">Offer</label>
                <VueMultiselect
                    id="offer"
                    v-model="offer"
                    :custom-label="customLabelForOffer"
                    :options="offers ?? []"
                    :show-no-results="true"
                    :track-by="id"
                    label="name"
                    placeholder="Select an offer"
                    select-label="+"
                    deselect-label="-"
                    :searchable="true"
                />
              </div>
              <div class="col-2">
                <label for="partner" class="form-label text-black">Partner</label>
                <VueMultiselect
                    id="partner"
                    v-model="selectedPartner"
                    :options="partners ?? []"
                    label="name"
                    track-by="id"
                    placeholder="Select a partner"
                    select-label="+"
                    deselect-label="-"
                />
              </div>
              <div class="col-3">
                <label for="web" class="form-label text-black">Web</label>
                <VueMultiselect
                    id="web"
                    v-model="selectedWeb"
                    :custom-label="customLabelForWeb"
                    :options="webs ?? []"
                    label="name"
                    track-by="id"
                    placeholder="Select a web"
                    select-label="+"
                    deselect-label="-"
                />
              </div>
              <div class="col-2">
                <label for="payout" class="form-label text-black">Payout (in $)</label>
                <material-input
                    id="payout"
                    v-model:value="payout"
                    type="number"
                    variant="static"
                    name="payout"
                />
              </div>
              <div class="col-2">
                <label for="azurelab_commission" class="form-label text-black">Azurelab commission (in $)</label>
                <material-input
                    id="azurelab_commission"
                    v-model:value="azurelab_commission"
                    type="number"
                    variant="static"
                    name="azurelab_commission"
                />
              </div>
            </div>
            <div class="text-end">
              <material-button class="btn btn-sm" @click.prevent="handleSave">
                Save changes
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin.js";
import store from "@/store";
import {mapGetters} from "vuex";
import MaterialButton from "@/components/MaterialButton.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import showSwal from "@/mixins/showSwal";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  name: 'CreateCustomPayout',
  components: {
    MaterialInput,
    VueMultiselect,
    MaterialButton,
  },
  watch: {
    selectedPartner: {
      handler: "updateWebsListByPartner"
    },
    selectedWeb: {
      handler: "updatePayout"
    },
    offer: {
      handler: "updatePayout"
    }
  },
  methods: {
    customLabelForOffer({id, name, geo, offer_type}) {
      return `${id} ${name} ${geo} ${offer_type}`;
    },
    customLabelForWeb({name, ext_id}) {
      return `${ext_id} ${name} `;
    },
    async updateWebsListByPartner() {
      if (this.selectedPartner) {
        await store.dispatch("users/getWebsIdsWithNames", {filter: {partner_ext_id: this.selectedPartner.ext_id}});
      } else {
        await store.dispatch("users/getWebsIdsWithNames");
      }
    },
    async updatePayout() {
      if (!this.selectedWeb || !this.offer) {
        return;
      }

      const data = {
        offer_id: this.offer ? this.offer.id : null,
        web_id: this.selectedWeb.id,
      };
      await store.dispatch("offers/getCustomPayout", data)
          .then((response) => {
            console.log("response", response);
            this.payout = response.data.payout.value;
            this.azurelab_commission = response.data.azurelab_commission.value;
          })
          .catch(() => {
            this.payout = 0;
            this.azurelab_commission = 0;
          });
    },
    handleSave() {
      this.apiValidationErrors = {};
      const data = {
        offer_id: this.offer.id,
        web_id: this.selectedWeb,
        payout: this.payout,
        azurelab_commission: this.azurelab_commission
      };
      store.dispatch("offers/createCustomPayout", data)
          .then(() => {
            showSwal.methods.showSwal({
              type: "success",
              message: "Saved!",
              width: 500,
            });
            // this.$router.push({name: 'Offers List'});
          })
          .catch((error) => {
            this.apiValidationErrors = error.response.data.errors;
          });
    },
  },
  data() {
    return {
      sampleBannerFiles: null,
      fullPriceBannerFiles: null,
      sampleLandingPageFiles: null,
      fullPriceLandingPageFiles: null,
      offer: null,
      web: null,
      apiValidationErrors: {},
      selectedWeb: null,
      selectedPartner: null,
      payout: 0,
      azurelab_commission: 0,
    }
  },
  computed: {
    ...mapGetters({
      webs: 'users/getWebsIdsWithNames',
      offers: 'offers/getOffersData',
      websWithAccess: 'users/getWebsWithAccess',
      partners: "users/getPartnersIds",
    })
  },
  async created() {
    await store.dispatch("users/getWebsIdsWithNames");
    await store.dispatch("offers/getOffers");
    await store.dispatch("users/getPartnerIds");
  },

  mixins: [formMixin],
}
</script>
