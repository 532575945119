<template>
  <div class="py-4 container-fluid">
    <AcceptCampaignModal/>
    <campaigns-filters v-if="userProfile" :update-filters="updateFilters" :role="userProfile?.roles[0].name" />

    <div class="mt-4 campaign">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Campaigns</h5>
              <div class="d-flex align-items-center">
                <div id="newPerPagePosition" class="d-flex align-items-center per-page-dropdown">
                  <!-- The "per page" dropdown will be moved here -->
                </div>

                <button class="btn btn-outline-success btn-sm ms-2 btn-add-on-listing" @click="$router.push({ name: 'Global Postback Url Generator', params: {userId: userProfile.id} })">
                  <i class="fas fa-user-plus me-2"></i>
                  Global postback
                </button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="card-body">

            <!-- Table -->
            <div class="table-responsive">
              <table id="campaign-list" ref="table" class="table table-flush">
                <thead class="thead-light">
                <CampaignsColumns v-if="loadingFinished" :role="userProfile?.roles[0].name"/>
                </thead>
                <tbody class="text-sm">
                </tbody>
              </table>
            </div>
          </div>

          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center cardBottom">
            <div class="ms-3">
              <p class="text-sm">
                Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }}
              </p>
            </div>
            <BasePagination v-model="pagination.currentPage" class="pagination-success pagination-md me-3"
                            :value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"
                            @click="getDataFromPage($event)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataTable} from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import store from "@/store";
import _ from "lodash";

import BasePagination from "@/components/BasePagination.vue";
import {mapGetters} from "vuex";
import CampaignsFilters from "@/components/campaigns/CampaignsFilters.vue";
import CampaignsColumns from "@/components/campaigns/CampaignsColumns.vue";
import {getCampaignProperties} from "@/components/campaigns/campaignsUtils";
import AcceptCampaignModal from "@/components/campaigns/AcceptCampaignModal.vue";

var sortDirection = "-total_payout";
var getCurrentPage = 1;
var filters = {};

const getCampaignsList = _.debounce(async function (params) {
  await store.dispatch("campaigns/getCampaignsListAdmin", {
    ...(params.sort ? {sort: params.sort} : {}),
    filter: {
      ...(params.query ? params.query : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "CampaignsList",
  props: {
    offerId: {
      type: String,
      default: ''
    }
  },
  components: {
    AcceptCampaignModal,
    CampaignsColumns,
    CampaignsFilters,
    BasePagination
  },
  data() {
    return {
      search: '',
      searchGeo: '',
      searchOfferType: '',
      searchMetricType: '',
      dataCampaigns: [],
      pagination: {},
      dataTable: null,
      keys: null,
      searchUserId: '',
      selectedOfferId: '',
      loadingFinished: false
    }
  },
  mixins: [eventTable],
  computed: {
    campaignsList() {
      return this.$store.getters["campaigns/getCampaignsData"];

    },
    metaPage() {
      return this.$store.getters["campaigns/getCampaignsMeta"];
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      userProfile: 'profile/getUserProfile',
    })
  },
  created() {
    store.dispatch("users/getUsersIds");
    store.dispatch("offers/getOffersIds");
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    campaignsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    userProfile: {
      handler: "reactiveSearch",
      immediate: false,
      deep: true,
    },
    filters: {
      handler: "reactiveFilters",
      deep: true
    },
  },
  async mounted() {
    await store.dispatch("profile/getProfile");

    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 25
      });

      document.querySelector(".dataTable-bottom").remove()
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
      if (this.userProfile?.roles[0].name === 'affiliate_manager') {
        this.dataTable.setMessage(`<i class='fas fa-circle-notch'>Please filter by WebId or Partner Id</i>`);
      } else {
        if ('' !== this.offerId) {
          filters['offer_id'] = this.offerId;
        }

        await getCampaignsList({
          sort: sortDirection,
          query: filters,
          nr: getCurrentPage,
          perpage: this.dataTable ? this.dataTable.options.perPage : 25
        });

        // Wait for the table to be initialized before moving the dropdown
        // Select the "per page" dropdown. The class or ID might change based on the library's implementation
        var perPageDropdown = document.querySelector(".dataTable-dropdown");

        // Select the new target location for the dropdown
        var newLocation = document.getElementById("newPerPagePosition");

        // Move the dropdown to the new location
        if (perPageDropdown && newLocation) {
          newLocation.appendChild(perPageDropdown);
        }

        this.dataTable.on('datatable.perpage', async function (perpage) {
          this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
          await getCampaignsList({
            sort: sortDirection,
            query: filters,
            nr: getCurrentPage = 1,
            perpage: perpage
          });
        });

        this.dataTable.on('datatable.sort', async function (column, direction) {
          column = this.headings[column].title;
          direction = direction == "asc" ? "" : "-";
          await getCampaignsList({
            sort: sortDirection = direction + column,
            query: filters,
            nr: getCurrentPage,
            perpage: this.options.perPage
          });
        });
      }
    }
  },
  beforeUnmount() {
    sortDirection = "-total_payout";
    filters = {};
    getCurrentPage = 1;
  },
  methods: {
    async refreshList() {
      await getCampaignsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 25
      });
    },
    async updateFilters(updatedFilter) {
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }

      await this.refreshList();
    },

    async getDataFromPage(page) {
      await getCampaignsList({
        sort: sortDirection,
        query: filters,
        nr: page,
        perpage: this.dataTable ? this.dataTable.options.perPage : 25
      });
    },

    async reactiveSearch() {
      await getCampaignsList({
        sort: sortDirection,
        query: filters,
        nr: getCurrentPage = 1,
        perpage: this.dataTable ? this.dataTable.options.perPage : 25
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      if(undefined === this.userProfile) {
        return;
      }
      this.dataCampaigns = [];
      if (this.campaignsList.length > 0) {
        this.campaignsList.forEach(campaign => {
          this.dataCampaigns.push(
              getCampaignProperties(campaign, this.userProfile?.roles[0].name)
          )
        });
        this.loadingFinished = true;
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({data: this.dataCampaigns});
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit Campaign",
          deletePath: "campaigns/deleteCampaign",
          getPath: "campaigns/getCampaignsListAdmin",
          textDelete: "Campaign deleted successfully!",
          textDefaultData: 'campaigns',
          defaultSort: '-total_payout'
        });
      } else {
        this.dataTable.setMessage('No results match your search query');
      }
    }
  }
};
</script>
