<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="selectedOffer"
        placeholder="Offer ID"
        :options="offerOptions ?? []"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    VueMultiselect,
  },
  props: {
    offerId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedOffer: null,
    }
  },
  watch: {
    selectedOffer(newValue) {
      this.updateSelectedOffer(newValue);
    },
  },
  computed: {
    // Use Vuex getters to compute values. Adjust according to your store structure
    ...mapGetters({
      offerOptions: "offers/getOffersIds", // Adjusted for offers
    }),
  },
  methods: {
    // Method to update selectedGeo and emit its value to the parent
    updateSelectedOffer(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'offer_id'})

      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'offer_id', value: value})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
  mounted() {
    this.$store.dispatch("offers/getOffersIds");

    if ('' !== this.offerId) {
      this.selectedOffer = this.offerId;
    }
  },
};
</script>
