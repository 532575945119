<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <!-- Card body -->
    <div class="card-body">
      <div class="row mt-sm-2">
        <OfferFilter @update:updateFilters="updateFilters"/>
        <UsersPartnerIDFilter @update:updateFilters="updateFilters"/>
        <WebIDFilter @update:updateFilters="updateFilters"/>
        <UsersAdvertiserIDFilter
            @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>

import OfferFilter from "@/components/Filters/OfferFilter.vue";
import UsersPartnerIDFilter from "@/components/Filters/UsersPartnerIDFilter.vue";
import WebIDFilter from "@/components/Filters/WebIDFilter.vue";
import UsersAdvertiserIDFilter from "@/components/Filters/UsersAdvertiserIDFilter.vue";

export default {
  name: 'CustomPayoutsFilters',
  components: {
    UsersAdvertiserIDFilter,
    WebIDFilter,
    UsersPartnerIDFilter,
    OfferFilter,
  },
  props: {
    updateFilters: {},
  }
}
</script>
