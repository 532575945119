<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Request access</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Offers List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>

              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.leads_daily" label="Leads daily"
                                  variant="static"
                                  type="number"
                                  name="leads_daily"></material-input>
                  <validation-error :errors="apiValidationErrors.leads_daily"></validation-error>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.traffic_source" label="Traffic source"
                                  variant="static"
                                  type="text"
                                  name="traffic_source"></material-input>
                  <validation-error :errors="apiValidationErrors.traffic_source"></validation-error>
                </div>
                <div class="col-2 text-end">
                  <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Request access
                  </material-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../mixins/showSwal";

export default {
  name: 'RequestAccessForOffer',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
  },

  props: {
    offerId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      campaign: {},
    }
  },
  mixins: [formMixin],
  async mounted() {
    if('' === this.offerId) {
      this.$router.push({ name: 'Offers List' })
    }
  },
  methods: {
    async handleAdd() {
      this.resetApiValidation();
      try {
        await this.$store.dispatch("campaigns/addCampaign", {
          leads_daily: parseInt(this.campaign.leads_daily),
          traffic_source: this.campaign.traffic_source,
          offer: {
            type: "offers",
            id: this.offerId
          },
          relationshipNames: ['offer']
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Campaign added successfully!",
          width: 500
        });
        this.$router.push({name: "Offers List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, something went wrong!",
          width: 500
        });

      }
    }
  }
}
</script>
