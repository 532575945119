<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Add campaign</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Offers List' })">
                  Back to list
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="col-4">
                <VueMultiselect v-model="selectedWeb"
                                :custom-label="customLabelForWeb"
                                :max-height="300"
                                :options="webs ?? []"
                                deselect-label="-" label="name" placeholder="Webs"
                                select-label='+'
                                track-by="name"
                >
                </VueMultiselect>
                <template>
                      <span v-for="(option, index) in selectedWeb" :key="index" :style="{ background: option.color }"
                            class="multiselect__tag">
                              <span>{{ option.ext_id }} {{ option.name }}</span>
                              <i class="remove-status-icon" tabindex="1" @click="removeOption(option)"></i>
                      </span>
                </template>
              </div>
              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.leads_daily" label="Leads daily"
                                  name="leads_daily"
                                  type="number"
                                  variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.leads_daily"></validation-error>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-10">
                  <material-input id="value" v-model:value="campaign.traffic_source" label="Traffic source"
                                  name="traffic_source"
                                  type="text"
                                  variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.traffic_source"></validation-error>
                </div>
                <div class="col-2 text-end">
                  <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Add campaign
                  </material-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from "@/components/ValidationError.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin";
import showSwal from "@/mixins/showSwal";
import {mapGetters} from "vuex";
import store from "@/store";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

export default {
  name: "CreateCampaignForUser",
  components: {VueMultiselect, MaterialInput, MaterialButton, ValidationError},
  props: {
    offerId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      campaign: {},
      selectedWeb: null,
    }
  },
  mixins: [formMixin],
  async mounted() {
    if ('' === this.offerId) {
      this.$router.push({name: 'Offers List'})
    }
  },
  async created() {
    await store.dispatch("users/getWebsIdsWithNames");
  },
  computed: {
    ...mapGetters({
      webs: 'users/getWebsIdsWithNames',
    })
  },
  methods: {
    customLabelForWeb({name, ext_id}) {
      return `${ext_id} ${name} `;
    },
    removeOption(item) {
      this.selectedWebs = this.selectedWebs.filter(option => option !== item);
    },
    async handleAdd() {
      this.resetApiValidation();
      try {
        await this.$store.dispatch("campaigns/addCampaign", {
          leads_daily: parseInt(this.campaign.leads_daily),
          traffic_source: this.campaign.traffic_source,
          offer: {
            type: "offers",
            id: this.offerId
          },
          promoter: {
            type: "users",
            id: this.selectedWeb.id.toString()
          },
          relationshipNames: ['offer', 'promoter']
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Campaign added successfully!",
          width: 500
        });
        this.$router.push({name: "Offers List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, something went wrong!",
          width: 500
        });

      }
    }
  }
}
</script>


<style scoped>

</style>
