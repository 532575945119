/* eslint-disable no-unused-vars */
import withdrawalsService from '../services/withdrawals.service';

const initialState = { withdrawals: null, withdrawal: null, addedWithdrawal: null, invoice: null, availableToWithdraw: null };

export const withdrawals = {
    namespaced: true,
    state: initialState,
    actions: {
        async getWithdrawals({ commit }, options) {
            const withdrawals = await withdrawalsService.getWithdrawals(options);
            commit('getWithdrawalsSuccess', withdrawals);
        },
        async getAvailableToWithdraw({ commit }, options) {
            const availableToWithdraw = await withdrawalsService.getAvailableToWithdraw(options);
            commit('getAvailableToWithdrawSuccess', availableToWithdraw);
        },

        async getInvoice({ commit }, withdrawalId) {
            const invoice = await withdrawalsService.downloadInvoice(withdrawalId);
            commit('getInvoiceSuccess', invoice);
        },

        async addWithdrawal({ commit }, newWithdrawal) {
            const withdrawal = await withdrawalsService.addWithdrawal(newWithdrawal);
            commit('getWithdrawalSuccess', withdrawal)
        },

        async addInvoice({ commit }, invoice) {
           const invoiceResponse = (await withdrawalsService.addInvoice(invoice, this.state.withdrawals.withdrawal.id)).url;
           commit('addInvoiceSuccess', invoiceResponse)
        },

        async deleteWithdrawal({ commit }, withdrawalId) {
            await withdrawalsService.deleteWithdrawal(withdrawalId);
        },

        async getWithdrawal({ commit }, withdrawalId) {
            const withdrawal = await withdrawalsService.getWithdrawal(withdrawalId);
            commit('getWithdrawalSuccess', withdrawal)
        },

        async editWithdrawal({ commit }, modifiedWithdrawal) {
            await withdrawalsService.editWithdrawal(modifiedWithdrawal);
        },
    },
    mutations: {
        getAvailableToWithdrawSuccess(state, availableToWithdraw) {
            state.availableToWithdraw = availableToWithdraw;
        },
        addInvoiceSuccess(state, invoice) {
            state.invoice = invoice;
        },
        getInvoiceSuccess(state, invoice) {
            state.invoice = invoice;
        },
        getWithdrawalsSuccess(state, withdrawals) {
            state.withdrawals = withdrawals;
        },
        getWithdrawalSuccess(state, withdrawal) {
            state.withdrawal = withdrawal;
        },
        addWithdrawalSuccess(state, withdrawal) {
            state.addedWithdrawal = withdrawal
        }
    },
    getters: {
        getAvailableToWithdraw(state) {
            return state.availableToWithdraw;
        },
        getWithdrawalsData(state) {
            return state.withdrawals?.data;
        },
        getWithdrawalsMeta(state) {
            return state.withdrawals?.meta;
        },
        getWithdrawal(state){
            return state.withdrawal;
        },
        getAddedWithdrawal(state){
            return state.addedWithdrawal;
        },
        getInvoice(state) {
            return state.invoice
        }
    }
}

