<template>
  <div class="card mb-2 mini-stat-card-custom" :style="{'cursor': isClickable ? 'pointer' : ''}" @click="navigateToLeadsListing">
    <div class="card-header p-3 pt-2">
      <div
        class="icon icon-shape shadow text-center border-radius-xl position-absolute"
        :class="`bg-gradient-${icon.background} shadow-${icon.background}`"
      >
        <i
          class="material-icons opacity-10"
          :class="icon.color"
          aria-hidden="true"
          >{{ icon.name }}</i
        >
      </div>
      <div class="text-end pt-1">
        <p class="text-sm mb-0 text-capitalize">{{ title.text }}</p>
        <h4 class="mb-0">{{ title.value }}</h4>
      </div>
    </div>
<!--    <hr class="dark horizontal my-0" />-->
<!--    <div class="card-footer p-3">-->
      <!--  eslint-disable-next-line vue/no-v-html -->
<!--      <p class="mb-0" v-html="detail"></p>-->
<!--    </div>-->
  </div>
</template>

<script>
import store from "@/store";
import {bool} from "yup";

export default {
  name: "MiniStatisticsCard",
  props: {
    filters: {
      type: Object,
      default: () => {}
    },
    leadsStatus: {
      type: String,
      default: ''
    },
    isClickable: {
      type: bool,
      default: true
    },
    title: {
      type: Object,
      required: true,
      text: String,
      value: [Number, String],
    },
    detail: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      required: true,
      name: String,
      color: String,
      background: String,
      default: () => ({
        color: "text-white",
        background: "success",
      }),
    },
  },
  methods: {
    navigateToLeadsListing() {
      if (false === this.isClickable) {
        return;
      }
      var localFilters = {...this.filters};
      if (this.leadsStatus) {
        localFilters['lead_status'] = this.leadsStatus
      }
      store.commit('leads/setFilters', localFilters);
      this.$router.push({ name: 'Leads List'})
    }
  }
};
</script>

<style scoped>
.mini-stat-card-custom .card-header {
  border-radius: 8px !important;
  padding: 8px 15px !important;
}

.mini-stat-card-custom {
  min-width: 250px; /* Ensure cards have a minimum width */
}

@media (max-width: 768px) {
  .mini-stat-card-custom .card-title {
    font-size: 1rem; /* Adjust font size for smaller devices */
  }
  .mini-stat-card-custom .card-detail {
    font-size: 0.8rem;
  }
}

.mini-stat-card-custom .card-header .icon .material-icons {
  font-size: 25px !important;
}

.mini-stat-card-custom .card-header .icon {
  margin-top: 3px;
}
</style>
