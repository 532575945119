<template>
  <div class="row mt-4 overflow-hidden">
    <div class="mt-1  mb-2">
      <material-button v-show="!file" size="sm" type="button">
        <label :for="id" class="mb-0 text-white small cursor-pointer">{{ text }} </label>
        <input :id="id" @change.prevent="onFileChange" type="file" style="display: none;"
        >
      </material-button>

      <div v-show="file">
        <material-button class="mx-2" @click.prevent="onFileRemove" size="sm" type="button" color="danger">
          <label class="mb-0 text-white small cursor-pointer"> &#10005; Remove</label>
        </material-button>
        <material-button size="sm" type="button">
          <label :for="id" class="mb-0 text-white small cursor-pointer">Change</label>
          <input :id="id" @change.prevent="onFileChange" type="file" style="display: none;"
                 accept="image/*">
        </material-button>
      </div>
    </div>
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue"

export default {
  name: 'FileUploadComponent',
  components: {MaterialButton},
  props: {
    text: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  watch: {
    file(newValue) {
      console.log('1', this.file);
      console.log('2', newValue);
      this.$emit('update:updateFile', this.file); //todo should it be this.file or newValue?
    },
  },
  data() {
    return {
      file: null,
    }
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    onFileRemove() {
      this.file = null
    }
  }
}
</script>
